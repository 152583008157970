import React from "react";
import { Link } from "react-router-dom";
import { Layout, Row, Card, Typography, Button, Col } from "antd";
import TempLogo from "../../Assets/Icons/newLogo.png";
import styles from "../styles/Logout.module.css";
const { Header, Content, Footer } = Layout;
const {  Text } = Typography;
function LogoutWindow(props) {

  return (
    <Layout style={{ height: "100vh" }}>
      <Header style={{ position: "fixed", zIndex: 1, width: "100%", }}>
        <Link to={"/login"}>
          <div style={{ textAlign: "center", float: "left" }}>
            <img
              className={styles.logo}
              style={{ width: "120px", height: "auto" }}
              src={TempLogo}
              alt="TempLogo"
            />
          </div>
        </Link>
      </Header>
      <Content style={{ padding: "0 50px", marginTop: 64, background: "#fff", alignItems:"center"}}>
        <div style={{ padding: 24, minHeight: 380, background: "#fff"}}>
          <Card style={{ width: "auto",margin:"auto",textAlign:"center",alignItems:"center" }}>
            <Col span={34} style={{padding:"10px",alignItems:"center",margin:"auto",}}>
            <Row span={24} align="middle">
                <div style={{display:"flex",flexDirection:"column"}}>
                <Text type="secondary" style={{fontSize:"22px",}}>Thank you for using RE-Sure.</Text>
                </div>
            </Row>
            <Row span={24} align="middle">
                <div style={{width:"100%",padding:"10px"}}>
              <Link to={"/login"}>
              <Button type="primary" style={{ width:"120px",fontSize:"15px" }}>
                Log In
              </Button>
              </Link>
                </div>
            </Row>
            </Col>
          </Card>
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>© 2023 - Hana Software, Inc.</Footer>
    </Layout>
  );
}
export { LogoutWindow };
