import React from 'react';
import { IoArchiveOutline } from "react-icons/io5";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from 'react-redux';
import { clientAction } from '../state/actions';
import { Button, Modal } from 'antd';

const DeleteContact = ({personDetails, setPage, setSelectedClient}) => {
    const dispatch = useDispatch();

    const handleDeleteOrArchive = () => {
        Modal.confirm({
            title: `Are you sure you want to ${personDetails?.deleteType === "ARCHIVE" ? "archive" : "delete"} this contact and all of its associated documents?`,
            okText: "Yes",
            cancelText: "No",
            onOk(){
                setSelectedClient("");
                dispatch(clientAction.deleteOrArchiveContact(personDetails?._id, personDetails?.deleteType, setPage, setSelectedClient))
            },
            onCancel(){}
        })
    }

  return (
    <div style={{ margin: "20px 0 30px 0", textAlign: "center",display: "flex", alignItems: "center", justifyContent: "center" }}>
        {personDetails?.deleteType === "ARCHIVE" ? (
        <Button
            type="primary"
            icon={<IoArchiveOutline style={{ fontSize: "20px", marginRight:"5px" }} />}
            onClick={handleDeleteOrArchive}
            style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "16px",
            padding: "8px 16px",
            }}
        >
            Remove and Archive
        </Button>
        ) : (
        <Button
            type="primary"
            icon={<DeleteOutlined style={{ fontSize: "17px", marginTop:"1px" }} />}
            onClick={handleDeleteOrArchive}
            style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "16px",
            padding: "8px 16px",
            }}
        >
            Delete
        </Button>
        )}
  </div>
  )
}

export default DeleteContact