import React from 'react'
import { PropertyHandlers } from '../state/actions';
import Dragger from 'antd/lib/upload/Dragger';
import { Button, Modal, message } from 'antd';
import { InboxOutlined } from "@ant-design/icons";
import { useDispatch } from 'react-redux';
import { changeExtensiontoLowerCase, getCoAgentsIds } from '../../Common/utils/extraFunctions';
import { customerDocsContTractTypes } from '../../Common/commondata/commonData';

export default function UploadPdfDoc({ selectedData, allData, setAllData, handleModalCancel, open, successFuncAll, getCoordinateItem }) {
    const dispatch = useDispatch();
    function beforeUpload(file) {
        let supportedFormats = file?.type;
        if (supportedFormats?.includes("pdf")) {
          const isPdf = file.type === "application/pdf";
          if (!isPdf) {
            message.error("You can only upload PDF file!");
          }
          const isLt2M = file.size / 1024 / 1024 < 20;
          if (!isLt2M) {
            message.error("File must be smaller than 20MB!");
          }
          return isPdf && isLt2M;
        }
    }
    
    function bytesToSize(bytes) {
        let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    }
    
    let handleCustome = (data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {};
        reader.onerror = (error) => {
          return error;
        };
        const fileName = changeExtensiontoLowerCase(data?.file?.name)
        const updatedFile = new File([data.file], fileName, {
            type: data.file.type,
            lastModified: data.file.lastModified,
          });
      
        setAllData({
          ...allData,
          file: updatedFile || data.file,
        });
    };
    
    const successFunc = () => {
        if(successFuncAll){
            successFuncAll();
        }
    }
    const handleSave = () => {
      const contractId = Array.isArray(selectedData?.contractId) ? selectedData.contractId.pop() : selectedData?.contractId;
      const contractType = selectedData?.contractType;
      // only when we create any customer docs from the Property docs
      if (getCoordinateItem?._id && customerDocsContTractTypes.includes(contractType)) {
        let buyerIds = [];
        let sellerIds = [];
        let buyerAgentIds = [];
        let sellerAgentIds = [];

        if (["ERTB", "BDB", "FRASCONA_BDB"].includes(contractType)) {
          buyerIds = getCoordinateItem?.isProperty ? selectedData?.sellerIds : selectedData?.buyerIds;
          buyerAgentIds = selectedData?.currentAgentId?._id && [selectedData?.currentAgentId?._id];
        } else {
          sellerIds = getCoordinateItem?.isProperty ? selectedData?.sellerIds : selectedData?.buyerIds;
          sellerAgentIds = selectedData?.currentAgentId?._id && [selectedData?.currentAgentId?._id];
        }

        const buildData = {
          openFor: ["ERTB", "BDB", "FRASCONA_BDB"].includes(contractType) ? "BUYER_AGENT" : "SELLER_AGENT",
          RTDInitiator: ["ERTB", "BDB", "FRASCONA_BDB"].includes(contractType) ? "BUYER" : "SELLER",
          contractId,
          contractType,
          sellerIds,
          buyerIds,
          buyerAgentIds,
          sellerAgentIds,
          ...(allData && { ...allData }),
        };

        dispatch(PropertyHandlers?.uploadPdfDocumentFunc({ data: buildData, successFunc }));
        return;
      }

      const buildData = {
        openFor: selectedData?.RTDInitiator === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
        RTDInitiator: selectedData?.RTDInitiator === "SELLER" ? "SELLER" : "BUYER",
        contractId,
        contractType,
        openForPropertyDoc: selectedData?.RTDInitiator === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
        ...(selectedData?.offerId && { offerId: selectedData.offerId }),
        ...(selectedData?.propertyId && { propertyId: selectedData.propertyId }),
        ...(selectedData?.clientDocId && { clientDocId: selectedData.clientDocId }),
        sellerIds: selectedData?.sellerIds,
        buyerIds: selectedData?.buyerIds,
        buyerAgentIds: getCoAgentsIds(selectedData?.buyerAgentIds),
        sellerAgentIds: getCoAgentsIds(selectedData?.sellerAgentIds),
        ...(allData && { ...allData }),
      };
      dispatch(PropertyHandlers?.uploadPdfDocumentFunc({ data: buildData, successFunc }));
    };
      
      
    return (
        <Modal
            open={open}
            onCancel={handleModalCancel}
            style={{ marginTop: "-50px"}}
            footer={false}
            width={700}
        >
            <div style={{minHeight: "350px"}}>
            <div style={{textAlign: "center", fontSize: "1.2rem", padding: "0px 0px 30px 0px"}}>Upload a fully signed PDF document</div>
            <Dragger
                accept=".pdf,.PDF"
                progress={false}
                showUploadList={false}
                customRequest={handleCustome}
                beforeUpload={beforeUpload}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Paste, Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                    Support for a single document/pdf upload.
                </p>
            </Dragger>
            <div style={{textAlign:"center",marginTop:"20px"}}>
                {allData?.file?.name && <h3>{allData?.file?.name}</h3>}
                {allData?.file?.size && <h6>Size:{" "}{bytesToSize(allData?.file?.size )}</h6>}
            </div>
            <div style={{display: "flex", justifyContent: "center"}}><Button type="primary" disabled={!allData?.file} onClick={handleSave}>Save</Button></div>
            </div>
        </Modal>
    )
}
