import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import React from "react";
import { documentAction } from "../../DocumentSection/state/actions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

export default function ListingForm({
  listingModalFields,
  setListingModalFields
}) {
  const dispatch = useDispatch();
 
  const isMobile = useMediaQuery({ maxWidth: 480 });

  function onChange(value, key) {
    const fieldEmptyUpdated = listingModalFields;
    fieldEmptyUpdated[key] = value;
    setListingModalFields({ ...fieldEmptyUpdated });
  }

  let drawerClose = () => {
    dispatch(documentAction.clearCustomerData());
  };

  const selectOptions = [
    { value: "", label: "None" },
    { value: "East", label: "East" },
    { value: "West", label: "West" },
    { value: "North", label: "North" },
    { value: "South", label: "South" },
    { value: "Northeast", label: "North East" },
    { value: "Northwest", label: "North West" },
    { value: "Southeast", label: "South East" },
    { value: "Southwest", label: "South West" },
  ];
  const onFinish = (v) => {};

  const disabledDate = (current) => {
    const targetDate = moment(listingModalFields.startPeriod, "YYYY-MM-DD");
    const nextDay = targetDate.clone().add(1, "day");
    return current && current.isBefore(nextDay, "day");
  };
  const defaultStartDate = moment();
  return (
    <div style={{ maxHeight: isMobile ? "300px" : "490px", overflow: "auto", overflowX: "hidden", padding: "0 10px" }}>
      <Form onCancel={drawerClose} layout="vertical" onFinish={onFinish} initialValues={{ unitLabel: "Unit " }}>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="lbl"
              name="listingStartDate"
              label="Listing Start Date"
              rules={[
                {
                  required: true,
                  message: "Please select the Listing Start Date.",
                },
              ]}
            >
              <DatePicker
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                allowClear
                defaultValue={defaultStartDate}
                onChange={(e, value) => {
                  onChange(value, "startPeriod");
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="lbl"
              name="listingEndDate"
              label="Listing End Date"
              initialValue={listingModalFields?.endPeriod ? moment(listingModalFields?.endPeriod, "YYYY-MM-DD") : null}
              rules={[
                {
                  required: true,
                  message: "Please select the Listing End Date.",
                },
              ]}
            >
              <DatePicker
                onChange={(e, value) => {
                  onChange(value, "endPeriod");
                }}
                disabled={listingModalFields.startPeriod ? false : true}
                disabledDate={disabledDate}
                format={"YYYY-MM-DD"}
                style={{ width: "100%" }}
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="StreetNumber" label="Street Number" className="lbl" initialValue={listingModalFields?.StreetNumber || ""} rules={[{ required: true, message: "Please enter the Street Number." }]}>
              <Input
                allowClear
                type="number"
                onChange={(e) => {
                  onChange(e.target.value, "StreetNumber");
                }}
                placeholder="Enter Street Number"
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="DirPrefix" label="Direction Prefix" className="lbl1" initialValue={listingModalFields?.DirPrefix || undefined}>
              <Select
                placeholder="Select Direction Prefix"
                options={selectOptions}
                size="middle"
                allowClear
                onChange={(e) => {
                  onChange(e, "DirPrefix");
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="StreetName"
              label="Street Name"
              className="lbl"
              rules={[{ required: true, message: "Please enter the Street Name." }]}
              initialValue={listingModalFields?.StreetName || ""}
              onChange={(e) => {
                onChange(e.target.value, "StreetName");
              }}
            >
              <Input placeholder="Enter Street Name" allowClear />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="StreetSuffix"
              label="Street Suffix"
              className="lbl"
              initialValue={listingModalFields?.StreetSuffix || ""}
              onChange={(e) => {
                onChange(e.target.value, "StreetSuffix");
              }}
            >
              <Input placeholder="Enter Street Suffix" allowClear />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item name="DirSuffix" label="Direction Suffix" className="lbl1" initialValue={listingModalFields?.DirSuffix || undefined}>
              <Select
                placeholder="Select Direction Suffix"
                options={selectOptions}
                size="middle"
                allowClear
                onChange={(e) => {
                  onChange(e, "DirSuffix");
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Form.Item
                label="Unit Label"
                name="UnitLabel"
                className="lbl"
                initialValue={listingModalFields?.UnitLabel || ""}
                style={{
                  flex: 1,
                }}
              >
                <Input
                  placeholder="Enter Unit, Apt or Suite"
                  onChange={(e) => {
                    onChange(e.target.value, "UnitLabel");
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item
                label="Unit Number"
                name="UnitNumber"
                style={{
                  flex: 1,
                }}
              >
                <Input
                  placeholder="Enter Unit Number"
                  onChange={(e) => {
                    onChange(e.target.value, "UnitNumber");
                  }}
                  allowClear
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item className="lbl1" name="City" label="City" rules={[{ required: true, message: "Please enter the City." }]} initialValue={listingModalFields?.City || ""}>
              <Input
                onChange={(e) => {
                  onChange(e.target.value, "City");
                }}
                placeholder="Enter City"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item className="lbl1" name="State" label="State" rules={[{ required: true, message: "Please enter the State." }]} initialValue={listingModalFields?.State || ""}>
              <Input
                defaultValue="CO"
                onChange={(e) => {
                  onChange(e.target.value, "State");
                }}
                placeholder="Enter State"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              className="lbl1"
              name="ZipCode"
              label="Zip Code"
              initialValue={listingModalFields?.ZipCode || ""}
              hasFeedback
              rules={[
                { required: true, message: "" },
                { whitespace: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (value?.length > 4 && value?.length < 7) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Please enter a valid Zip Code."));
                  },
                }),
              ]}
            >
              <Input
                type="number"
                maxLength={"6"}
                allowClear
                onChange={(e) => {
                  onChange(e.target.value, "ZipCode");
                }}
                placeholder="Enter Zip Code"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
