const { PropertyHandlers } = require("../../PropertyComp/state/actions");
const { message } = require("antd");

let optionsForSort = [
  "PRICE",
  "CLOSING_DATE",
  "DOWN_PAYMENT_AMOUNT",
  "LOAN_TYPE",
];
let optionsForOrder = ["ASC", "DEC"];


var daysData = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];


let doubleDigitNumber = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
];

let staticStrings = [
  "Price",
  "Highest Escalated Price",
  "Down Payment",
  "Earnest Money",
  "Loan Type",
  "Point in Approval",
  "Concessions",
  "Extra Inclusions",
  "Exclusions",
  "Closing Fee",
  "Status Letter",
  "Record Change Fee",
  "Water Transfer Fee",
  "Due Diligence Docs",
  "Possession Penalty",
  "Inspection Objection Deadline",
  "Inspection Termination Deadline",
  "Loan Termination Deadline",
  "Props Ins Termination Deadline",
  "Additional Prov",
  "Closing Date",
  "Possession Date",
  "Rent Back Cost",
  "Rent Back Deposit",
  "Time to Decide",
  "Items Suggested to Counter",
  "Offer Package Supplements",
  "Notes",
];

let changeLoanTypeTemp = {
  134: "Conventional",
  Conventional: "FHA",
  FHA: "VA",
  VA: "Bond",
};

let loanTypeData = ["Conventional", "FHA", "VA", "Bond"];

let docType = [
  { name: "CBSR Property", id: "" },
  { name: "Seller Disclosure", id: "" },
  { name: "Exclusive right to buy", id: "" },
  { name: "Exclusive right to sell", id: "" },
];


const transactionDefaultSortFilter = {
  search: "",
  sectionSort: ["BUYING", "SELLING"],
  statusSort: ["ACTIVE","UNDER_CONTRACT","INPROGRESS","DRAFT"],
  dateSort: {
    type: "UPDATED",
    order: "DESC"
  }
}

const defaultERTSData = ({moment}) => {
  return {
    startPeriod: moment().format("YYYY-MM-DD"),
    endPeriod: undefined,
    listingType: "",
    DirPrefix: "",
    DirSuffix: "",
    StreetSuffix: "",
    searchSellerType: "",
    sellerId: "",
    State: "CO",
    StreetName: "",
    StreetNumber: "",
    ZipCode: "",
    UnitLabel: "Unit",
    UnitNumber: "",
    City: ""
  }
}

const isResetFilterSort = ({filterSort}) => {
  if(filterSort?.sectionSort?.length === transactionDefaultSortFilter?.sectionSort?.length){
    for(let value of filterSort.sectionSort){
      if(!transactionDefaultSortFilter?.sectionSort?.includes(value)){
        return true;
      }
    }
  }
  else return true;

  if(filterSort?.statusSort?.length === transactionDefaultSortFilter?.statusSort?.length){
    for(let value of filterSort.statusSort){
      if(!transactionDefaultSortFilter?.statusSort?.includes(value)){
        return true;
      }
    }
  }
  else return true;

  if(filterSort?.search?.trim() !== transactionDefaultSortFilter?.search?.trim()){
    return true;
  }

  if((filterSort?.dateSort?.type !== transactionDefaultSortFilter?.dateSort?.type) || (filterSort?.dateSort?.order !== transactionDefaultSortFilter?.dateSort?.order)){
    return true;
  }

  return false
}

const handleDateSortClick = ({type,dispatch, filterSort}) => {
  if(type === filterSort?.dateSort?.type){
    if(filterSort?.dateSort?.order === "DESC"){
      dispatch(PropertyHandlers.changeFilterSort({
        ...filterSort,
        dateSort: {
          ...filterSort.dateSort,
          order: "ASC"
        }
      }))
    }
    else{
      dispatch(PropertyHandlers.changeFilterSort(
        {
            ...filterSort,
            dateSort: {
              ...filterSort.dateSort,
              order: "DESC"
            }
          }
      ))
    }
  }
  else{
    dispatch(PropertyHandlers.changeFilterSort(
      {
          ...filterSort,
          dateSort: {
            type,
            order: "DESC"
          }
        }
    ))
  }
}

const getBackgroundColor = ({key, type}) => {
  switch (key) {
      case "UNDER_CONTRACT":
          return (type==="TEXT") ? {value: "Pending", key: "UNDER_CONTRACT"} : (type==="OUTLINE") ? "#5CBD49" : "#BEF2BB";
      case "ACTIVE":
          return (type==="TEXT") ? {value: "Active", key: "ACTIVE"} : (type==="OUTLINE") ? "#7DAFDE" : "#D0E2F9";
      case "INPROGRESS":
          return (type==="TEXT") ? {value: "In-Progress", key: "INPROGRESS"} : (type==="OUTLINE") ? "#E3CB00" : "#FFF58F";
      case "DRAFT":
          return (type==="TEXT") ? {value: "Draft", key: "DRAFT"} : (type==="OUTLINE") ? "#9E9D9E" : "#D4D2D4";
      case "TERMINATED":
          return (type==="TEXT") ? {value: "Terminated", key: "TERMINATED"} : (type==="OUTLINE") ? "#D95063" : "#F6BBBE";
      case "ARCHIVED":
          return (type==="TEXT") ? {value: "Archived", key: "ARCHIVED"} : (type==="OUTLINE") ? "#B38BC1" : "#E6E4FB";
      case "CLOSED":
          return (type==="TEXT") ?  {value: "Closed", key: "CLOSED"} : (type==="OUTLINE") ? "#FF9F00" : "#F4CE9D";
      case "BUYING":
          return (type==="TEXT") ? {value: "Buying", key: "BUYING"} : "#3d59ab";
      case "SELLING":
          return (type==="TEXT") ? {value: "Selling", key: "SELLING"} : "#FCFCED";
      default:
          return "";
  }
};

const commonStatusFunc = ({key}) => {
  switch (key) {
    case "ACCEPTED":
        return {value: "Pending", key: "UNDER_CONTRACT"};
    case "ARCHIVED":
        return {value: "Archived", key: "ARCHIVED"};
    case "DRAFT":
        return {value: "Draft", key: "DRAFT"};
    case "SUBMITTED":
    case "HOLD":
    case "BACKUP":
    case "INPROGRESS":
        return {value: "In-Progress", key: "INPROGRESS"};
    case "TERMINATED":
        return {value: "Terminated", key: "TERMINATED"};
    case "Active":
    case "Pending":
    case "Active Under Contract":
        return {value: "Active", key: "ACTIVE"};
    case "CLOSED":
    case "Closed":
        return {value: "Closed", key: "CLOSED"};
    default:
        return "";
  }
}

const createOfferContractTypes = ["CBSR", "CBS_MH", "CBS_LAND", "CBS_CL", "CBS_IR", "CBS_COFPA"];
const conterOfferContractTypes = ["CO", "MH_CO"];
const customerDocsContTractTypes = ["BDS", "BDB", "DWR", "ERTB", "LCAE", "ERTL", "ETCT", "LCAES_ERTL", "LCAES_ETCT", "FRASCONA_BDB", "WFD"];

const createOfferUploadType = [{
    contractType:"CBSR",
    contractName: "Contract to Buy and Sell, Residential"
  }, 
  {
    contractType: "CBS_MH",
    contractName: "Manufactured Home Contract to Buy and Sell (Lot Lease Only)"
  }, 
  {
    contractType:"CBS_LAND",
    contractName: "Contract to Buy and Sell, Land (2024)"
  }, 
  {
    contractType: "CBS_CL",
    contractName: "Contract to Buy and Sell, Commercial"
  }, 
  {
    contractType: "CBS_IR",
    contractName: "Contract to Buy and Sell, Income-Residential"
  },
  {
    contractType: "CBS_COFPA",
    contractName: "Contract to Buy and Sell, CO Foreclosure Protection Act"
  },
  {
    contractType: "CO",
    contractName: "Counterproposal"
  }];

const checkIfDisabled = ({openFor, actualPropertyStatus, offerStatus, buyingStatus, enableArray}) => {
  if(openFor === "SELLER"){
    const common = commonStatusFunc({key: actualPropertyStatus});
    if((((common?.key === "CLOSED") && !enableArray?.includes(common?.key))) || ((common?.key === "ARCHIVED") && !enableArray?.includes(common?.key))){
      return {
        disabled: true
      }
    }
  }
  else if(openFor === "BUYER"){
    const common = commonStatusFunc({key: buyingStatus});
    if(((common?.key === "CLOSED") && !enableArray?.includes(common?.key)) || ((common?.key === "ARCHIVED") && !enableArray?.includes(common?.key)) || ((offerStatus === "TERMINATED") && !enableArray?.includes(offerStatus))){
      return {
        disabled: true
      }
    }
  }
}

const disabledTransactionText = () => {
  message.warning("Action not available for transactions with status Closed, Archived and Terminated.");
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const hasAccessAccountForAllUsers = (getUserDetailsData) => {
 return Array.isArray(getUserDetailsData?.delegateId?.adminRole) && getUserDetailsData?.delegateId?.adminRole.some(role => ['SUPER_ADMIN', 'RS_SUPPORT1'].includes(role))
}
const combineAgentWithComma = ({ sellerAgents }) => {
  if(!Array.isArray(sellerAgents)) return "";
  return sellerAgents.map(agent => agent.fullName).join(", ");
}

const isSellingNotExecutedFunc = ({item}) => {
  return (item.propertyStatus !== "DRAFT") && (item?.propertyStatus !== "INPROGRESS") && (item?.previousStatus !== "DRAFT") && (item?.previousStatus !== "INPROGRESS")
} 

const isBuyingNotExecutedFunc = ({item}) => {
  return (item.status !== "DRAFT") && (item?.status !== "INPROGRESS") && (item?.previousStatus !== "DRAFT") && (item?.previousStatus !== "INPROGRESS")
} 

export {
  docType,
  staticStrings,
  daysData,
  optionsForSort,
  optionsForOrder,
  doubleDigitNumber,
  changeLoanTypeTemp,
  loanTypeData,
  transactionDefaultSortFilter,
  handleDateSortClick,
  getBackgroundColor,
  isResetFilterSort,
  defaultERTSData,
  createOfferContractTypes,
  commonStatusFunc,
  checkIfDisabled,
  disabledTransactionText,
  validateEmail,
  createOfferUploadType,
  conterOfferContractTypes,
  hasAccessAccountForAllUsers,
  combineAgentWithComma,
  customerDocsContTractTypes,
  isSellingNotExecutedFunc,
  isBuyingNotExecutedFunc
};
