import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Modal, Button, Typography, Checkbox, Row } from "antd";
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { userSignUpAction } from "../state/actions";
import styles from "../styles/User.module.css";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import dotEnv from "dotenv";
import { useMediaQuery } from "react-responsive";
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;

const { Text } = Typography;

/* eslint-enable no-template-curly-in-string */
function validateMessages(label) {
  return {
    required: `${label} is required!`,
    types: {
      email: `${label} is not a valid email!`,
      number: `${label} is not a valid number!`,
    },
  };
}
/* eslint-enable no-template-curly-in-string */
function UserLogin(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [emailVal, setEmailVal] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: 540 });

  const urlParams = new URLSearchParams(window.location.search);
  const urlId = urlParams.get("urlId");

  const { userLogInLoading, userSessionId } = useSelector((state) => state.userSignup);
  const accessToken = localStorage.getItem("userAuth");

  const destination = location?.state?.dest;
  const searchQuery = location?.state?.searchQuery;
  const redirectpathname = userSessionId === location?.state?.sessionId ? location?.state?.redirectpathname : null;

  const handleLoginSubmit = (values) => {
    const payload = {
      email: values.email.trim(),
      password: values.password.trim(),
    };
    dispatch(userSignUpAction.userLogin(payload));
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    history.replace({});
  };

  let handleCancel = () => {
    setIsModalVisible(false);
    history.replace({});
  };

  if (["offerTable", "PRE_RTD", "OMD", "TC_PRE_RTD", "TC_OMD"].includes(destination) && accessToken && String(userSessionId) === location?.state?.sessionId) {
    window.location.href = `${redirectpathname}`;
    return <></>;
  } else if (urlId && accessToken) {
    window.location.href = `${adminURL}/contractDocument/${urlId}`;
    return <></>;
  }

  return accessToken && userSessionId  ? (
    <Redirect
      to={{
        pathname: redirectpathname ? redirectpathname : "/transactions",
        ...(redirectpathname && searchQuery ? { search: searchQuery } : {}),
        state: { role: "user" },
      }}
    />
  ) : (
    <div style={{ padding: isMobile ? "5rem 2rem" : "5rem 5rem" }}>
      <div>
        <Modal title="RE-Sure" onOk={handleModalClose} onCancel={handleCancel} visible={!destination && location && location.state && location.state.email && isModalVisible ? true : false}>
          <p>Email with verification link has been sent to your email id please verify the link to proceed with RE-Sure.</p>
        </Modal>
      </div>
      <div style={{ paddingBottom: "1rem" }}>
        <h1 className={styles.custFormH1}>Sign In</h1>
        <h2 className={styles.custFormH2}>Welcome to RE-Sure</h2>
      </div>
      <Form
        layout="vertical"
        name="normal_login"
        className={styles.loginForm}
        initialValues={{
          remember: true,
          email: location && location.state && location.state.email,
        }}
        onFinish={handleLoginSubmit}
        validateMessages={validateMessages}
      >
        <Form.Item
          label="Email"
          name="email"
          hasFeedback
          rules={[
            {
              type: "email",
              message: "Not a valid Email!",
            },
            {
              required: true,
              message: "Please Enter Email!",
            },
          ]}
        >
          <Input
            value={emailVal}
            onChange={(e) => {
              setEmailVal(e.target.value);
            }}
            prefix={<UserOutlined className="site-form-item-icon" style={{ marginRight: "15px" }} />}
            placeholder="Enter Email"
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            { required: true, message: "Please Enter Password!" },
            {
              validator: (_, value) => (value?.trim().length > 5 ? Promise.resolve() : Promise.reject("Password must be greater than 5 characters!")),
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Enter Password" allowClear iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between">
            <Form.Item>
              <Checkbox style={{ color: "#7f8082" }} checked={true}>
                Keep me logged in
              </Checkbox>
            </Form.Item>
            <Link
              to={{
                pathname: "/forgetpassword",
                state: { email: emailVal && emailVal },
              }}
            >
              {" "}
              Forgot Password?
            </Link>
          </Row>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" className={styles.btnPrimary} loading={userLogInLoading}>
            Log in
          </Button>
          <Row justify="center">
            <Text type="secondary">
              Not yet a member ?<Link to="/signup"> Sign up now</Link>
            </Text>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
}
export { UserLogin };
