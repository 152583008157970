import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import { useSelector } from 'react-redux'
import { TitleCompanyEditModal } from '../../Common/utils/extraFunctions'

const AddCloserModal = ({ add, setAdd, onFinish, handleAdd, companyModalStep, setCompanyModalStep, selectedStatus }) => {
    const { getIndividualTransactionData, getTitleCompanySelectedData } = useSelector((state) => state.rtdTransactionLane);
    const handleTitleCompanyCancel = () => {
        setCompanyModalStep({
        ...companyModalStep,
        step: 0,
        inbuilt: true,
        titleCompany: "",
        titleOffice: "",
        titleCloser: "",
        salesRep: ""
        });
        setAdd({...add,open:false});
    }

    return (
        ((getTitleCompanySelectedData && getTitleCompanySelectedData?.titleCompanyName && (companyModalStep?.selectedBy !== getTitleCompanySelectedData?.selectedBy)) || (getTitleCompanySelectedData && getTitleCompanySelectedData?.affiliateDetails && getTitleCompanySelectedData?.affiliateDetails?.affiliates?.length && getTitleCompanySelectedData?.affiliateDetails?.selectedBy !== companyModalStep?.selectedBy)) ? 
            <Modal
                title={<div style={{display:"flex",alignItems:"center"}}><ExclamationCircleOutlined style={{color:"yellowgreen" ,fontSize:"20px",marginRight:"10px"}} />{add?.section === "Affiliate" ? "Affiliates" : "Title Company"} Already Selected</div>}
                open={true}
                onCancel={handleTitleCompanyCancel}
                style={{ marginTop: "-50px" }}
                footer={false}
            > 
                {/* need testing discussion */}
                <TitleCompanyEditModal
                    titleCompany={getTitleCompanySelectedData?.titleCompanyName}
                    firstAgent={ ((getTitleCompanySelectedData?.selectedBy === "SELLER_AGENT")) ? getIndividualTransactionData?.sellerAgentIds?.[0]?.fullName : getIndividualTransactionData?.buyerAgentIds?.[0]?.fullName}
                    selectedBy={getTitleCompanySelectedData?.selectedBy}
                    section={add?.section}
                />
            </Modal> :
        <Modal
            title={<div style={{display:"flex",justifyContent:"center",fontWeight:"bold",fontSize:"22px",color:"#1990ff"}}>Add {(add?.section === "CLOSER") ? "Closer" : ((add?.section === "SALES_REP") ? "Sales Rep" : add?.section === "Processor" ? "Processor" : add?.section === "Affiliate" ? "Affiliate" : "")}</div>}
            open={true}
            onCancel={() => handleAdd("")}
            style={{ marginTop: "-50px" }}
            footer={false}
        >
            <Form
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item
                    label={<div style={{ fontSize: "15px" }}>Name</div>}
                    name="name"
                    rules={[{ required: true, message: "Please Enter Name!" }]}
                >
                    <Input placeholder="Enter Name" allowClear autoFocus />
                </Form.Item>
                <Form.Item
                    label={<div style={{ fontSize: "15px" }}>Email</div>}
                    name="email"
                    rules={[
                        { required: true, message: "Please Enter Email!" },
                        {
                            type: 'email',
                            message: 'Not a valid Email!',
                        },
                    ]}
                >
                    <Input placeholder="Enter Email" allowClear autoFocus />
                </Form.Item>
                {add?.section === "Affiliate" && <Form.Item
                    label={<div style={{ fontSize: "15px" }}>Role/Title</div>}
                    name="role"
                >
                    <Input placeholder="Enter Role/Title" allowClear autoFocus />
                </Form.Item>}
                <Form.Item
                    label={<div style={{ fontSize: "15px" }}>Phone Number</div>}
                    name="phoneNumber"
                    hasFeedback
                >
                    <PhoneInput
                        inputStyle={{ width: '100%' }}
                        inputClass="phoneInput"
                        country={'us'}
                        onlyCountries={['us']}
                        maxLength="10"
                        international={false}
                        disableCountryCode={true}
                        placeholder="Enter Phone Number"
                        className="phone-input"
                        style={{ width: '286px !important' }}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        style={{
                            width: "100%"
                        }}
                        htmlType="submit"
                    >
                        Add
                    </Button>
                </Form.Item>
            </Form>
        </Modal>    
    )
}

export default AddCloserModal