import React from "react";
import styles from "../../PropertyComp/styles/EmailModal.module.css";
import { Typography } from "antd";
const { Text } = Typography;
const RecipientList = ({ data, sourcePlace }) => (
  <div style={{ fontSize: "18px", }}>
    <div>
      {data?.map((el, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: sourcePlace === "CONFIRM_MODAL" && "-25px",
          }}
        >
          <span className={styles.bulletPoint}>•</span>
          <div
            style={{
              display: "flex",
              whiteSpace: "nowrap",
              paddingRight: 0,
              overflow: sourcePlace === "CONFIRM_MODAL" && "hidden",
              textOverflow: sourcePlace === "CONFIRM_MODAL" && "ellipsis",
            }}
          >
            <span className={styles.fullName}>{el?.fullName}</span>
            <span className={styles.dash}>-</span>
            <Text className={styles.email} ellipsis>
              {el?.email}
            </Text>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default RecipientList;
