import React, { useState } from "react";
import { Avatar, List, Skeleton, Tooltip, Popconfirm, Typography } from "antd";
import { UserOutlined, FlagOutlined, DeleteTwoTone } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { appStatusChange } from "../state/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import dotEnv from "dotenv";
import { encryptStr, encryptUrlParams } from "../../Common/utils/extraFunctions";
import { getCategoryDataHandle } from "../../PropertyComp/utils/helper";
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;
const { Title } = Typography;
const EachNotificialtion = ({ item, notiCount, actualNotiCount, setAcutalNotiCount, setNotiCount, read, setVisible, setPage, isSmallScreen }) => {
  let dispatch = useDispatch();
  const [isRead, setIsRead] = useState(item.isRead);
  const [isFlagged, setIsFlagged] = useState(item.isFlagged);
  const history = useHistory();

  const falgButtonClick = (item) => {
    let data = {
      _id: item._id,
      isFlagged: isFlagged ? false : true,
    };
    dispatch(appStatusChange.updateNotificationFun(data));
    setIsFlagged((prev) => !prev);
  };

  const isReadButtonClick = (item) => {
    if (!isRead) {
      let data = {
        _id: item._id,
        isRead: true,
      };
      dispatch(appStatusChange.updateNotificationFun(data));
      setIsRead(true);
      if (notiCount === "9+" && actualNotiCount === 10) {
        setAcutalNotiCount((prev) => prev - 1);
        setNotiCount(9);
      } else if (notiCount === "9+" && actualNotiCount > 10) {
        setAcutalNotiCount((prev) => Math.max(0, prev - 1));
        setNotiCount("9+");
      } else {
        setAcutalNotiCount((prev) => Math.max(0, prev - 1));
        setNotiCount((prev) => Math.max(0, prev - 1));
      }
    }
    setVisible(false);
    const { type, openFor, primaryPersonId, redirectToSection, urlId, offerId, delegateOwnerId } = item;
    switch (type) {
      case "CLIENT_DOCUMENT_SIGNED":
        history.push(`/contacts?reqId=${primaryPersonId}&key=Docs`);
        break;

      case "SHARED_PROPERTY_DOC":
      case "OFFER_DOCUMENT_SUBMITTED":
        const encryptedOwnerId = ["TC_RTD"].includes(redirectToSection) && encryptUrlParams(delegateOwnerId);
        if (openFor === "SELLER_AGENT") {
          if (["PRE_RTD", "OMD", "TC_PRE_RTD", "TC_OMD"].includes(redirectToSection)) {
            if (!urlId) {
              const { category, categoryId, status } = getCategoryDataHandle({ notificatonData: item });
              history.push({ pathname: "/transactions", state: { category, categoryId, status } });
              return;
            }
            let redirectPathname;
            if (["OMD", "TC_OMD"].includes(redirectToSection)) {
              redirectPathname = `${adminURL}/offer/${urlId}`;
            } else {
              redirectPathname = `${adminURL}/offer/${urlId}/rtd/${encryptStr(offerId)}`;
            }
            window.location.href = redirectPathname;
            return;
          } else {
            if (encryptedOwnerId) {
              history.push(`/account/delegates/transactions/sDashboard/${encryptStr(offerId)}?ref=${encryptedOwnerId}`);
            } else {
              history.push(`/transactions/sDashboard/${encryptStr(offerId)}`);
            }
          }
        } else {
          if (encryptedOwnerId) {
            history.push(`/account/delegates/transactions/bDashboard/${encryptStr(offerId)}?ref=${encryptedOwnerId}`);
          } else {
            history.push(`/transactions/bDashboard/${encryptStr(offerId)}`);
          }
        }
        break;

      default:
        const { category, categoryId, status } = getCategoryDataHandle({ notificatonData: item });
        // console.log("category", category, "categoryId", categoryId, "status", status, "item", item);
        history.push({ pathname: redirectToSection==="TC_TRANSACTION" ? "/account/delegates/transactions" : "/transactions", state: { category, categoryId, status } });
        break;
    }
  };

  
  const deleteNotification = (item) => {
    dispatch(appStatusChange.deleteNotificationtData(item._id));
    setPage(1);
    if (!isRead) {
      if (notiCount === "9+" && actualNotiCount === 10) {
        setAcutalNotiCount((prev) => prev - 1);
        setNotiCount(9);
      } else if (notiCount === "9+" && actualNotiCount > 10) {
        setAcutalNotiCount((prev) => Math.max(0, prev - 1));
        setNotiCount("9+");
      } else {
        setAcutalNotiCount((prev) => Math.max(0, prev - 1));
        setNotiCount((prev) => Math.max(0, prev - 1));
      }
    } else {
      return;
    }
  };
  return (
    <div>
      <List.Item
        style={{
          background: read ? "white" : !isRead ? "#D4E9FD" : "white",
          width: "100%",
          padding: "15px 25px 15px 25px",
        }}
      >
        <Skeleton avatar title={false} loading={item.loading} active>
          <List.Item.Meta
            onClick={() => {
              isReadButtonClick(item);
            }}
            // style={{ color: isRead ? "green" : "blue" }}
            avatar={
              item?.personData?.profileImg?.original ? (
                <Tooltip title={item.personData?.fullName} placement="left">
                  <Avatar
                    style={{
                      border: "3px solid #085191",
                    }}
                    size={isSmallScreen ? 40 : 52}
                    src={item?.personData?.profileImg?.original}
                  />
                </Tooltip>
              ) : item?.personData?.firstName ? (
                <Tooltip title={item?.personData?.fullName} placement="left" >
                  <Avatar size={isSmallScreen ? 40 : 52} style={{ background: "#085191" }}>
                    {(item?.personData?.firstName ? item?.personData?.firstName[0].toUpperCase() : "") + "" + (item?.personData?.lastName ? item?.personData?.lastName[0]?.toUpperCase() : "")}
                  </Avatar>
                </Tooltip>
              ) : (
                <Avatar style={{ background: "#ADD8E6" }} size={45} icon={<UserOutlined />} />
              )
            }
            title={<Title style={{ color:'grey', fontSize: isSmallScreen ? '12px' : '17px'}}>{item.message}</Title>}
            description={
              <div>
                <p style={{ color: "#178DFA", fontWeight: "bolder", fontSize: isSmallScreen ? '10px' : '15px' }}>{item.address}</p>
                <p style={{ marginTop: "-10px", fontSize: isSmallScreen ? '10px' : '14px' }}>{moment(item.createdAt).format("MMMM D, YYYY hh:mm A")}</p>
              </div>
            }
          />
          <div style={{ marginTop: "-20px", marginLeft: "10px" }}>
            <div className="clickFlag">
              <FlagOutlined
                style={{
                  fontSize: isSmallScreen ? "17px" : "26px",
                  color: isFlagged === true ? "red" : " ",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  falgButtonClick(item);
                }}
              />
            </div>
            <div>
              <Popconfirm title={<p style={{ width: "250px" }}>Are you sure you want to delete this notification?</p>} onConfirm={() => deleteNotification(item)} okText="Yes" cancelText="No">
                <DeleteTwoTone
                  style={{
                    fontSize: isSmallScreen ? "17px" : "22px",
                  }}
                />
              </Popconfirm>
            </div>
          </div>
        </Skeleton>
      </List.Item>
    </div>
  );
};

export default EachNotificialtion;
