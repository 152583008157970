import { Avatar, Button, Collapse, Form, Input, Tabs, Tooltip, List, Row, Col } from "antd";
import React, { useState } from "react";
import { PlusCircleFilled, UserOutlined, CaretRightOutlined, DeleteTwoTone, UserAddOutlined } from "@ant-design/icons";
import { clientAction } from "../../Client/state/actions";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { EmptyData } from "../../Common/components/EmptyData";
import TabPane from "antd/lib/tabs/TabPane";
import { generateFullNameFromObj, generateInitialsFromObj } from "../../Common/utils/extraFunctions";
import { CreateCustomerDrawer } from "./CreateCustomerDrawer";
import { documentAction } from "../state/actions";
import { useMediaQuery } from "react-responsive";
import DelegateSeller from "./DelegateSeller";
import EachCorpSigner from "./EachCorpSigner";
import styles from "../style/EditSellerBuyerCorp.module.css"
// import { ClientList } from "../../Client/components/ClientList";

const CorpSigners = ({ setPage, handleDeleteClientOrCorp, source, sourceConditon, setShowClientListModal, delegate, setListingModalFields, onChange, delegateOwnerId, selectedSellerAgent ,addCorps,addPersons,setAddPerson,setAddCorps}) => {
  const dispatch = useDispatch();
 
  const { Panel } = Collapse;
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const { clientsArray, drawerVisible, entityName } = useSelector((state) => state.documentSection.CorpReducer);

  const handleAddSigners = (element) => {
   
    setShowClientListModal(true);
    dispatch(documentAction.setCorpType(true));
    dispatch(documentAction.setCorpState({ currentlySelectedCorpData: element }));
  };
  const handleEntityNameChange = (e) => {
    dispatch(documentAction.setCorpState({ entityName: e.target.value }));
  };
  const handleInputOrSearchClick = (element) => {
    setShowClientListModal(true);
    // dispatch(documentAction.setCorpState({ showClientListModal: true }));
    dispatch(clientAction.clearGetClientData());
    dispatch(documentAction.setCorpType(false));
    setPage([1]);
  };
  const handleCreateUserClick = (element) => {
    dispatch(documentAction.setCorpState({ drawerVisible: true }));
  };

  const handleCorpAdd = () => {
    dispatch(documentAction.setCorpState({ entityName: "" }));
    let corpInfo = {
      fullName: entityName?.trim(),
      isCorp: true,
      key: uuidv4(),
      signers: [],
    };

    let newData = [...(clientsArray || []), corpInfo];
    dispatch(documentAction.setCorpState({ clientsArray: newData }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCorpAdd();
    }
  };
  let drawerClose = () => {
    dispatch(documentAction.clearCustomerData());
    dispatch(documentAction.setCorpState({ drawerVisible: false }));
  };

  const corpClients = clientsArray?.filter((ele) => ele.isCorp) || [];
  const nonCorporateClients = clientsArray?.filter((ele) => !ele.isCorp) || [];
  return (
    <>
      {/* create new customer */}
      <CreateCustomerDrawer visible={drawerVisible} onClose={drawerClose} nameData="contact" delegateOwnerId={delegateOwnerId} />
      {delegate && (
        <>
          <DelegateSeller setListingModalFields={setListingModalFields} onChange={onChange} selectedSellerAgent={selectedSellerAgent} />
          <hr style={{ marginTop: "30px" }} />
        </>
      )}
      <div className={styles.contentDiv}>
        <div>
          {/* <Form layout="vertical" initialValues={{}} autoComplete="off"> */}
          {sourceConditon !== "NO_EDIT" ? (
            <>
              <div className={styles.personheading}>
                <text>Persons</text>
                <PlusCircleFilled
                  style={{
                    fontSize: "25px",
                    fill: "#085191",
                    color: "#085191",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    setAddPerson(!addPersons);
                    dispatch(documentAction.setCorpType(false));
                  }}
                />
              </div>
              {addPersons && (
                <Form.Item name="buyer" rules={[{ required: true, message: "" }]}>
                  <div style={{ display: "flex", gap: "10px", width: "100%", justifyContent: "center", marginTop: "10px" }}>
                    <Input placeholder={`Select a person`} onClick={handleInputOrSearchClick} disabled={delegate ? !delegateOwnerId : false} />
                    <Tooltip placement="top" title="Create contact">
                      <Button disabled={delegate ? !delegateOwnerId : false} onClick={handleCreateUserClick}>
                        <UserAddOutlined style={{ fontSize: "18px", color: "#5959dcd9" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </Form.Item>
              )}
            </>
          ) : null}

          {nonCorporateClients.length > 0 ?  (
            <Form.Item>
              <Row>
                <Col className={styles.selectedRowsOuterDivParent}>
                  <div className={styles.selectedRowsOuterDiv}>
                    <List
                      style={{
                        margin: isMobile ? "-5px 0px" : addPersons?"-10px -10px":"5px -10px",
                        width: "90%",
                      }}
                      dataSource={clientsArray.filter((ele) => !ele.isCorp)}
                      renderItem={(item) => {
                        const intials = generateInitialsFromObj(item);
                        return (
                          <List.Item key={item._id}>
                            <List.Item.Meta
                              avatar={
                                intials ? (
                                  <Avatar size={isMobile?35:45} style={{ background: "#085191",marginTop:isMobile?"10px":0 }}>
                                    {intials}
                                  </Avatar>
                                ) : (
                                  <Avatar size={isMobile?35:45} icon={<UserOutlined />} style={{ background: "#085191" }} />
                                )
                              }
                              title={<span style={{ fontSize: "15px", display: "inline-block", verticalAlign: "middle", lineHeight: "45px" }}>{generateFullNameFromObj(item)}</span>}
                              // description={<span style={{ fontSize: "15px" }}>{item?.email}</span>}
                            />
                            {sourceConditon === "NO_EDIT" ? null : (
                              <span onClick={() => handleDeleteClientOrCorp(item, "CLIENT")} style={{ cursor: "pointer" }}>
                                <DeleteTwoTone style={{ fontSize: "medium" }} />
                              </span>
                            )}
                          </List.Item>
                        );
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Form.Item>
          ) : (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <EmptyData />
            </div>
          )}

          {/* </Form>{" "} */}
        </div>

        <div style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "stretch",  marginBottom: "20px" }}>
          {sourceConditon === "NO_EDIT" ? null : (
            <>
              <div className={styles.corpheading}>
                <text>Corporation/Trust/Other</text>
                <PlusCircleFilled
                  style={{
                    fontSize: "25px",
                    fill: "#085191",
                    color: "#085191",
                    marginLeft: "5px",
                  }}
                  onClick={() => {
                    setAddCorps(!addCorps);
                    dispatch(documentAction.setCorpType(true));
                  }}
                />
              </div>

              {addCorps && (
                <div style={{ display: "flex", gap: "10px", width: "100%",marginTop:"10px "}}>
                  <Input placeholder="Enter Corporation/Trust Name" onKeyPress={handleKeyPress} name="trustee" value={entityName || ""} onChange={(e) => handleEntityNameChange(e)} />
                  <Button type="primary" onClick={handleCorpAdd} disabled={!entityName}>
                    Add
                  </Button>
                </div>
              )}
            </>
          )}
          {corpClients && corpClients.length > 0 ? (
     clientsArray
    .filter((ele) => ele.isCorp)
    .map((ele, ind) => (
      <div key={ind} style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                textTransform: "capitalize",
                maxWidth: isMobile ? "160px" : "auto",
                minWidth: isMobile ? "160px" : "70%",
                wordBreak: "break-word", // Ensures long words wrap to the next line
                whiteSpace: "normal", // Allows wrapping of text
              }}
            >
              {ele.fullName}
            </span>
            {sourceConditon !== "NO_EDIT" && (
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "15px" }}>
                  <Tooltip title="Add Signers">
                    <PlusCircleFilled
                      onClick={(e) => {
                        e.stopPropagation();
                        handleAddSigners(ele);
                      }}
                      style={{
                        fontSize: "20px",
                        fill: "#085191",
                        color: "#085191",
                        marginTop: "6px",
                      }}
                    />
                  </Tooltip>
                </div>

                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteClientOrCorp(ele, "CORP");
                  }}
                >
                  <Tooltip title="Delete Corporation/Trust">
                    <DeleteTwoTone
                      style={{
                        fontSize: "medium",
                        marginTop: "8px",
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Display Signers */}
        {(ele.signers || []).length > 0 && (
          <List
            dataSource={ele.signers}
            renderItem={(item) => {
              const initials = generateInitialsFromObj(item);
              return (
                <EachCorpSigner
                  key={item?._id}
                  item={item}
                  ele={ele}
                  initials={initials}
                  isMobile={isMobile}
                  handleDelete={handleDeleteClientOrCorp}
                  clientsArray={clientsArray}
                  source={"SIGNER_MODAL"}
                />
              );
            }}
          />
        )}
      </div>
    ))
) : (
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center",marginTop:isMobile?"50px":0 }}>
              <EmptyData />
            </div>
)}

        </div>
      </div>
    </>
  );
};

export { CorpSigners };
