/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Tooltip, message, Checkbox, Typography } from "antd";
import React, { useEffect, useState } from "react";
import StatusLabel from "./Status";
import moment from "moment";
import styles from "../styles/profileDetails.module.css";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { LinkOutlined, UserOutlined, } from "@ant-design/icons";
import MLSModal from "./MLSModal";
import UserDetailsModal from "./UserDetailsModal";
import { generateDataForShowclientOnTop, generateInitials } from "../../Common/utils/extraFunctions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PropertyHandlers } from "../state/actions";
import { SignersModal } from "./SignersModal";
import { accountHandle } from "../../Account/state/actions";
import { checkIfDisabled, disabledTransactionText, isSellingNotExecutedFunc } from "../../Common/commondata/commonData";
import { useMediaQuery } from "react-responsive";




const ProfileDetails = ({ item, section, filterSort, setSelectedPropertyId, selectedPropertyId, selectedTransaction, readOnly }) => {
  const delegate =  (section === "DELEGATE");
  const limited =  ((section === "LIMITED_TRANSACTION") || (section === "HOME"));
  const delegateAndLimited = (section === "DELEGATE") || (section === "LIMITED_TRANSACTION") || (section === "HOME");
  const home = (section === "HOME");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isMobile = useMediaQuery({ maxWidth: 803 })
  
  const agentDetails =  item?.currentAgentId 
  const [isMLSVisible, setIsMLSVisible] = useState({
    addModal: false,
    editModal: false,
    defaultAddress: item?.addressDetails
  });

  const [state, setState] = useState({
        isUseDetailModal: false,
        isSignersModal: false,
        });

  useEffect(()=>{
    if(item.isProperty && item.propertyId){
      if(item.propertyId === selectedPropertyId){
        handleMls();
      }
    }
  }, [])

  //   method call for OMD
  const offerTableHandleDocument = (record) => {
    let buildData = {
      builtForId: agentDetails?._id,
      key: agentDetails?._id,
      builtForSection: "OFFER_TABLE",
      propertyId: record?.propertyId,
      propertyImage: record?.images[0]?.imageUrl,
      fullAddress: record?.address,
      token: localStorage.getItem("userAuth"),
      ...(delegate ? {delegateOwnerId: agentDetails?._id} : {})
    };
    dispatch(PropertyHandlers.offerTableUrlShorthandApiCalling(buildData, location?.pathname));
  };

  const redirectOfferTable = (e) => {
    e.stopPropagation();
    if(item?.totalOffers > 0){
      offerTableHandleDocument(item)
    }
    else message.warning("No Offers")
  }

  const handleAccessAccount = (e, item) => {
    e.stopPropagation();
    // confirm({
    //   title: `Are you sure you want to go to the account of ${item?.currentAgentId?.fullName?`,
    //   content: "Note: You will be logged out from your current account",
    //   okText: "Yes",
    //   cancelText: "No",
    //   icon: <ExclamationCircleOutlined />,
    //   onOk() {
        dispatch(accountHandle.updateAccessProfile({delegateOwnerId: agentDetails._id}, true));
    //   },
    //   onCancel() {},
    // });
  };

  const profileInfo = ({ item, type }) => {
    const sellerAgentDetails = item?.currentAgentId; 
    if(delegate){
      if(item?.isProperty){
        if(type === "IMAGE") return sellerAgentDetails?.profileImg?.original
        else if(type === "IMAGE_TEXT") return (sellerAgentDetails?.firstName?.charAt(0)?.toUpperCase() || "") + " " + (sellerAgentDetails?.lastName?.charAt(0)?.toUpperCase() || "")
        else if(type === "BROKER_NAME") return sellerAgentDetails?.fullName
        else if(type === "BROKER_BROKERAGE") return   sellerAgentDetails?.brokerageData && sellerAgentDetails?.brokerageData?.name

      }
      else {
        const buyerAgentDetails = item?.currentAgentId; 
        if(type === "IMAGE") return buyerAgentDetails?.profileImg?.original
        else if(type === "IMAGE_TEXT") return (buyerAgentDetails?.firstName?.charAt(0)?.toUpperCase() || "") + " " + (buyerAgentDetails?.lastName?.charAt(0)?.toUpperCase() || "")
        else if(type === "BROKER_NAME") return buyerAgentDetails?.fullName
        else if(type === "BROKER_BROKERAGE") return buyerAgentDetails?.brokerageData && buyerAgentDetails?.brokerageData?.name

      }
    }
    else{
      if(item?.isProperty){
        if(type === "IMAGE"){
          if(item?.sellerIds?.[0]?.isCorp) return item?.sellerIds?.[0]?.signers?.[0]?.personId?.profileImg?.original
          else return item?.sellerIds?.[0]?.personId?.profileImg?.original
        }
        else if(type === "IMAGE_TEXT"){
          if (item?.sellerIds?.[0]?.isCorp) return generateInitials(item?.sellerIds?.[0]?.signers?.[0]?.personId?.fullName || item?.sellerIds?.[0]?.signers?.[0]?.fullName)
          else return generateInitials(item?.sellerIds?.[0]?.personId?.fullName|| item?.sellerIds?.[0]?.fullName)
        }
      }
      else{
        if(type === "IMAGE"){
          if(item?.buyerIds?.[0]?.isCorp) return item?.buyerIds?.[0]?.signers?.[0]?.personId?.profileImg?.original
          else return item?.buyerIds?.[0]?.personId?.profileImg?.original
        }
        else if(type === "IMAGE_TEXT"){
          if (item?.buyerIds?.[0]?.isCorp) return generateInitials(item?.buyerIds?.[0]?.signers?.[0]?.personId?.fullName || item?.buyerIds?.[0]?.signers?.[0]?.fullName)
          else return generateInitials(item?.buyerIds?.[0]?.personId?.fullName|| item?.buyerIds?.[0]?.fullName)
        }
      }
    }
  }

  const propertyImages = (ele) => {
    if(ele?.propertyType?.includes("PRIVATE")){
      return (<Tooltip placement="top" title="RE-Sure"><img style={{width: "29px", borderRadius: "2px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/65b8f71bdff9fe6ba44c8501/MEMORIES/16RE-Sure%20Blue%20Bug.png" alt="RE-Sure"/></Tooltip>)
    }
    else if(ele?.propertyType?.some(ele => ele.includes("RECOLORADO"))){
      return (<Tooltip placement="top" title="REcolorado"><img style={{width: "31px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage82704" alt="REcolorado"/></Tooltip>)
    }
    else if(ele?.propertyType?.some(ele => ele.includes("IRES"))){
      return (<Tooltip placement="top" title="IRES"><img style={{width: "77px", height: "24px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage84057" alt="IRES"/></Tooltip>)
    }
    else if(ele?.propertyType?.some(ele => ele.includes("PPAR"))){
      return (<Tooltip placement="top" title="PPAR"><img style={{width: "73px", height: "24px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/657ff2157e660a0701cfaa24/MEMORIES/19PPMLS-logo-MAIN-WebRGB.jpeg" alt="PPAR"/></Tooltip>);
    }
    else if(ele?.propertyType?.some(ele => ele.includes("PAR"))){
      return (<Tooltip placement="top" title="PAR"><img style={{width: "68px", height: "24px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/qa/6444b9c521382a6780572f63/MEMORIES/2pastedImage68271" alt="PAR"/></Tooltip>);
    }
    else if(ele?.propertyType?.some(ele => ele.includes("GJARA"))){
      return (<Tooltip placement="top" title="GJARA"><img style={{width: "77px", height: "27px", cursor: "pointer"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/prod/64cb4128b00a232d9c2cc0d4/MEMORIES/29gjara-logo.png" alt="GJARA"/></Tooltip>);
    }
    else return "";
  }

  const showMLSModal = (e) => {
    e.stopPropagation();
    handleMls();
    setSelectedPropertyId(item.propertyId);
  }

  const handleMls = (isClose) => {
    if(item?.linkedPropertyIds?.length){
      setIsMLSVisible({
        ...isMLSVisible,
        editModal: true
      })
    }
    else{
      setIsMLSVisible({
        ...isMLSVisible,
        addModal: isClose ? false : true
      })
    }
  }

  const handleMLSCancel = () => {
    setSelectedPropertyId(null);
    setIsMLSVisible({
      ...isMLSVisible,
      editModal: false,
      addModal: false
    })
  }

  const handleLinkMoreMls = (item) => {
    setIsMLSVisible({
      ...isMLSVisible,
      editModal: false,
      addModal: true
    })
  }

  const getPrimaryContact = (item) => {
    if(item?.isProperty || item?.isErts){
      if(item?.sellerIds?.[0]?.isCorp) return item?.sellerIds?.[0]?.signers?.[0]?.personId?._id
      else return item?.sellerIds?.[0]?.personId?._id
    } else {
      if(item?.buyerIds?.[0]?.isCorp) return item?.buyerIds?.[0]?.signers?.[0]?.personId?._id
      else return item?.buyerIds?.[0]?.personId?._id
    }
  }

  const handleImageClick = (item) => {
    const primaryContact = getPrimaryContact(item);
    let {redirect, stateObj}=  generateDataForShowclientOnTop((item?.isProperty) ? item?.sellerIds : item?.buyerIds);
    if (redirect) {
      dispatch(PropertyHandlers.getIfContactIsDeletedOrArchived(primaryContact, history, stateObj))
    //   history.push(stateObj);
    } else {
      return;
    }
  };

  const handleCheck = () => {
    if(item.isOffer){
      if(selectedTransaction?.buying?.includes(item.offerId)){
        return true
      }
      return false;
    }
    else if(item.isProperty){
      if(selectedTransaction?.sellingProperty?.includes(item.propertyId)){
        return true;
      }
      return false;
    }
    return false;
  };

  const transactionStatus = (item?.isProperty) ? item?.propertyStatus : item?.actualOfferStatus;
  const actualTransactionStatus = (item?.isProperty) ? item?.actualPropertyStatus : item?.status;

  const checkForDisable = checkIfDisabled({openFor: (item?.isProperty) ? "SELLER" : "BUYER", actualPropertyStatus: item?.actualPropertyStatus, buyingStatus: item?.actualOfferStatus, offerStatus: item?.status});

  const displayBuyersAndSellers = () => {
    return <div style={{display: "flex"}}>
    {
      (item?.isProperty) ? item?.sellerIds?.length > 0 ?
        <div style={{display: "inline-block"}} className={!limited && styles.hoverClients} {...((!limited) ? {onClick: (e) => { e.stopPropagation(); setState({...state, isSignersModal: true})}} : {})}>
          <div className={styles.singleTextWraping}>
            Sellers: {
              item?.sellerIds?.map((el, index, array) => (
                <>
                  {!el?.isCorp ? (
                    <span key={index}>
                        {el?.fullName}
                        {index === array.length - 1 ? "" : ", "}
                    </span>
                    ) : (
                    <span>
                        {el?.fullName}
                        {el?.signers && el?.signers.length > 0 && (
                        <span>
                            {" ("}
                            {el?.signers.map((ele, ind, arr) => (
                            <span key={ind}>
                                {ele?.fullName}
                                {ind === arr.length - 1 ? "" : ", "}
                            </span>
                            ))}
                            {")"}
                            {index === array.length - 1 ? "" : ", "}
                        </span>
                        )}
                    </span>
                  )}
                </>
              ))
            }
          </div>
        </div>
      :
      (<div style={{display: "inline-block"}} {...((!limited) ? {onClick: (e)=> {e.stopPropagation(); setState({...state,isUseDetailModal:true})}} : {})}
          className={`${styles.addSellers} ${(!limited) &&  styles.hoverClients}`}
        >
          Add Sellers To Listing
      </div>) :
      <div style={{display: "inline-block"}} className={!limited && styles.hoverClients} {...((!limited) ? {onClick: (e) => { e.stopPropagation(); setState({...state, isSignersModal: true})}} : {})}>
        <div className={styles.singleTextWraping}>
          Buyers: {
            item?.buyerIds?.map((el, index, array) => (
              <>
                {!el?.isCorp ? (
                  <span key={index}>
                      {el?.fullName}
                      {index === array.length - 1 ? "" : ", "}
                  </span>
                  ) : (
                  <span>
                      {el?.fullName}
                      {el?.signers && el?.signers.length > 0 && (
                      <span>
                          {" ("}
                          {el?.signers.map((ele, ind, arr) => (
                          <span key={ind}>
                              {ele?.fullName}
                              {ind === arr.length - 1 ? "" : ", "}
                          </span>
                          ))}
                          {")"}
                          {index === array.length - 1 ? "" : ", "}
                      </span>
                      )}
                  </span>
                )}
              </>
            ))
          }
        </div>
      </div>
    }
</div>
  }

  const isSellingNotExecuted = isSellingNotExecutedFunc({item});
  return (
    <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
      {
        (!(readOnly?.value || readOnly?.home)) && (limited && <Checkbox
          checked={ handleCheck() }
        />)
      }
      <div style={{width: "100%"}}>
        <div onClick={(e)=>e.stopPropagation()}>
          {
            (isMLSVisible.addModal || isMLSVisible.editModal) && <MLSModal handleLinkMoreMls={handleLinkMoreMls} isMLSVisible={isMLSVisible} filterSort={filterSort} selectedData={item} handleMLSCancel={handleMLSCancel} handleMls={handleMls} section={section}/>
          }
          {state.isUseDetailModal && <UserDetailsModal item={item} state={state} section={section} filterSort={filterSort} setState={setState}  delegateOwnerId={delegate && agentDetails?._id}/>}
          {state.isSignersModal && <SignersModal item={item} state={state} setState={setState} />}
        </div>
        <div className={home ? styles.homeProfileBox : styles.profileBox}>
          {/* profile img div  */}
          <div className={home ? styles.homeProfileImgBox : styles.profileImgBox}>
            <div>
              {profileInfo({item, type: "IMAGE"}) ? (
                <Avatar {...((!delegateAndLimited) ? {onClick: ()=>handleImageClick(item)} : {})}
                  style={{ border: "1px solid grey" }}
                  className={!delegateAndLimited && styles.profileImg}
                  size={60}
                  src={profileInfo({item, type: "IMAGE"})}
                />
              ) : (
                <Avatar {...((!delegateAndLimited) ? {onClick: ()=>handleImageClick(item)} : {})} size={60} style={{ background: "#085191", fontSize: "20px" }} className={(profileInfo({item, type: "IMAGE_TEXT"}) && !delegateAndLimited) && styles.profileImg2}>
                  {profileInfo({item, type: "IMAGE_TEXT"}) && profileInfo({item, type: "IMAGE_TEXT"}) !== "undefined" ?
                  profileInfo({item, type: "IMAGE_TEXT"}) :
                  <UserOutlined style={{fontSize:"25px",}} />}
                </Avatar>
              )}
            </div>

            {/* name broker property detail div  */}
            <div className={home ? styles.homeBrokerDetails : styles.brokerDetails}>
              {
                delegate ?
                <div className={styles.singleTextWraping}>
                  <Typography.Text ellipsis style={{ flex: '1 1 auto', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: (item?.currentAgentId?.brokerageData && item?.currentAgentId?.brokerageData?.name && !isMobile) ? "70%" : "" }}>
                    Agent: {profileInfo({ item, type: "BROKER_NAME" })} {profileInfo({ item, type: "BROKER_BROKERAGE" }) && ` - ${profileInfo({ item, type: "BROKER_BROKERAGE" })}`}
                  </Typography.Text>
                  <button
                    style={{ flex: '0 0 auto', marginLeft: '10px', padding: '4px', lineHeight: '13px', cursor: 'pointer', fontSize: '15px' }}
                    className={`${(!limited) && styles.hoverClients}`}
                    onClick={(e) => handleAccessAccount(e, item)}
                  >
                    Access Account
                  </button>
                </div>
                : 
                displayBuyersAndSellers()
              }
              <div className={styles.singleTextWraping}>{item?.address}</div>
              {
                delegate && displayBuyersAndSellers()
              }
              <div style={{display: "flex", gap: "10px", alignItems: "center", color: "rgb(96, 96, 96)", padding: "4px 0px"}}>
                {
                  (item?.linkedPropertyIds?.length) ? item?.linkedPropertyIds?.map((ele)=>{
                    return propertyImages(ele?.propertyId)
                  }) : propertyImages(item)
                }
                {!limited && <div className={styles.linkMls} style={{lineHeight: "21px"}}>
                    {((item?.isProperty && isSellingNotExecuted) && item?.propertyType?.includes("PRIVATE")) &&
                      <Tooltip title="Link To MLS">
                        <LinkOutlined style={{fontSize:"20px"}} {...(checkForDisable?.disabled ? {onClick: (e)=>{ e.stopPropagation();disabledTransactionText();}} : {onClick: showMLSModal})}/>
                      </Tooltip>
                    }
                </div>}
              </div>
            </div>
          </div>

          {/* status & dates div  */}
          <div className={home ? styles.homeStatusBox : styles.statusBox}>
          <div>
              <div>
                <StatusLabel currentStatus={transactionStatus} actualStatus={actualTransactionStatus} item={item} filterSort={filterSort} limited={limited} section={section}/>
              </div>

              <div style={{marginTop: "4px",fontSize: home ? "0.8rem" : ""}}>
                Updated:{" "}
                {moment(item?.updatedAt).format(
                  "MM/DD/YYYY hh:mm a",
                )}{" "}
              </div>
              {item?.closingDate && !isNaN(Date.parse(item?.closingDate)) && <div style={{fontSize: home ? "0.8rem" : ""}}>Closing: {moment(item?.closingDate).format("MM/DD/YYYY")}</div>}
            </div>
          </div>

          {/* OMD & side div  */}
          <div className={home ? styles.homeOfferDetailBox : styles.offerDetailBox}>
            <div
              className={(item?.isProperty) ? home ? styles.homeSellingCard : styles.sellingCard : home ? styles.homeBuyingCard : styles.buyingCard}
            >
              {item?.isProperty ? "Selling" : "Buying"}
             
            </div>

            {(item?.isProperty && isSellingNotExecuted) ? (
            <div
              className={`${home ? styles.homeOfferNumberCard : styles.offerNumberCard} ${(!limited) &&  styles.hoverClients}`}
              {...((!limited) ? {onClick: redirectOfferTable} : {})}
            >
              <div>{item?.totalOffers || 0}</div>
              <div>{(item?.totalOffers === 1) ? "Offer" : "Offers"}</div>
            </div>)
            : (<></>)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
