import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import style from "../style/DocumentSection.module.css";
import { LeftCircleOutlined, CaretRightOutlined } from "@ant-design/icons";
import { EmptyData } from "../../Common/components/EmptyData";
import { documentAction } from "../state/actions";
import { Loading } from "../../Common/components/Loading";
import { combineAgentWithComma } from "../../Common/commondata/commonData";

function PropertyTable({
  handleCancelPropertySearch,
  handleBackButton,
  skip,
  setSkip,
  limit,
  addressData,
  setFieldIsEmpty,
  fieldEmpty,
  setisownerDetailModal,
  redirectFromClient
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  let { getPropertySearchData,getPropertySearchLoading, propertySearchNextPage, propertySearchPreviousPage } = useSelector(
    (state) => state.documentSection.PropertySearchReducer
  );
  const { Panel } = Collapse;
  const { demoMode } = useSelector((state) => state.app);
  const [propertyData,setpropertyData]=useState([])

  useEffect(()=>{
    setpropertyData(getPropertySearchData)
  },[getPropertySearchData])


  const prevPage = () => {
    if (propertySearchPreviousPage) {
      const newSkip = skip - limit;
      setCurrentPage(currentPage - 1);
      setSkip(newSkip);
      dispatch(documentAction.getPropertySearch(addressData, demoMode,newSkip,limit));
    }
  };

  const nextPage = () => {
      if (propertySearchNextPage) {
        const newSkip = skip + limit;
        setCurrentPage(currentPage + 1);
        setSkip(newSkip);
        dispatch(documentAction.getPropertySearch(addressData, demoMode, newSkip, limit));
      }
  };


  const handleProperty = (item) => {
    redirectFromClient.form.setFieldsValue({price: fieldEmpty?.priceType && fieldEmpty?.priceType != "N/A" ? fieldEmpty?.priceType : item?.listingPrice ? item?.listingPrice : ""})
    setFieldIsEmpty({
      ...fieldEmpty,
      property: item,
      propertyType: item?.propertyType || "",
      priceType: fieldEmpty?.priceType && fieldEmpty?.priceType != "N/A" ? fieldEmpty?.priceType : item?.listingPrice ? item?.listingPrice : "",
    });
    
    handleCancelPropertySearch();
    if(!item?.propertyType?.includes("PRIVATE")){
      setisownerDetailModal(true);
      getOwners(item);
    }
  };

  const PriceComponent = ( price,type ) => {
    const formattedPrice = price?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formattedPrice
  }

  const getOwners = (item) => {
      let streetName = item?.StreetName?.split(" ")?.[0] || "";
      let city = item?.City?.split(" ")?.[0] || "";
      let streetNumber = item?.StreetNumber?.toString() || "";
      let unitNumber = item?.UnitNumber?.toString() || "";
      let latitude = item?.Latitude || "";
      let longitude = item?.Longitude || "";
      let data = {
        ...(streetName ? { StreetName: streetName } : {}),
        ...(city ? { City: city } : {}),
        ...(streetNumber ? { StreetNumber: streetNumber } : {}),
        ...(unitNumber ? { UnitNumber: unitNumber } : {}),
        ...(latitude ? { Latitude: latitude } : {}),
        ...(longitude ? { Longitude: longitude } : {}),
        skip: 0,
        limit: 10,
      }
      dispatch( documentAction.getOwnerSearch(data) );
  }

  return (
    <div style={{height: "572px"}}>
      <div className={style.heading}>
        <LeftCircleOutlined
          onClick={handleBackButton}
          style={{ fontSize: "20px" }}
        />
        <h2 style={{ textDecorationLine: "underline" }}>Search Results</h2>
      </div>
      <div>
        {getPropertySearchLoading ? (
        <Loading />
      ) : (
      <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
        <div className={style.scrollContainer}>{
          propertyData?.length > 0 &&
          propertyData?.length !== 0 ? (
            <Collapse
              bordered={false}
              defaultActiveKey={[]}
              style={{ background: "white",height: "470px"}}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  style={{ fontSize: "20px" }}
                  rotate={isActive ? 90 : 0}
                />
              )}
              className="site-collapse-custom-collapse"
            >
              {propertyData.map((el,i) => {
                return (
                  <Panel
                    header={
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                        <div onClick={() => handleProperty(el)} style={{ cursor: "pointer" }}>
                          {el.fullAddress}
                        </div>
                        <div>
                          {
                            //  el.propertyType.includes("PRIVATE") ? <div style={{backgroundColor: "#085191", color: "white", padding: "0px 8px", borderRadius: "100px"}}>P</div> :
                            el.propertyType?.some(ele => ele.includes("RECOLORADO")) ? <img style={{width: "38px", marginRight: "-5px"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage82704" alt="logo"/> :
                            el.propertyType?.some(ele => ele.includes("IRES")) ? <img style={{width: "60px", height: "27px", marginRight: "-5px"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/64d5ce64dff9fe6ba4cfaf06/MEMORIES/18pastedImage84057" alt="logo"/> :
                            el?.propertyType?.some(ele => ele.includes("PPAR")) ? <img style={{width: "70px", height: "27px", marginRight: "-10px"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/657ff2157e660a0701cfaa24/MEMORIES/19PPMLS-logo-MAIN-WebRGB.jpeg" alt=""/> :
                            el?.propertyType?.some(ele => ele.includes("PAR")) ? <img style={{width: "60px", height: "35px", marginRight: "-5px"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/qa/6444b9c521382a6780572f63/MEMORIES/2pastedImage68271" alt="logo"/> :
                            el?.propertyType?.some(ele => ele.includes("GJARA")) ? <img style={{width: "90px", height: "35px", marginRight: "-5px"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/prod/64cb4128b00a232d9c2cc0d4/MEMORIES/29gjara-logo.png" alt="logo"/> :
                            el?.propertyType?.some(ele => ele.includes("PRIVATE")) ? <img style={{width: "40px", height: "30px", marginRight: "-5px"}} src="https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/65b8f71bdff9fe6ba44c8501/MEMORIES/16RE-Sure%20Blue%20Bug.png" alt="logo"/> : ""
                          }
                        </div>
                      </div>
                    }
                    key={i}
                    className="site-collapse-custom-panel"
                  >
                    <div style={{ width: "90%", margin: "auto", cursor: "pointer" }}>
                      <h3 style={{ ":hover": { background: "blue" } }}>
                        {el?.listingId ? `Listing Id : ${el?.listingId}`: ""}
                      </h3>
                      <h3>Agent : {combineAgentWithComma({sellerAgents: el?.SellerAgents})}</h3>
                      <h3>Price : {PriceComponent(el?.listingPrice,el?.propertyType[0])}</h3>
                      <h3>Property type : {el?.propertyType?.[0] ==="PRIVATE" ? "RE-Sure" : el?.propertyType?.[0] === "RECOLORADO" ? "REcolorado" : el?.propertyType?.[0]}</h3>
                    </div>
                  </Panel>
                )
              })}
            </Collapse>
          ) : (
            <EmptyData />
          )}
          </div>
          <div className={style.pagination}>
            <button
              className={`${style.pageButton} ${propertySearchPreviousPage ? '' : style.disabled}`}
              style={{ width: "80px" }}
              onClick={prevPage}
              disabled={propertySearchPreviousPage ? false : true}
            >
              Previous
            </button>
            <p className={style.pageText}>{currentPage}</p>
            <button
              className={`${style.pageButton} ${propertySearchNextPage ? '' : style.disabled}`}
              style={{ width: "80px" }}
              onClick={nextPage}
              disabled={propertySearchNextPage ? false : true}
            >
              Next
            </button>
          </div>
        </div>)}
      </div>
    </div>
  );
}

export { PropertyTable };
