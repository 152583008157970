/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { documentAction } from "../../state/actions";
import { Row, Col, Spin, Modal, message } from "antd";
import styles from "../../style/offerImport.module.css";
import "../../style/offerImport.css";
// import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
import { BsCurrencyDollar } from "react-icons/bs";
import { formatPrice, handleCheckboxGroupUpdate, isAtleastOneClientPresent, setInitialValues, updatedBuyersData } from "../../utils/helper";
import ManualEditCorpOrClient from "../ManualEditCorpOrClient";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ModifyDatesModal from "./ModifyDatesModal";

const Step2 = ({source, confirmModal, setconfirmModal, handleCancelErts}) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const [modalState, setModalState] = useState({
    editClientModalVisible: false,
    clientType: "Buyers",
    event: {},
    showDateFieldModal: false,
  });
  const [formVisibility, setFormVisibility] = useState({
      addPersons: false,
      addCorps: false,
      addSigners: false,
    });
  let { clientsArray } = useSelector((state) => state.documentSection.CorpReducer);
  const { urlImportData, CurrentPdfImportData } = useSelector((state) => state.documentSection.urlImportReducer);

  const clickHandler = (event) => {
    if (event.target.classList.contains("relativeDateInput")) {
      setModalState((prevState) => ({ ...prevState, showDateFieldModal: true, event }));
    }
  }

useEffect(() => {
  if (Object.keys(urlImportData)?.length) {
    document.querySelectorAll("input,textarea")?.forEach((element) => {
      if (element.disabled === false) {
        if (element.type === "date" || element.type === "time") {
          element.addEventListener("click", function () {
            element.showPicker();
          });
        }
        element.addEventListener("input", handleInput);
        element.addEventListener("blur", handleBlur);
      }
    });
    document.querySelectorAll(".relativeDateInput")?.forEach((element) => {
      element.addEventListener("click", clickHandler);
    });
  }
  // Cleanup function to remove the event listeners
  return () => {
    document.querySelectorAll("input,textarea")?.forEach((element) => {
      if (element.type !== "textarea") {
        element.removeEventListener("click", function () {
          element.showPicker();
        });
      }
      element.removeEventListener("input", handleInput);
      element.removeEventListener("blur", handleBlur);
    });
  };
}, [urlImportData]);



  useEffect(() => {
    if (Array.isArray(CurrentPdfImportData?.Buyers)) {
      updatedBuyersData(CurrentPdfImportData, dispatch);
    }
  }, [CurrentPdfImportData]);

  
  useEffect(() => {
    if (Object.keys(CurrentPdfImportData)?.length) {
      setInitialValues({CurrentPdfImportData, clientsArray})
    }
  }, [CurrentPdfImportData, clientsArray]);

  const handleInput = (event) => {
    const { type } = event.target;
    let specificData = {};
    if (type === "checkbox") {
      if (event?.target?.dataset?.cbGroup && event?.target?.checked) {
        handleCheckboxGroupUpdate(event.target.value, event.target.dataset.cbGroup, specificData);
      }
      const elements = Array.from(document.querySelectorAll(`[name="${event.target.name}"]:checked`));
      const values = elements.filter((e) => e.value !== `${e.name}__Other` && e.value !== "").map((e) => e.value);
      if (values?.length) {
        specificData[event.target.name] = values;
      } else {
        specificData[event.target.name] = "";
      }
      if (event.target.hasAttribute("data-linked-value") && !event.target.checked) {
        event.target.value = `${event.target.name}__Other`;
        document.querySelectorAll(`[data-linked-value="${event.target.name}"][name="${event.target.dataset.linkedValue}"]`).forEach((element) => {
          element.value = "";
          specificData[element.name] = "";
          if (element.parentNode.classList.contains("grow-with-input") || element.parentNode.classList.contains("grow-with-content")) {
            element.parentNode.dataset.replicatedValue = "";
          }
        });
      }
    } else {
      // not a checkbox
      if (event.target.hasAttribute("data-linked-value")) {
        const element = document.querySelector(`[name="${event.target.dataset.linkedValue}"][data-linked-value="${event.target.name}"]`);
        element.value = event.target.value;
        element.checked = true;
        element.dispatchEvent(
          new CustomEvent("input", {
            detail: {
              noApiCall: true,
            },
          })
        );
      }
      specificData[event.target.name] = event.target.value;
      if (event.target.parentNode.classList.contains("grow-with-content")) {
        event.target.parentNode.dataset.replicatedValue = event.target.value;
      }
    }
    handleInputSave({ ...specificData });
    specificData = {};
  };

  const handleInputSave = (valueObj) => {
    if (Object.keys(valueObj)?.length) {
      dispatch(documentAction.setpdfImportState(valueObj));
    }
  };

  const handleBlur = (event) => {
    const { name, value, type } = event.target;
    if (type === "checkbox") {
      return;
    } else {
      if (event.target.hasAttribute("data-price-format")) {
        handleInputSave({ [name]: formatPrice(value) });
      }
    }
  };

  const handleEditClientclick = () => {
    setModalState((prevState) => ({ ...prevState, editClientModalVisible: true,  }));
  };

  const handleCancel = (e) => {
    if (!isAtleastOneClientPresent(clientsArray)) {
      message.error("Please select at least one buyer!");
    } else {
   setModalState((prevState) => ({ ...prevState, editClientModalVisible: false }));
   setFormVisibility((prevState) => ({
     ...prevState,
     addCorps: false,
     addSigners: false,
     addPersons: false,
   }));
    }
  };

  const selectOptions = [
    { value: "", label: "None" },
    { value: "East", label: "East" },
    { value: "West", label: "West" },
    { value: "North", label: "North" },
    { value: "South", label: "South" },
    { value: "Northeast", label: "North East" },
    { value: "Northwest", label: "North West" },
    { value: "Southeast", label: "South East" },
    { value: "Southwest", label: "South West" },
  ];

  const handleConfirm = () => {
    Modal.confirm({
      title: urlImportData?.checkDuplicate?.message,
      okText: "Yes",
      cancelText: "No",
      icon: <ExclamationCircleOutlined />,
    //   content: "Note: This will also remove all the signers associated with it.",
      onOk() {
        setconfirmModal(false)
      },
      onCancel() {handleCancelErts()},
    });
  };

  return (
    <>
      <span>
        {
            confirmModal && handleConfirm()
        }
      </span>
      <Modal open={modalState.editClientModalVisible} width={"650px"} style={{  height: "80vh" }} title={<span style={{ display: "flex", justifyContent: "center", fontSize: "22px", color: "grey" }}>{"EDIT BUYERS"}</span>} onCancel={handleCancel} footer={false}>
        <ManualEditCorpOrClient sourceType={"MANUAL_ADD_CLIENTS"} formVisibility={formVisibility} setFormVisibility={setFormVisibility}/>
      </Modal>
      {modalState?.showDateFieldModal && <ModifyDatesModal modalState={modalState} setModalState={setModalState} CurrentPdfImportData={CurrentPdfImportData} handleInputSave={handleInputSave}  />}
      {!Object.keys(urlImportData)?.length ? (
        <div style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="OIformWrapper" style={{ maxHeight: isMobile ? "300px" : "400px", overflow: "auto", overflowX: "hidden", marginBottom: "30px", padding: "0 10px", }}>
            {/* buyers Information  */}
            {(source !== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow} style={{ marginTop: "25px" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Buyers
                </div>
                <span data-buyers-list style={{ color: "blue", marginRight: "10px" }}></span>
                <span style={{ fontSize: isMobile ? "10px" : "15px", color: "#1890ff", cursor: "pointer" }} onClick={() => handleEditClientclick()}>
                  <EditOutlined />
                </span>
              </Col>
            </Row>)}

            {/* Address information  */}
            {source === "ERTS_IMPORT" ? (
              <Row gutter={[16, 16]} className={styles.formRow}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className={styles.Customlbl}>
                    {" "}
                    <span style={{ color: "red", marginRight: "2px" }}>*</span>Street Number
                  </div>
                  <input name="StreetNumber" placeholder="Enter Street Number" style={{ width: "100%" }} required />
                  <span className={styles.validationMessage}>Please enter the Street Number.</span>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className={styles.Customlbl}>
                    <span style={{ color: "red", marginRight: "2px" }}></span>Direction Prefix
                  </div>
                  <select name="DirPrefix" placeholder="Enter Direction Prefix" style={{ width: "100%", height: "50%" }}>
                    {selectOptions?.map((dir, index) => (
                      <option key={index} value={dir.value}>
                        {dir.label}
                      </option>
                    ))}
                  </select>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className={styles.Customlbl}>
                    {" "}
                    <span style={{ color: "red", marginRight: "2px" }}>*</span>Street Name
                  </div>
                  <input name="StreetName" placeholder="Enter Street Name" style={{ width: "100%" }} required />
                  <span className={styles.validationMessage}>Please enter the Street Name.</span>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className={styles.Customlbl}>
                    {" "}
                    <span style={{ color: "red", marginRight: "2px" }}></span>Street Suffix
                  </div>
                  <input name="StreetSuffix" placeholder="Enter Street Suffix" style={{ width: "100%" }} />
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className={styles.Customlbl}>
                    <span style={{ color: "red", marginRight: "2px" }}></span>Direction Suffix
                  </div>
                  <select name="DirSuffix" placeholder="Enter Direction Suffix" style={{ width: "100%", height: "50%" }}>
                    {selectOptions?.map((dir, index) => (
                      <option key={index} value={dir.value}>
                        {dir.label}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <div className={styles.Customlbl}>
                        {" "}
                        <span style={{ color: "red", marginRight: "2px" }}></span>Unit Label
                      </div>
                      <input name="UnitLabel" placeholder="Enter Unit, Apt or Suite" style={{ width: "100%" }} />
                    </div>
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <div className={styles.Customlbl}>
                        {" "}
                        <span style={{ color: "red", marginRight: "2px" }}></span>Unit Number
                      </div>
                      <input name="UnitNumber" placeholder="Enter Unit Number" style={{ width: "100%" }} />
                    </div>
                  </div>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className={styles.Customlbl}>
                    {" "}
                    <span style={{ color: "red", marginRight: "2px" }}>*</span>City
                  </div>
                  <input name="City" placeholder="Enter City" style={{ width: "100%" }} required />
                  <span className={styles.validationMessage}>Please enter the City.</span>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className={styles.Customlbl}>
                    {" "}
                    <span style={{ color: "red", marginRight: "2px" }}>*</span>State
                  </div>
                  <input name="State" placeholder="Enter State" style={{ width: "100%" }} required />
                  <span className={styles.validationMessage}>Please enter the state.</span>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className={styles.Customlbl}>
                    {" "}
                    <span style={{ color: "red", marginRight: "2px" }}>*</span>Zip Code
                  </div>
                  <input name="ZipCode" placeholder="Enter Zip Code" style={{ width: "100%" }} required />
                  <span className={styles.validationMessage}>Please enter a valid Zip Code.</span>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <div className={styles.Customlbl}>
                    {" "}
                    <span style={{ color: "red", marginRight: "2px" }}></span>Document Date
                  </div>
                  <input type="date" name="DocumentDate" style={{ width: "100%" }} placeholder="Enter Document date" />
                </Col>
              </Row>
            ) : (
              <Row gutter={[16, 16]} className={styles.formRow}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className={styles.Customlbl}>
                    {" "}
                    <span style={{ color: "red", marginRight: "2px" }}>*</span>Property Address
                  </div>
                  <input name="FullAddress" placeholder="Enter Property Address" style={{ width: "100%" }} required />
                  <span className={styles.validationMessage}>Please enter the Property Address.</span>
                </Col>
              </Row>
            )}
            {/* Buyer Agent information & Listing start Date and End Date */}
           { (source==="ERTS_IMPORT") ?
            (<Row gutter={[16, 16]} className={styles.formRow}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className={styles.Customlbl}>
                {" "}
                <span style={{ color: "red", marginRight: "2px" }}>*</span>Listing Start Date
              </div>
              <input type="date" name="ListingPeriodBeginDate" style={{ width: "100%" }} placeholder="Enter Listing Start Date" required />
              <span className={styles.validationMessage}>Please select the Listing Start Date.</span>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <div className={styles.Customlbl}>
                {" "}
                <span style={{ color: "red", marginRight: "2px" }}>*</span>Listing End Date
              </div>
              <input type="date" name="ListingPeriodEndByDate" style={{ width: "100%" }} placeholder="Enter Listing End Date" required />
              <span className={styles.validationMessage}>Please select the Listing End Date.</span>
            </Col>
          </Row>)
           :
           (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Buyer Agent
                </div>
                <input name="BuyerBrokerName" placeholder="Enter Buyer Agent Name" style={{ width: "100%" }} required />
                <span className={styles.validationMessage}>Please enter the Buyer Agent name.</span>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Buyer Agent Email
                </div>
                <input name="BuyerBrokerEmailAddress" placeholder="Enter Buyer Agent Email" style={{ width: "100%" }} type="email" required />
                <span className={styles.validationMessage}>Please enter only one valid email address.</span>
              </Col>
            </Row>)}


             {/* Purchase price and Earnest Money  */}
             <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>{source !== "ERTS_IMPORT" ? "*" : ""}</span>Purchase Price
                </div>
                <div style={{ position: "relative" }}>
                  <BsCurrencyDollar style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: "10px" }} />
                  <input name="PurchasePrice" placeholder="Enter Purchase Price" className="currency" style={{ paddingLeft: "30px", width: "100%" }} required data-price-format />
                  <span className={styles.validationMessage}>Please enter the Purchase Price.</span>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  <span style={{ color: "red", marginRight: "2px" }}> {source !== "ERTS_IMPORT" ? "*" : ""}</span>Earnest Money
                </div>
                <div style={{ position: "relative" }}>
                  <BsCurrencyDollar style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: "10px" }} />
                  <input name="EarnestMoney" placeholder="Enter Earnest Money" className="currency" style={{ paddingLeft: "30px", width: "100%" }} required data-price-format />
                  <span className={styles.validationMessage}>Please enter the Earnest Money.</span>
                </div>
              </Col>
            </Row>

             {/* New Loan & Cash At Closing  */}
            {(source !== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>New Loan</div>
                <div style={{ position: "relative" }}>
                  <BsCurrencyDollar style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: "10px" }} />
                  <input name="NewLoan" placeholder="Enter New Loan" className="currency" style={{ paddingLeft: "30px", width: "100%" }} data-price-format />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Cash At Closing
                </div>
                <div style={{ position: "relative" }}>
                  <BsCurrencyDollar style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: "10px" }} />
                  <input name="CashAtClosing" placeholder="Enter Cash At Closing" className="currency" style={{ paddingLeft: "30px", width: "100%" }} required data-price-format />
                  <span className={styles.validationMessage} style={{ position: "absolute", bottom: "-20px", left: "0" }}>
                    Please enter the Cash At Closing.
                  </span>
                </div>
              </Col>
            </Row>)}

             {/* Concession & Possetion Penalty  */}
            {(source !== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Concessions</div>
                <div style={{ position: "relative" }}>
                  <BsCurrencyDollar style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: "10px" }} />
                  <input name="SellerConcession" placeholder="Enter Concessions Price" className="currency" style={{ paddingLeft: "30px", width: "100%" }} data-price-format />
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Possession Penalty Per Day</div>
                <div style={{ position: "relative" }}>
                  <BsCurrencyDollar style={{ position: "absolute", top: "50%", transform: "translateY(-50%)", left: "10px" }} />
                  <input name="PosessionSellerPenaltyPerDay" placeholder="Enter Possession Penalty Per Day" className="currency" style={{ paddingLeft: "30px", width: "100%" }} data-price-format />
                </div>
              </Col>
            </Row>)}

             {/* possetion Date & closing date  */}
            {(source !== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Possession Date
                </div>
              
                <input className="relativeDateInput requiredDateInput"  name="PossessionDate" type="text" style={{ width: "100%" }} placeholder="Enter Possession Date" required readOnly />
                <span className={styles.validationMessage}>Please enter the Possession Date.</span>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Closing Date
                </div>
                <input className="relativeDateInput  requiredDateInput closing-date-input"  type="text" name="ClosingDate" style={{ width: "100%" }} placeholder="Enter Closing Date" required readOnly />
                <span className={styles.validationMessage}>Please enter the Closing Date.</span>
                </Col>
            </Row>)}

            {/* Acceptance Date & Acceptance Time  */}
           {(source !== "ERTS_IMPORT") && ( <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>
                  {" "}
                  <span style={{ color: "red", marginRight: "2px" }}>*</span>Acceptance Date
                </div>
                <input className="relativeDateInput requiredDateInput" type="text" name="AcceptanceDeadlineDate" style={{ width: "100%" }} placeholder="Enter Acceptance Date" required readOnly />
                <span className={styles.validationMessage}>Please enter the Acceptance Date.</span>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Acceptance Time</div>
                <input className="relativeDateInput relativeTimeInput" type="text" name="AcceptanceDeadlineTime" style={{ width: "100%" }} placeholder="Enter Acceptance Time" readOnly/>
              </Col>
            </Row>)}

            {/* Inspection Objection Deadline & Inspection Termination Deadline */}
           {(source !== "ERTS_IMPORT") && ( <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Inspection Objection Deadline</div>
                <input className="relativeDateInput" name="InspectionObjectionDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Inspection Objection Deadline" readOnly />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Inspection Termination Deadline</div>
                <input className="relativeDateInput" name="InspectionTerminationDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Inspection Termination Deadline" readOnly />
              </Col>
            </Row>)}

            {/* Loan Termination Deadline & Appraisal Deadline */}
            {(source!== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Loan Termination Deadline</div>
                <input className="relativeDateInput"  name="NewLoanTermsDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Loan Termination Deadline" />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Appraisal Deadline</div>
                <input className="relativeDateInput" name="AppraisalDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Appraisal Deadline" readOnly />
              </Col>
            </Row>)}

            {/* Property Insurance Termination Deadline */}
            {(source !== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Property Insurance Termination Deadline</div>
                <input className="relativeDateInput" name="PropertyInsuranceTerminationDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Property Insurance Termination Deadline" readOnly />
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className={styles.Customlbl}>Due Diligence Documents Delivery Deadline</div>
                <input className="relativeDateInput" name="DueDiligenceDocumentsDeliveryDeadline" type="text" style={{ width: "100%" }} placeholder="Enter Due Diligence Documents Delivery Deadline" readOnly/>
              </Col>
            </Row>)}

            {/* checkBoxes  */}
            {(source !== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Loan Type</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="Conventional" />
                    &nbsp; <span>Conventional</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="FHA" />
                    &nbsp; <span>FHA</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="VA" />
                    &nbsp; <span>VA</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="Bond" />
                    &nbsp; <span>Bond</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" name="LoanLimitations" value="LoanLimitations__Other" data-linked-value="LoanLimitations__Other" />
                    &nbsp; <span>Other</span>
                  </label>
                  <span className={styles.otherInputWrapper}>
                    <input type="text" style={{ width: "100%" }} name="LoanLimitations__Other" data-linked-value="LoanLimitations" />
                  </span>
                </div>
              </Col>
            </Row>)}

            {(source !== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Closing Services Fee Paid By</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="ClosingServicesFeePaidBy" name="ClosingServicesFeePaidBy" value="Buyer" />
                    &nbsp;<span>Buyer</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="ClosingServicesFeePaidBy" name="ClosingServicesFeePaidBy" value="Seller" />
                    &nbsp;<span>Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="ClosingServicesFeePaidBy" name="ClosingServicesFeePaidBy" value="One-Half by Buyer and One-Half by Seller" />
                    &nbsp;<span>One-Half by Buyer and One-Half by Seller</span>
                  </label>
                  <label>
                    <input type="checkbox" data-cb-group="ClosingServicesFeePaidBy" name="ClosingServicesFeePaidBy" data-linked-value="ClosingServicesFeePaidBy__Other" />
                    &nbsp;<span>Other</span>
                  </label>

                  <span className={styles.otherInputWrapper}>
                    <input type="text" name="ClosingServicesFeePaidBy__Other" data-linked-value="ClosingServicesFeePaidBy" style={{ width: "100%" }} />
                  </span>
                </div>
              </Col>
            </Row>)}

            {(source !== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Status Letter Fee Paid By</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationStatusLetterFeePaidBy" name="AssociationStatusLetterFeePaidBy" value="Buyer" />
                    &nbsp;<span>Buyer</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationStatusLetterFeePaidBy" name="AssociationStatusLetterFeePaidBy" value="Seller" />
                    &nbsp;<span>Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationStatusLetterFeePaidBy" name="AssociationStatusLetterFeePaidBy" value="One-Half by Buyer and One-Half by Seller" />
                    &nbsp;<span>One-Half by Buyer and One-Half by Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationStatusLetterFeePaidBy" name="AssociationStatusLetterFeePaidBy" value="N/A" />
                    &nbsp;<span>N/A</span>
                  </label>
                </div>
              </Col>
            </Row>)}

            {(source !== "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Record Change Fee Paid By</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationRecordChangeFeePaidBy" name="AssociationRecordChangeFeePaidBy" value="Buyer" />
                    &nbsp;<span>Buyer</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationRecordChangeFeePaidBy" name="AssociationRecordChangeFeePaidBy" value="Seller" />
                    &nbsp;<span>Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationRecordChangeFeePaidBy" name="AssociationRecordChangeFeePaidBy" value="One-Half by Buyer and One-Half by Seller" />
                    &nbsp;<span>One-Half by Buyer and One-Half by Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="AssociationRecordChangeFeePaidBy" name="AssociationRecordChangeFeePaidBy" value="N/A" />
                    &nbsp;<span>N/A</span>
                  </label>
                </div>
              </Col>
            </Row>)}

           {(source !== "ERTS_IMPORT") && ( <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Water Transfer Fee Paid By</div>
                <div className={styles.cheboxWrapperDiv}>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="WaterTransferFeesPaidBy" name="WaterTransferFeesPaidBy" value="Buyer" />
                    &nbsp;<span>Buyer</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="WaterTransferFeesPaidBy" name="WaterTransferFeesPaidBy" value="Seller" />
                    &nbsp;<span>Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="WaterTransferFeesPaidBy" name="WaterTransferFeesPaidBy" value="One-Half by Buyer and One-Half by Seller" />
                    &nbsp;<span>One-Half by Buyer and One-Half by Seller</span>
                  </label>
                  <label style={{ marginRight: "10px" }}>
                    <input type="checkbox" data-cb-group="WaterTransferFeesPaidBy" name="WaterTransferFeesPaidBy" value="N/A" />
                    &nbsp;<span>N/A</span>
                  </label>
                </div>
              </Col>
            </Row>)}

           {(source !== "ERTS_IMPORT") && ( <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Additional Prov</div>
                <span className="grow-with-content">
                  <textarea name="AdditionalProvisions"></textarea>
                </span>
              </Col>
            </Row>)}

            {/* Other Inclusion  */}
           <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Other Inclusions</div>
                <span className="grow-with-content">
                  <textarea name="OtherInclusions"></textarea>
                </span>
              </Col>
            </Row>

             {/* Exclusions  */}
             <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Exclusions</div>
                <span className="grow-with-content">
                  <textarea name="Exclusions"></textarea>
                </span>
              </Col>
            </Row>

            {/* PosessionDeliveredAsFollows */}
           {(source === "ERTS_IMPORT") && ( <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Posession Delivered As Follows</div>
                <span className="grow-with-content">
                  <textarea name="PosessionDeliveredAsFollows"></textarea>
                </span>
              </Col>
            </Row>)}

            {/* ParkingFacilities */}
           {(source === "ERTS_IMPORT") && ( <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Parking Facilities</div>
                <span className="grow-with-content">
                  <textarea name="ParkingFacilities"></textarea>
                </span>
              </Col>
            </Row>)}

            {/* StorageFacilities */}
            {(source === "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Storage Facilities</div>
                <span className="grow-with-content">
                  <textarea name="StorageFacilities"></textarea>
                </span>
              </Col>
            </Row>)}

            {/* EncumberedInclusions */}
           {(source === "ERTS_IMPORT") && ( <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Encumbered Inclusions</div>
                <span className="grow-with-content">
                  <textarea name="EncumberedInclusions"></textarea>
                </span>
              </Col>
            </Row>)}

            {/* AdditionalProvisions */}
            {(source === "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Additional Provisions</div>
                <span className="grow-with-content">
                  <textarea name="AdditionalProvisions"></textarea>
                </span>
              </Col>
            </Row>)}

             {/* LeasedItems */}
            {(source === "ERTS_IMPORT") && ( <Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Leased Items</div>
                <span className="grow-with-content">
                  <textarea name="LeasedItems"></textarea>
                </span>
              </Col>
            </Row>)}

             {/* legalDescription */}
             {(source === "ERTS_IMPORT") && (<Row gutter={[16, 16]} className={styles.formRow}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className={styles.Customlbl}>Legal Description</div>
                <span className="grow-with-content">
                  <textarea name="legalDescription"></textarea>
                </span>
              </Col>
            </Row>)}

          </div>
        </>
      )}
    </>
  );
};

export default Step2;
