import { transactionLaneConstants } from "./action-types";
import { transactionLaneDataApi } from "../utils/api";
import dotEnv from "dotenv";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { appStatusChange } from "../../App/state/actions";
import { PropertyHandlers } from "../../PropertyComp/state/actions";
import { encryptUrlParams } from "../../Common/utils/extraFunctions";
dotEnv.config();
const adminURL = process.env.REACT_APP_ADMIN_BASE_URL;
/**
 * All Actions for contract Page
 */

/**
 * @param {string}
 */

const getTransactionLaneError = (error) => {
  return {
    type: transactionLaneConstants.GET_TRANSACTION_LANE_FAILURE,
    errorMsg: error?.response?.data?.message || "Failed to fetch data!",
  };
};

const getTranscactionLaneSucess = (response) => {
  return {
    type: transactionLaneConstants.GET_TRANSACTION_LANE_SUCCESS,
    getTransactionLaneData: response.data.info
  };
};

const getTransactionLaneLoading = (responce) => {
  return {
    type: transactionLaneConstants.GET_TRANSACTION_LANE_LOADING
  };
};

const getTransactionLaneMethod = ({offerId, role, unmounted, source, delegateSide, delegateOwnerId}) => {
  return (dispatch, getState) => {
    dispatch(getTransactionLaneLoading(true));
    transactionLaneDataApi
      .transactionLaneApi({offerId, role, source, delegateSide, delegateOwnerId})
      .then((response) => {
        if(!unmounted.value){
          dispatch(getTranscactionLaneSucess(response));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(getTransactionLaneError(error));
          message.error(error?.response?.data?.message || "Failed to fetch transaction lane data!")
        }
      });
  };
};

const getTitleCompanyDataLoading = () => {
  return {
    type: transactionLaneConstants.GET_TITLE_COMPANY_DATA_LOADING,
  };
};
const getTitleCompanyDataError = (error) => {
  return {
    type: transactionLaneConstants.GET_TITLE_COMPANY_DATA_ERROR,
    errorMsg: error?.response?.data?.message || "Error occured while getting titlecompany data!" ,
  };
};
const getTitleCompanyDataSuccess=(res)=>{
 return {
    type:transactionLaneConstants.GET_TITLE_COMPANY_DATA_SUCCESS,
    titleCompanyselecteddata:res.data.info
  }
}
const getTitleCompanyData=({offerId, source, unmounted, delegateSide, delegateOwnerId})=>{
  return(dispatch) => {
    dispatch(getTitleCompanyDataLoading())
    transactionLaneDataApi.getTitleCompanyData({offerId, source, delegateSide, delegateOwnerId})
    .then((response) => {
      if(!unmounted.value){
        dispatch(getTitleCompanyDataSuccess(response))
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        dispatch(getTitleCompanyDataError(error))
        message.error(error?.response?.data?.message || "Error occured while getting title company data!");
      }
    })
  }
}

const getIndividualTransactionError = (error) => {
  return {
    type: transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_FAILURE,
    errorMsg: error?.response?.data?.message || "Failed to fetch individual transaction data!",
  };
};

const triggerIndividualTransactionFetch = (payload) => {
  return {
    type: transactionLaneConstants.TRIGGER_INDIVIDUAL_TRANSACTION_FETCH,
    payload
  };
};

const getIndividualTransactionLoading = () => {
  return {
    type: transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_LOADING,
  };
};
const getIndividualTransactionSuccess = (response) => {
  return {
    type: transactionLaneConstants.GET_INDIVIDUAL_TRANSACTION_SUCCESS,
    getIndividualTransactionData: response.data.info
  };
};

const getIndividualTransaction = ({ offerId, propertyId, setLoading, source, unmounted, delegateSide, delegateOwnerId }) => {
  return (dispatch, getState) => {
    if (setLoading) {
      setLoading(true);
    } else {
      dispatch(getIndividualTransactionLoading());
    }
    transactionLaneDataApi
      .individualTransactionApi({ offerId, propertyId, source, delegateSide, delegateOwnerId })
      .then((response) => {
        if (!unmounted.value) {
          dispatch(getIndividualTransactionSuccess(response));
          if (setLoading) {
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        if (!unmounted.value) {
          dispatch(getIndividualTransactionError(error));
          message.error(error?.response?.data?.message || "Failed to fetch individual transaction data!");
          if (setLoading) {
            setLoading(false);
          }
        }
      });
  };
};

const getPropertyDocsLoading = () => {
  return {
    type: transactionLaneConstants.GET_PROPERTY_DOCS_LOADING,
  };
};
const getPropertyDocsError = (error) => {
  return {
    type: transactionLaneConstants.GET_PROPERTY_DOCS_FAILURE,
    errorMsg: error?.response?.data?.message || "We encountered an issue while getting property docs data. Please try again later!",
  };
};

const getPropertyDocsSuccess = (response) => {

  return {
    type: transactionLaneConstants.GET_PROPERTY_DOCS_SUCCESS,
    getPropertyDocsData: response.data.info,
  };
};


const getPropertyDocs = ({offerId, source, unmounted, delegateSide, delegateOwnerId}) => {
  return (dispatch, getState) => {
    dispatch(getPropertyDocsLoading());
    transactionLaneDataApi
      .getPropertyDocsRequest({offerId, source, delegateSide, delegateOwnerId })
      .then((response) => {
        if(!unmounted.value){
          dispatch(getPropertyDocsSuccess(response));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(getPropertyDocsError(error));
          message.error(error?.response?.data?.message || "Error occured while getting property docs data!");
        }
      });
  };
};

const getDraftDocumentsFailure = (error) => {
  return {
    type: transactionLaneConstants.DRAFT_DOCUMENTS_FAILURE,
    getDraftFailure: error,
    getDraftLoading: false,
  };
};

const getDraftDocumentsSucess = (response) => {
  return {
    type: transactionLaneConstants.DRAFT_DOCUMENTS_SUCCESS,
    getDraftDocumentData: response,
    getDraftLoading: false,
  };
};

const generateUrlIdAndRedirect = ({ buildData, currentPath, source, delegateOwnerId }) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    transactionLaneDataApi
      .draftDocumetnApi(buildData)
      .then((response) => {
        const tabId = uuidv4();
        document.cookie = `${tabId}=${currentPath}; domain=.resure.realestate; path=/;`;
        let encryptedOwnerId;
        if (delegateOwnerId) {
          encryptedOwnerId = encryptUrlParams(delegateOwnerId);
        }
        const refParam = encryptedOwnerId ? `&reqId=${encryptedOwnerId}` : "";
        if (source && source === "Print") {
          window.location.href = `${adminURL}/contractDocument/${response?.data?.info?._id}?tabId=${tabId}${refParam}`;
        } else if (buildData?.builtForSection === "CONTACT_SHEET") {
          window.location.href = `${adminURL}/contactSheet/${response?.data?.info?._id}?tabId=${tabId}${refParam}`;
        } else {
          window.location.href = `${adminURL}/contractDocument/${response?.data?.info?._id}?tabId=${tabId}${refParam}`;
        }
        dispatch(getDraftDocumentsSucess(response.data.info));
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error occured while getting draft document data!");
        dispatch(appStatusChange.loadOpacityLoader(false));
      });
  };
};




const updateDocument = ({documentId, data, client, offerId, contractType, delegateSide, delegateOwnerId, emailMessage, emailSignatureId}) => {
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    transactionLaneDataApi
      .updateDocumentApi({documentId, data: {...data, offerId}, contractType, delegateSide, delegateOwnerId, emailMessage, emailSignatureId})
      .then((response) => {
        dispatch(appStatusChange.loadOpacityLoader(false));
        message.success(response.data.info.message);
        dispatch(updateSuccessRtd());
      })
      .catch((error) => {
        dispatch(appStatusChange.loadOpacityLoader(false));
        dispatch(updateSuccessRtd());
      });
  };
};

const clientPortalError = (error) => {
  return {
    type: transactionLaneConstants.CLIENT_PORTAL_ERROR,
    setError: error,
    setLoading: false,
  };
};

const clientPortalLoading = () => {
  return {
    type: transactionLaneConstants.CLIENT_PORTAL_LOADING,
    setLoading: true,
  };
};
const clientPortalSuccess = (response) => {
  return {
    type: transactionLaneConstants.CLIENT_PORTAL_SUCCESS,
    payload: response.data.info,
  };
};

const ClientPortalData = (query, data) => {
  return (dispatch) => {
    dispatch(clientPortalLoading());
    transactionLaneDataApi
      .getClientScreen(query)
      .then((response) => {
        dispatch(clientPortalSuccess(response));
      })
      .catch((error) => {
        dispatch(clientPortalError(error?.response?.data?.message || "Error occured while getting client portal data!"));
      });
  };
};

const EmailFileDataError = (error) => {
  return {
    type: transactionLaneConstants.EMAIL_FILES_ERROR,
    emailFilesError: error,
    emailFilesLoading: false,
  };
};

const EmailFileDataLoading = () => {
  return {
    type: transactionLaneConstants.EMAIL_FILES_LOADING,
    emailFilesLoading: true,
  };
};
const EmailFileDataSuccess = (response) => {
  return {
    type: transactionLaneConstants.EMAIL_FILES_SUCCESS,
    payload: response.data.info.emails,
  };
};

const EmailFileData = (data) => {
  return (dispatch) => {
    dispatch(EmailFileDataLoading());
    transactionLaneDataApi
      .getEmails_FilesApi(data)
      .then((response) => {
        dispatch(EmailFileDataSuccess(response));
        // let redirectURL = `${apiURL}/contractDocument/${JSON.parse(urlId)}`
        // window.location.assign(encodeURI(redirectURL));
      })
      .catch((error) => {
        dispatch(EmailFileDataError(error?.response?.data?.message || "Error occured while getting email files data!"));
      });
  };
};

const getPropertyDocsFun = (data) => {
  return (dispatch, getState) => {
    dispatch(uploadPropertyDocsLoading());
    transactionLaneDataApi
      .getClientDocRequest(data)
      .then((response) => {
        dispatch(uploadPropertyDocsSuccess(response));
      })
      .catch((error) => {
        dispatch(uploadPropertyDocsError(error));
        message.error(error?.response?.data?.message || "Error occured while uploading property docs!");
      });
  };
};

const uploadPropertyDocsError = (error) => {
  return {
    type: transactionLaneConstants.UPLOAD_DOCUMENT_FAILURE,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const uploadPropertyDocsLoading = (error) => {
  return {
    type: transactionLaneConstants.UPLOAD_DOCUMENT_LOADING,
    uploadPdfError: error,
    uploadPdfLoading: false,
  };
};

const uploadPropertyDocsSuccess = (response) => {
  return {
    type: transactionLaneConstants.UPLOAD_DOCUMENT_SUCCESS,
    propertUploadDocs: response?.data?.info,
    uploadPdfLoading: false,
  };
};

const updateSuccessRtd = () => {
  return {
    type: transactionLaneConstants.UPDATE_SUCCESS_RTD
  };
}

const deleteForm = ({payload, delegateSide, delegateOwnerId}) => {
  let documentId = payload?.documentId;
  let offerId = payload?.selectedData?.offerId;
  let isToggleFrom = payload?.isToggleFrom
  return (dispatch, getState) => {
    dispatch(appStatusChange.loadOpacityLoader(true));
    transactionLaneDataApi.deleteFormRequest({documentId, offerId, delegateSide,delegateOwnerId, isToggleFrom})
    .then((response) => {
      dispatch(appStatusChange.loadOpacityLoader(false));
      message.success(response?.data?.message);
      dispatch(updateSuccessRtd());
    })
    .catch((error) => {
      dispatch(appStatusChange.loadOpacityLoader(false));
      message.error(error?.response?.data?.message || "Error occured while deleting form!");
    });
  }
}

const addEmailLoading = () => {
  return {
    type: transactionLaneConstants.ADD_EMAIL_LOADING
  }
}

const addEmailError = () => {
  return {
    type: transactionLaneConstants.ADD_EMAIL_ERROR
  }
}

const addEmailSuccess = () => {
  return {
    type: transactionLaneConstants.ADD_EMAIL_SUCCESS
  }
}

const addEmail = (data,source,filterSort) => {
  return (dispatch,getState) => {
    dispatch(addEmailLoading())
    transactionLaneDataApi.addEmail(data)
    .then((res) => {
      if(source === "TRANSACTIONS"){
        dispatch(PropertyHandlers.changeFilterSort({...filterSort}));
      }else{
        dispatch(addEmailSuccess())
      }
      message.success("Email added successfully")
    })
    .catch((error) => {
      message.error(error?.response?.data?.message || "Error occured while adding email!");
      dispatch(addEmailError())
    })
  }
}

const getDoraDocumentsLoading = () => {
  return {
    type: transactionLaneConstants.GET_DORA_DOCUMENTS_LOADING,
  };
};
const getDoraDocumentsError = (error) => {
  return {
    type: transactionLaneConstants.GET_DORA_DOCUMENTS_FAILURE,
    errorMsg: error?.response?.data?.message || "We encountered an issue while getting doraDocs data.Please try again later!",
  };
};

const getDoraDocumentsSuccess = (response) => {
  return {
    type: transactionLaneConstants.GET_DORA_DOCUMENTS_SUCCESS,
    getDoraDocumentsData: response
  };
};

const getDoraDocumentsData = ({data, fiters, source, unmounted, brokerageId}) => {
  return (dispatch) => {
    dispatch(getDoraDocumentsLoading());
    transactionLaneDataApi
      .getNestedDoraDocuments({data, fiters, source, brokerageId})
      .then((response) => {
        if(!unmounted.value){
          dispatch(getDoraDocumentsSuccess(response.data.info));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(getDoraDocumentsError(error));
          message.error(error?.response?.data?.message || "Error occured while getting doraDocs data!");
        }
      });
  };
};

const addTitleOfficeLoading = () => {
  return {
    type: transactionLaneConstants.ADD_TITLE_OFFICE_LOADING,
  };
};

const addTitleOfficeError = (error) => {
  return {
    type: transactionLaneConstants.ADD_TITLE_OFFICE_ERROR,
    errorMsg: error?.response?.data?.message || "We encountered an issue while adding titleOffice.Please try again later!",
  };
};

const addTitleOfficeSuccess=()=>{
  return {
    type:transactionLaneConstants.ADD_TITLE_OFFICE_SUCCESS
  }
}

const addTitleOffice=({payload, delegateSide, delegateOwnerId, processorArr, affiliateArr, category, data, processObj, affiliateObj})=>{
  return(dispatch) => {
    dispatch(addTitleOfficeLoading())
    transactionLaneDataApi.addTitleoffice({payload, delegateSide, delegateOwnerId, processorArr, affiliateArr, category})
    .then((response) => {
      dispatch(addTitleOfficeSuccess())
      message.success(category === "deleteProcessor" ? `${data?.name} deleted successfully!` : category === "deleteAffiliate" ? `${data?.name} deleted successfully!` : processorArr?.length ? `${processObj?.name} added successfully!` : affiliateArr?.length ? `${affiliateObj?.name} added successfully!` : "Title Company saved successfully!")
      dispatch(refreshTitleCompany());
    })
    .catch((error) => {
      dispatch(addTitleOfficeError(error))
      message.error(error?.response?.data?.message || "Error occured while adding title office!");
    })
  }
}

const getTitleCompanyLoading = () => {
  return {
    type: transactionLaneConstants.GET_TITLE_COMPANY_LOADING,
  };
};
const getTitleCompanyError = (error) => {
  return {
    type: transactionLaneConstants.GET_TITLE_COMPANY_ERROR,
    errorMsg: error?.response?.data?.message || "We encountered an issue while getting title company data.Please try again later!",
  };
};
const getTitleCompanySuccess = (res)=>{
  return {
    type: transactionLaneConstants.GET_TITLE_COMPANY_SUCCESS,
    titleCompanydata:res.data.info
  }
}
const getTitleCompany = ({type, id, search, unmounted, source})=>{
  return(dispatch) => {
    dispatch(getTitleCompanyLoading())
    transactionLaneDataApi.gettitlecompany({type, id, search, source})
    .then((response) => {
      if(!unmounted.value){
        dispatch(getTitleCompanySuccess(response))
      }
    })
    .catch((error) => {
      if(!unmounted.value){
        dispatch(getTitleCompanyError(error))
        message.error(error?.response?.data?.message || "Error occured while getting title company data!");
      }
    })
  }
}

const sendTitleCompanyDocument=(payload)=>{

  return(dispatch) => {
    transactionLaneDataApi.sendTitleCompanyDocument(payload)
    .then((response) => {
      message.success("Documents Sent Sucessfully!")
    })
    .catch((err) => {
      message.error(err?.response?.data?.message || "Error occured while sending title company document!");
    })
  }
}

const resetRTDData = () => {
  return {
    type: transactionLaneConstants.RESET_RTD_DATA
  };
};

const refreshTitleCompany = () => {
  return {
    type: transactionLaneConstants.REFRESH_TITLE_COMPANY
  };
};

const deleteTitleCompanyData=({offerId, delegateSide, delegateOwnerId, clearAffliates})=>{
  return(dispatch) => {
    transactionLaneDataApi.deleteTitleCompanyData({offerId, delegateSide, delegateOwnerId, clearAffliates})
    .then((response) => {
      message.success(clearAffliates ? "Other data cleared successfully!" : response?.data?.info?.message || "Cleared Successfully!");
      dispatch(refreshTitleCompany());
    })
    .catch((err) => {
      message.error(err?.response?.data?.message || "Error occured while deleting titleCompany data!");
    })
  }
}

const resetTransactionLaneData =()=>{
    return {
        type: transactionLaneConstants.RESET_TRANSACTION_LANE_DATA
      };
}

export const transactionLaneDataHandle = {
  getTransactionLaneMethod,
  generateUrlIdAndRedirect,
  updateDocument,
  getTranscactionLaneSucess,
  ClientPortalData,
  EmailFileData,
  getPropertyDocsFun,
  deleteForm,
  getIndividualTransaction,
  getPropertyDocs,
  getTitleCompanyData,
  addEmail,
  getDoraDocumentsData,
  addTitleOffice,
  getTitleCompany,
  sendTitleCompanyDocument,
  resetRTDData,
  deleteTitleCompanyData,
  resetTransactionLaneData,
  triggerIndividualTransactionFetch
};
