import { toolsConstants } from "./action-types";
import { toolsApi } from "../utils/api";
import { message } from "antd";

// get clausesData
const getClausesLoading = ({pageNumber}) => {
  return {
    type: toolsConstants.GET_CLAUSE_LOADING,
    pageNumber,
  };
};
const getClausesError = (error) => {
  return {
    type: toolsConstants.GET_CLAUSE_FAILURE,
  };
};
const getClausesSuccess = (response) => {
  return {
    type: toolsConstants.GET_CLAUSE_SUCCESS,
    getClauseData: response.data.info,
  };
};
const getClause = ({search, type, sourceAxios, unmounted, pageNumber}) => {
  return (dispatch) => {
    dispatch(getClausesLoading({pageNumber}));
    toolsApi
      .getClausesRequest({search, type, sourceAxios, pageNumber})
      .then((response) => {
        if(!unmounted?.value) {
          dispatch(getClausesSuccess(response));
        }
      })
      .catch((error) => {
        if(!unmounted?.value) {
          dispatch(getClausesError(error));
        }
      });
  };
};

// get DocumentsName List
const getDocumentsLoading = ({pageNumber}) => {
  return {
    type: toolsConstants.GET_DOCUMENTS_LOADING,
    pageNumber,
  };
};
const getDocumentsError = (error) => {
  return {
    type: toolsConstants.GET_DOCUMENTS_FAILURE,
    templateJSXError: error,
  };
};
const getDocumentsSuccess = (response) => {
  return {
    type: toolsConstants.GET_DOCUMENTS_SUCCESS,
    getDocsData: response.data.info,
  };
};
const getDocuments = ({search,type, sourceAxios, unmounted, pageNumber}) => {
  return (dispatch) => {
    dispatch(getDocumentsLoading({pageNumber}));
    toolsApi
      .getDocumentsRequest({search,type, sourceAxios, pageNumber})
      .then((response) => {
        if (!unmounted?.value) {
          dispatch(getDocumentsSuccess(response));
        }
      })
      .catch((error) => {
        if (!unmounted?.value) {
          dispatch(getDocumentsError(error));
        }
      });
  };
};
// get emailtemplate List
const get_Email_Template_Loading = () => {
  return {
    type: toolsConstants.GET_EMAIL_TEMPLATE_LOADING,
  };
};
const get_Email_Template_Error = (error) => {
  return {
    type: toolsConstants.GET_EMAIL_TEMPLATE_FAILURE,
    errorMsg: error?.response?.data?.message,
  };
};
const get_Email_Template_Success = (response) => {
  return {
    type: toolsConstants.GET_EMAIL_TEMPLATE_SUCCESS,
    getEmail_Templatedata: response.data.info,
  };
};

const getEmail_Template = ({source, unmounted}) => {
  return (dispatch) => {
    dispatch(get_Email_Template_Loading());
    toolsApi
      .getemailtemplateRequest({source})
      .then((response) => {
        if(!unmounted.value){
          dispatch(get_Email_Template_Success(response));
        }
      })
      .catch((error) => {
        if(!unmounted.value){
          dispatch(get_Email_Template_Error(error));
          message.error(error?.response?.data?.message || "Error occured while getting email template!");
        }
      });
  };
};

// adding documents name , clauses and email template
const addDocumentsLoading = () => {
  return {
    type: toolsConstants.ADD_DOCUMENTS_LOADING,
  };
};
const addDocumentsError = (error) => {
  return {
    type: toolsConstants.ADD_DOCUMENTS_FAILURE,
  };
};
const addDocumentsSuccess = (response) => {
  return {
    type: toolsConstants.ADD_DOCUMENTS_SUCCESS,
    addClausesData: response.data.info,
  };
};
const addDocAndClause = (data) => {
  return (dispatch) => {
    dispatch(addDocumentsLoading());
    toolsApi
      .addDocAndClauseRequest(data)
      .then((response) => {
        dispatch(updateSuccess(response));
        if (data.type === "DOCUMENT") {
          dispatch(getDocuments({pageNumber: 1}));
        } else if(data.type === "CLAUSE"){
          dispatch(getClause({pageNumber: 1}));
        }
        message.success("Added Successfully!");
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Error occurred while adding doc/clause data!");
        dispatch(addDocumentsError(error));
      });
  };
};
// update Documets and clauses
const updateDocsClauseLoading = () => {
  return {
    type: toolsConstants.UPDATE_DOCUMENTS_LOADING,
  };
};
const updateDocsClauseError = (error) => {
  return {
    type: toolsConstants.UPDATE_DOCUMENTS_FAILURE,
  };
};

const updateDocsClauseSuccess = (response) => {
  return {
    type: toolsConstants.UPDATE_DOCUMENTS_SUCCESS,
    addClausesData: response.data.info,
  };
};

const updateSuccess = (response) => {
  return {
    type: toolsConstants.UPDATE_SUCCESS
  };
};

const updateDocsClause = (data, type, setTriggerAPI) => {
  return (dispatch, getState) => {
    dispatch(updateDocsClauseLoading());
    toolsApi
      .updateDocsClauseRequest(data)
      .then((response) => {
        dispatch(updateSuccess(response));

        if (type === "DOCUMENT") {
          setTriggerAPI(true);
        } else if (type === "CLAUSE") {
          setTriggerAPI(true);
        }
        message.success("Updated Successfully!");
      })
      .catch((error) => {
        dispatch(updateDocsClauseError(error));
      });
  };
};
// delete Documets and clauses
const deleteDocsClauseLoading = () => {
  return {
    type: toolsConstants.DELETE_DOCUMENTS_LOADING,
  };
};
const deleteDocsClauseError = (error) => {
  return {
    type: toolsConstants.DELETE_DOCUMENTS_FAILURE,
  };
};
const deleteDocsClauseSuccess = (response) => {
  return {
    type: toolsConstants.DELETE_DOCUMENTS_SUCCESS,
    addClausesData: response.data.info,
  };
};

const deleteDocsClause = (id, type, setTriggerAPI) => {
  return (dispatch) => {
    dispatch(deleteDocsClauseLoading());
    toolsApi
      .deleteDocsClauseRequest(id)
      .then((response) => {
        dispatch(updateSuccess(response));
        if (type === "DOCUMENT") {
          setTriggerAPI(true);
        } else if (type === "CLAUSE") {
          setTriggerAPI(true);
        }
        message.success("Deleted Successfully!");
      })
      .catch((error) => {
        dispatch(deleteDocsClauseError(error));
        message.error(error?.response?.data?.message || "Error occurred while deleting docs and clause data!");
      });
  };
};

const templateJSXError = (error) => {
  return {
    type: toolsConstants.GET_TEMPLATE_JSX_ERROR,
    templateJSXError: error,
    templateJSXLoading: false,
  };
};

const templateJSXSuccess = (response) => {
  return {
    type: toolsConstants.GET_TEMPLATE_JSX_SUCCESS,
    templateJSXData: response,
    templateJSXLoading: false,
  };
};

const gettemplateJSX = (id) => {
  return (dispatch, getState) => {
    toolsApi
      .templateJSXRequest(id)
      .then((response) => {
        dispatch(templateJSXSuccess(response.data.info.contractJSX));
      })
      .catch((error) => {
        dispatch(templateJSXError(error));
      });
  };
};
const getEmailDripConstantLoading = () => {
  return {
    type: toolsConstants.GET_REQUEST_FOR_EMAIL_DRIP_LOADING,
    getemailDripConstantLoading:true
  };
};
const getEmailDripConstantError = (error) => {
  return {
    type: toolsConstants.GET_REQUEST_FOR_EMAIL_DRIP_FAILURE,
    getemailDripConstanterror: error,
  };
};

const getEmailDripConstantSuccess = (response) => {
  return {
    type: toolsConstants.GET_REQUEST_FOR_EMAIL_DRIP_SUCCESS,
    getemailDripConstantData: response.data.info,
    getemailDripConstantLoading: false,
  };
};
const getEmailDripConstantSuccessRealtor = (response) => {
  return {
    type: toolsConstants.GET_REQUEST_FOR_EMAIL_DRIP_REALTOR_SUCCESS,
    getemailDripConstantRealtorData: response.data.info,
    getemailDripConstantLoading: false,
  };
};
const getEmailDripConstant = () => {
  return (dispatch) => {
    dispatch(getEmailDripConstantLoading());
    toolsApi
      .emailDripCampaign()
      .then((response) => {
        dispatch(getEmailDripConstantSuccess(response));
      })
      .catch((error) => {
       dispatch(getEmailDripConstantError(error));
       })

  };
};

const getEmailDripRealtor = () => {
return (dispatch) => {
dispatch(getEmailDripConstantLoading());
    toolsApi
      .emailDripCampaignrealtor()
      .then((response) => {
     dispatch(getEmailDripConstantSuccessRealtor(response));
      })
      .catch((error) => {
       dispatch(getEmailDripConstantError(error));
       })

  };
};
const updateEmailDripCampaign=(id,data)=>{

  return (dispatch) => {

    toolsApi
      .updateEmailDripCampaign(id,data)
      .then((response) => {
        // // dispatch(getEmailDripConstantSuccessRealtor(response));
        dispatch(getEmailDripRealtor())
        if(data.message)
        {
        message.success("Email message updated!")
        }
      })
      .catch((error) => {
       dispatch(getEmailDripConstantError(error));
       message.error(error?.response?.data?.message || "Error occured while updating email drip campaign!");
       })
      }
}
const postEmailDripCampaign=(data)=>{

  return (dispatch) => {

    toolsApi
      .postEmailDripEvent(data)
      .then((response) => {
        dispatch(getEmailDripConstantSuccess(response));

      })
      .catch((error) => {
       dispatch(getEmailDripConstantError(error));
       })
      }
}
const emailBroadCaste=(subject,message2,email)=>{
  return (dispatch) => {
   toolsApi
      .postEmailBroadCaste(subject,message2,email)
      .then((response) => {
    message.success("sent Successfully!");
    })
      .catch((error) => {
        message.error(error?.response?.data?.message || "We encountered an issue while doing an emailbroadcast. Please try again later!");
      });
  };
}

// Send mail for clauses
const sendMailForClausesLoading = () => {
    return {
      type: toolsConstants.SEND_MAIL_FOR_CLAUSES_LOADING,
    };
  };
  const sendMailForClausesError = (error) => {
    return {
      type: toolsConstants.SEND_MAIL_FOR_CLAUSES_FAILURE,
    };
  };

  const sendMailForClausesSuccess = (response,setVisble) => {
    message.success(response?.data?.info);
    setVisble(false);
    return {
      type: toolsConstants.SEND_MAIL_FOR_CLAUSES_SUCCESS,
      addClausesData: response.data.info,
    };
  };

  const sendMailForClauses = (setVisble,setIssendEmailModal,source,templateEssentials) => {
    return (dispatch, getState) => {
      dispatch(sendMailForClausesLoading());
      toolsApi
        .sendMailForClausesRequest(source,templateEssentials)
        .then((response) => {
          dispatch(sendMailForClausesSuccess(response,setVisble));
          setIssendEmailModal({visible:false,source:""});
        })
        .catch((error) => {
          dispatch(sendMailForClausesError(error));
        });
    };
  };

  // get access for FJGG Doc & clauses
const getFJGGAccessLoading = () => {
    return {
      type: toolsConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_LAODING,
    };
  };
  const getFJGGAccessError = (error) => {
    return {
      type: toolsConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_ERROR,
    };
  };

  const getFJGGAccessSuccess = (response) => {
    return {
      type: toolsConstants.GET_ACCESS_TO_FJGG_DOCANDCLAUSES_SUCCESS,
      FJGGAccessData: response?.data?.info?.[0],
    };
  };

  const getFJGGAccess = () => {
    return (dispatch, getState) => {
      dispatch(getFJGGAccessLoading());
      toolsApi
        .getFJGGAccessRequest()
        .then((response) => {
          dispatch(getFJGGAccessSuccess(response));
        })
        .catch((error) => {
          dispatch(getFJGGAccessError(error));
        });
    };
  };

  const getResureSignSSOLink = () => {
    return toolsApi.getResureSignSSOLink().then((response) => {
      // console.log(response);
      if (response.status === 200 && response.data.statusCode === 200) {
        window.open(response.data.info.redirect_url);
      } else {
        alert("Unable to open RE-Sure Sign");
      }
    });
  };


export const toolsAction = {
  gettemplateJSX,
  getClause,
  getDocuments,
  addDocAndClause,
  updateDocsClause,
  deleteDocsClause,
  getEmail_Template,
  emailBroadCaste,
  postEmailDripCampaign,
  updateEmailDripCampaign,
  getEmailDripRealtor,
  getEmailDripConstant,
  sendMailForClauses,
  getFJGGAccess,
  getResureSignSSOLink,
};
