import React from "react";
import { Layout } from "antd";
import { Listing } from "./components/Listing";
import {  useSelector } from "react-redux";
import { Suggestions } from "./components/Suggestions";
import styles from "./styles/TestingRoute.module.css"
import { Support } from "./components/Support";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bugReportHandle } from "./state/actions";
import { getValuesFromUrl } from "../Common/utils/extraFunctions";

const { Content } = Layout;


function BugReportHome() {
    const { renderComp } = useSelector((state) => state.bugReport);
    const isMobile = useMediaQuery({ maxWidth: 985 });
    const dispatch = useDispatch();

    const param = new URLSearchParams(window.location.search);
    const idValue = getValuesFromUrl(param.get("id"));
    const type = param.get("type");

    useEffect(() => {
      if(type?.length){
        if(type === "BUGS"){
          dispatch(bugReportHandle.renderComponant("listings"))
        }else if(type === "SUGGESTIONS"){
          dispatch(bugReportHandle.renderComponant("suggestions"))
        }else{
          dispatch(bugReportHandle.renderComponant("support"))
        }
      }
    },[type])

  return (
    <Layout style={{ height: "100%", padding: "0", }}>
      <Content
        style={{
          width:"100%",
          marginTop:"20px",
          // marginBottom:'20px',
          //   boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          padding: "1rem",
          height:'85vh'
        }}
      >
        <div
          className={styles.contentWrapper}
          style={{
            display: "flex",
            justifyContent: "center",
            margin:isMobile ? "" : "0 -20px 0 -20px"
          }}
        >
        {renderComp === "listings" ?
          type?.length ? type === "BUGS" && <Listing idValue={idValue}/> : <Listing/> : renderComp === "suggestions" ? <Suggestions idValue={idValue}/> : <Support idValue={idValue}/>}
        </div>
      </Content>
    </Layout>
  );
}

export { BugReportHome };
