import React, { useEffect, useState,useRef } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button } from "antd";
import { clientAction } from "../state/actions";
import { Loading } from "../../Common/components/Loading";

function AddressDetails({ data, setModelDrawer, selectedClient }) {
  const dispatch = useDispatch();
  const [dataLoader, h] = useState(true);
  const inputRef = useRef()
  const [formData, setFormData] = useState({
    // streetNumber: data?.streetNumber || "",
    // streetName: data?.streetName || "",
    // unit: data?.unit || "",
    addressLine1: data?.addressLine1 || ((`${data?.streetNumber || ''} ${data?.unit ? ((data?.streetName || '') + ", Unit: " + data.unit + ",") : (data?.streetName || '') }`)?.trim()) || '',
    addressLine2: data?.addressLine2 || '',
    city: data?.city || "",
    state: data?.state || "CO",
    zipCode: data?.zipCode || "",
  });
  const [form] = Form.useForm();
  
  useEffect(() => {
    setTimeout(() => {
      h(false);
    }, 100);
    return () => {};
  }, []);
  // const { updateAddressDetails } = useSelector((state) => state.client);

  // if (updateAddressDetails !== null) {
  //   dispatch(clientAction.reSetAddress());
  //   setModelDrawer({ status: false, component: "", title: "" });
  // }

  const onFinish = (values) => {
    dispatch(clientAction.updateAddress(values, data, selectedClient));
    setModelDrawer({ status: false, component: "", title: "" });
    setFormData({
      // streetNumber: "",
      // streetName: "",
      // unit: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "CO",
      zipCode: "",
    });
  };

  // useEffect(() => {
  //   if (inputRef.current) {
  //     inputRef.current.focus();
  //   }
  //   form.setFieldsValue({
  //     address:data?.join("\n")
  //   })
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return (
    <>
      {dataLoader ? (
        <Loading />
      ) : (
        <Form
          labelCol={{ span: 8}}
          wrapperCol={{ span: 16 }}
          initialValues={{
            // streetNumber: data?.streetNumber || '',
            // streetName: data?.streetName || '',
            // unit: data?.unit ? data.unit : "",
            // unit: data?.unit ? data.unit : "",
            addressLine1: data?.addressLine1 || ((`${data?.streetNumber || ''} ${data?.unit ? ((data?.streetName || '') + ", Unit: " + data.unit + ",") : (data?.streetName || '') }`)?.trim()) || '',
            addressLine2: data?.addressLine2 || '',
            city: data?.city,
            state: data?.state || "CO",
            zipCode: data?.zipCode,
            // address: data?.join(",") || "",
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Address Line 1"
            name="addressLine1"
            rules={[{ required: true, message: "Please Enter Street No./Name/Unit" }]}
          >
            <Input
              ref={inputRef}
              placeholder="Enter Address line 1"
              allowClear
              autoFocus
              name="addressLine1"
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item
            label="Address Line 2"
            name="addressLine2"
            labelCol={{span: 8, style: {paddingLeft: 10}}}
            // rules={[{ required: true, message: "Please Enter Street Name!" }]}
          >
            <Input
              placeholder="Enter Address Line 2"
              allowClear
              name="addressLine2"
              onChange={handleInputChange}
            />
          </Form.Item>
          {/* <Form.Item
            label="Unit"
            name="unit"
            labelCol={{span: 8, style: {paddingLeft: 10}}}
          >
            <Input
              placeholder="Enter Unit"
              allowClear
            />
          </Form.Item> */}
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please Enter City Name!" }]}
          >
            <Input
              placeholder="Enter City"
              allowClear
              name="city"
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: "Please Enter State Name!" }]}
          >
            <Input
              defaultValue='CO'
              placeholder="Enter State"
              allowClear
              name="state"
              onChange={handleInputChange}
            />
          </Form.Item>

          <Form.Item
            label="Zip Code"
            name="zipCode"
            hasFeedback
            rules={[
              { required: true, message: "Please Enter the Zip Code!" },
              { whitespace: true },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value?.length > 4 && value?.length < 7) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("Please Enter valid Zip Code!")
                  );
                },
              }),
            ]}
          >
            <Input
              type="number"
              maxLength={"6"}
              placeholder="Enter Zip Code"
              allowClear
              name="zipCode"
              onChange={handleInputChange}
            />
          </Form.Item>
          {/* <Form.Item
          label="Address"
          name="address"
          rules={[{ required: true, message: "Please Enter Address!" }]}
          >
            <textarea cols="40" rows="5" placeholder="Enter Address" style={{padding:"0 5px"}} onChange={() => setFormData(true)}></textarea>
          </Form.Item> */}
          <Form.Item wrapperCol={{ offset: 12, span: 12 }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={ 
                !formData.addressLine1 ||
                !formData.city ||
                !formData.state ||
                !formData.zipCode
              }
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
export { AddressDetails };
