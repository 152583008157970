import { Avatar, Button, List, message } from "antd";
import React, { useCallback, useEffect } from "react";
import { UserOutlined, DeleteTwoTone } from "@ant-design/icons";
import { documentAction } from "../state/actions";
import { useDispatch } from "react-redux";
import _ from "lodash";
function EachCorpSigner({ item, ele, initials, isMobile, clientsArray, handleDelete, handleDataChange, source }) {

  const dispatch = useDispatch();
  
  useEffect(() => {
    const corpData = clientsArray?.filter((el) => el?.isCorp);
    corpData?.forEach((corp) => {
      corp?.signers?.forEach((signer) => {
        if (corp?.key && !signer?.personId && signer?.key) {
          // manual signer which don't have personId
          let targetInput = document.getElementsByName(`title_${corp?.key}_${signer?.key}`)[0];
          if (targetInput) targetInput.value = signer?.title || "";
        } else {
          let targetInput = document.getElementsByName(`title_${corp?.key}_${signer?.personId}`)[0];
          if (targetInput) targetInput.value = signer?.title || "";
        }
      });
    });
  }, [clientsArray]);

  
  const handleKeyPressOnTitle = (e, obj) => {
    if (e.key === "Enter") {
      handleTileSave(e.target.value, obj);
    }
  };

  const handleTileSave = useCallback(
    _.debounce((value, obj) => {
      if (Array.isArray(clientsArray)) {
        const corpData = clientsArray.filter((el) => el.isCorp && el.key === obj?.corpKey)[0];
        let updatedCorpData = { ...(corpData ? corpData : {}) };
        let personIndex = -1;

        if (!obj?.personId && obj?.manualSignerKey) {
          // Manual signer which doesn't have personId
          personIndex = corpData?.signers?.findIndex((person) => person?.key === obj?.manualSignerKey);
        } else if (obj?.personId) {
          personIndex = corpData?.signers?.findIndex((person) => person?.personId === obj?.personId);
        }

        if (personIndex !== -1) {
          updatedCorpData.signers[personIndex][`title`] = value;
          clientsArray = clientsArray?.map((data) => {
            if (data?.isCorp && data?.key === obj?.corpKey) {
              return updatedCorpData;
            } else {
              return data;
            }
          });
        }

        if (source === "SIGNER_MODAL") {
          dispatch(documentAction.setCorpState({ clientsArray }));
        } else {
          if (handleDataChange) {
            handleDataChange({ Buyers: clientsArray });
          }
        }
        // message.success("Title Added Successfully!");
      }
    }, 500),
    [clientsArray, dispatch, handleDataChange, source] // Dependencies for the debounced function
  );

  return (
    <div>
      <List.Item
        style={{
          margin: isMobile ? "-5px 20px" : "-10px 20px",
          width: isMobile ? "93%" : "99%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <List.Item.Meta
          avatar={
            initials ? (
              <Avatar size={isMobile ? 35 : 45} style={{ background: "#085191", marginTop: isMobile ? "5px" : "3px" }}>
                {initials}
              </Avatar>
            ) : (
              <Avatar size={isMobile ? 35 : 45} icon={<UserOutlined />} style={{ background: "#085191", marginTop: isMobile ? "5px" : "3px" }} />
            )
          }
          title={
            <div style={{ display: "flex", alignItems: "center", width: "90%" }}>
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    fontSize: isMobile ? "13px" : "15px",
                    // width:"60%",
                    verticalAlign: "middle",

                    // lineHeight: "45px",
                    whiteSpace: "nowrap",
                    // maxWidth: isMobile ? "160px" : "auto",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "capitalize",
                  }}
                >
                  {item?.fullName || ""}
                </div>
                <div style={{ fontSize: isMobile ? "10px" : "12px", display: "flex", gap: "5px", alignItems: "center", justifyContent: "space-between" }}>
                  <input
                    placeholder="Enter Title"
                    onKeyPress={(e) => handleKeyPressOnTitle(e, { corpKey: ele?.key, personId: item?.personId, manualSignerKey: item?.key })}
                    name={ele?.key && item?.personId ? `title_${ele?.key}_${item?.personId}` : ele?.key && !item?.personId && item?.key ? `title_${ele?.key}_${item?.key}` : ""}
                    style={{ border: "1px solid silver", minWidth: isMobile ? "50px" : "70%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                    onChange={(e) =>
                      handleTileSave(e.target.value, {
                        corpKey: ele?.key,
                        personId: item?.personId,
                        manualSignerKey: item?.key,
                      })
                    }
                  />
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "10%" }}>
                    <span onClick={() => handleDelete(ele, "SIGNERS", item)} style={{ cursor: "pointer", marginRight: source === "SIGNER_MODAL" ? "25px" : "40px" }}>
                      <DeleteTwoTone style={{ fontSize: "medium" }} />
                    </span>
                  </div>
                  
                </div>
              </div>
            </div>
          }
        />
      </List.Item>
    </div>
  );
}

export default EachCorpSigner;
