import { Button, Col, Input, Layout,  Modal,  Row, Select, Tabs, Tooltip, } from "antd";
import { PlusCircleFilled, SearchOutlined, FilterFilled, FilterOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DocumentsList from "./DocumentsList";
import ClausesList from "./ClausesList";

import {
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { DocsClauseModal } from "./DocsClauseModal";
import { toolsAction } from "../state/actions";
import axios from "axios";

const { Content } = Layout;
const { TabPane } = Tabs;

export const DocsClause = () => {
  return (
    <Layout style={{ width: "100%", height: "100%", padding: "0px" }}>
      <Content>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingLeft: "6px", paddingRight: "6px" }}>
            <TabCommon />
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

function TabCommon() {
  const [visible, setVisble] = useState(false);
  const [handleFilterModal, setHandleFilterModal] = useState(false);
  // const [filterOptions, setFilterOptions] = useState([]);
  // const [searchText, setSearchText] = useState('');
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { Option } = Select;
  const pathname = location.pathname.split("/").pop();
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(1);
  const [triggerAPI, setTriggerAPI] = useState(false);
  
    let unmounted = { value: false };
    let sourceAxios = axios.CancelToken.source();
  
    // Unified API call for docs and clauses
    useEffect(() => {
      if (pathname === 'clauses') {
        dispatch(toolsAction.getClause({
          sourceAxios,
          unmounted,
          pageNumber: page,
          search: searchText,
          type: filterOptions,
        }));
      } else if (pathname === 'documents') {
        dispatch(toolsAction.getDocuments({
          sourceAxios,
          unmounted,
          pageNumber: page,
          search: searchText,
          type: filterOptions,
        }));
      }
      setTriggerAPI(false);
  
      return () => {
        unmounted.value = true;
        sourceAxios.cancel('Request canceled due to component unmounting');
      };
    }, [dispatch, location.pathname, page, triggerAPI]);

  const handleSearch = () => {
    setPage(1);
    setHandleFilterModal(false);
    setTriggerAPI(true);
  };

  const handleReset = () => {
    setPage(1);
    setSearchText('');
    setFilterOptions([]);
    setHandleFilterModal(false);
    setTriggerAPI(true);

  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
    if (!e.target.value) {
      setTriggerAPI(true);
    }
  }

  // created this function for showing the exact routes when key changes
  const handleChange = (key) => {
    if (key === "2") {
      history.push("/tools/docsClause/documents");
    } else if (key === "1") {
      history.push("/tools/docsClause/clauses");
    }
    setPage(1);
    setSearchText('');
    setFilterOptions([]);
  };


  return (
    <div
      style={{
        border: "1px solid  rgba(0,0,0,0.2)",
        width: "100%",
        borderRadius: "8px",
        backgroundColor: "#fff",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        height: "calc(100vh - 7.5rem)",
        msOverflowY: "auto",
      }}
    >
    <Modal
    visible={handleFilterModal}
    onCancel={()=>setHandleFilterModal(false)}
    footer={false}
    title={<span style={{display:"flex",justifyContent:"center",color:"grey",fontWeight:"bold"}}>
    FILTER {pathname==='clauses' ? "CLAUSES" : "DOCUMENTS"}
    </span>}
    >
    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
     <Select value={filterOptions} onChange={(val)=>setFilterOptions([...val])} style={{width:"300px"}} placeholder='Select Options' mode="multiple">
        <Option value='CUSTOM'>CUSTOM</Option>
        <Option value='FJGG'>FJGG</Option>
        <Option value='SHARED'>SHARED</Option>
     </Select>
     <div style={{margin:"10px auto",}}>
        <Button onClick={handleSearch} size="small" type="primary" style={{width:"100px"}}>Filter</Button>
        <Button onClick={handleReset} size="small"  style={{width:"100px", marginLeft:"10px"}}>Clear Filter</Button>
     </div>
     </div>
    </Modal>
      <DocsClauseModal
        visible={visible}
        setVisble={setVisble}
        pathname={pathname}

      />
       <div style={{
        position:"absolute",
        top:"20",
        left:"40%",
        zIndex:"111",
        padding:"10px",
        width:"40%",
        display:"flex",
        justifyContent:"space-around"
      }}>
        <div style={{display: "flex", gap: 10}}>
          <Input onChange={handleSearchChange} value={searchText?.trim() || ''} onPressEnter={handleSearch} placeholder="Search by description..." allowClear enterButton suffix={<SearchOutlined />}/>
          <Button disabled={!searchText?.length} onClick={handleSearch} type='primary'>Search</Button>
        </div>
        <Tooltip trigger={['hover']} title="Filter By Types">
          <span onClick={() => setHandleFilterModal(true)}
            style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', color: "#085191", fontSize: "20px", margin: "5px auto auto 10px" }}
          >
            {!filterOptions?.length ? <FilterOutlined /> : <FilterFilled />}
          </span>
        </Tooltip>
      </div>
      <Tabs
        defaultActiveKey="1"
        activeKey={pathname === 'clauses' ? '1' : '2'}
        onChange={handleChange}
        tabBarExtraContent={
          <Button
            shape="circle"
            style={{
              marginLeft: "-5rem",
              paddingTop: "0px",
            }}
            icon={
              <PlusCircleFilled
                style={{
                  fontSize: "30px",
                  color: "#085191",
                }}
                onClick={() => setVisble(true)}
              />
            }
          ></Button>
        }
      >
         <TabPane
          tab={
            <span
              className="mozFontSize"
              style={{
                fontSize: "1.2rem",
                paddingLeft: "1rem",
              }}
            >
              Clauses
            </span>
          }
          key="1"
        >
          <ClausesList searchText={searchText} setPage={setPage} page={page} setTriggerAPI={setTriggerAPI} />
        </TabPane>
        <TabPane
          tab={
            <span
              className="mozFontSize"
              style={{
                fontSize: "1.2rem",
                paddingLeft: "1rem",
              }}
            >
              DOCUMENTS
            </span>
          }
          key="2"
        >
          <DocumentsList searchText={searchText} setPage={setPage} page={page} setTriggerAPI={setTriggerAPI} />
        </TabPane>
      </Tabs>

    </div>
  );
}
