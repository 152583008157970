import React, { useEffect, useState ,useCallback} from "react";
import { List, Typography, Drawer, Badge,  Button, Spin } from "antd";
import { BellOutlined} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import EachNotificialtion from "./EachNotificialtion";
import { appStatusChange } from "../state/actions";
import { useMediaQuery } from "react-responsive";
import debounce from "lodash/debounce";
const { Title } = Typography;


const NotificationDrawer = () => {
  const dispatch=useDispatch()
  const [visible, setVisible] = useState(false);
  const [read,setRead]=useState(false)
  const [page,setPage]=useState(1)
  const isSmallScreen = useMediaQuery({maxWidth:480});

  const {getNotificationLoading, totalrecords,getNotificationCountData,paginationdata } = useSelector(
    (state) => state.app
  );
 
const [notiCount, setNotiCount] = useState(0);
const [actualNotiCount, setAcutalNotiCount] = useState();
 useEffect(() => {
if(page>1)
{
    dispatch(appStatusChange.getNotificationtData(page));
}}, [page,dispatch])
  // const dispatch = useDispatch();
  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
 
  useEffect(() => {
    setNotiCount(getNotificationCountData?.totalCount);
    setAcutalNotiCount(getNotificationCountData?.totalCount)
  }, [getNotificationCountData]);
  
  const handlescroll = useCallback(
    debounce((e) => {
      const element = e.target;
     if (element.scrollHeight - element.scrollTop <= window.innerHeight + 250) {
        const pages = Math.ceil(totalrecords / 20);
     if (page + 1 <= pages) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    }, 300),
    [totalrecords, page]
  );
   const handleMarkAllRead = () => {
    const firstNotificationId = paginationdata?.[0]?._id;
    if (firstNotificationId) {
      dispatch(appStatusChange.updateNotificationFun({ _id: firstNotificationId, markAllRead: true }));
      setRead(true);
      setNotiCount(0);
      setAcutalNotiCount(0);
    }
  };

  return (
    <>
      {totalrecords>= 0 ? (
        <Badge count={actualNotiCount}>
          <BellOutlined
            style={{
              fontSize: "30px",
              marginRight: "30px",
              borderRadius: "50px",
              color: "white",
              marginLeft: "5px"
            }}
            onClick={showDrawer}
          />
        </Badge>
      ) : (
        <BellOutlined
          style={{ fontSize: "30px", marginRight: "30px" }}
          onClick={showDrawer}
        />
      )}

      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ padding: "0", overflow:"hidden" }}
        title={<Title style={{fontSize: isSmallScreen ? "15px" : "20px", fontFamily:"sans-serif",color: "#178DFA", textAlign:"center", marginTop:'10px' }}>NOTIFICATIONS</Title> }
      >
        <div style={{textAlign:"right",padding:"10px"}}>
              {actualNotiCount > 0 && <Button onClick={handleMarkAllRead} >
                Mark all as read
              </Button>}
        </div>
        <div
        style={{overflow:"auto", height:"95%", }}
        onScroll={
        handlescroll
        }>
        <List
          className="infinite-container"
          //   loading={getClientLoading}
          itemLayout="horizontal"
          scroll="auto"
         
          dataSource={paginationdata}
          style={{ width: "100%", padding: "0" }}
          renderItem={(item) => (
            <EachNotificialtion
              setVisible={setVisible}
              item={item}
              read={read}
              notiCount={notiCount}
              actualNotiCount={actualNotiCount}
              setAcutalNotiCount={setAcutalNotiCount}
              setNotiCount={setNotiCount}
              setPage={setPage}
              isSmallScreen={isSmallScreen}
            />
          )}
        />
         {getNotificationLoading?<div style={{textAlign:"center",width:"100%"}}> <Spin/>  </div>:""}
        </div>
   
      </Drawer>
     
    </>
  );
};

export default NotificationDrawer;
