import React, { useState } from "react";
import { Upload, message, Modal, Image, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { clientAction } from "../state/actions";
import styles from "../styles/PhotoComp.module.css";

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === "image/webp";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/JPEG/WEBP file!");
  }
  const isLt5M = file.size / 1024 / 1024 < 20;
  if (!isLt5M) {
    message.error("Image must smaller than 20MB!");
  }
  return isJpgOrPng && isLt5M;
}

function AvatarImage(id) {
  const dispatch = useDispatch();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [visible, setVisible] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [spinning, setSpinning] = useState(false);


  let handleCustome = (event) => {
    setVisible(true);
    setFileData(event.file);
    setVisible(true);
    // Setting name to show the name of image
    setLoading(true);
    setSpinning(true);
    // Preview the image before uploading to our server
    const file = event.file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");
      setPreviewUrl(base64String);
    };
  };
  const uploadButton = (
    <div>
      <PlusOutlined style={{ color: "#085191" }} />
    </div>
  );

  const HandleCancel = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleImageUpload = () => {
    dispatch(clientAction.postImage({fileData, id}));
    setVisible(false);
  };

  return (
    <>
      <div className={styles.uploadButtonbigscreen}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={handleCustome}
          beforeUpload={beforeUpload}
          style={{ width: "900px" }}
        >
          {uploadButton}
        </Upload>
      </div>

      {/* Modal opens when someone select an image for preview*/}
      <Modal visible={visible} onCancel={handleCancel} footer={[]}>
        {/* Show only when New pic got uploaded */}
        <div
          style={{
            display: previewUrl ? "block" : "none",
            width: "60%",
            margin: "auto",
          }}
        >
          <div style={{display:"flex",justifyContent:"center",margin:"auto"}}>
            {" "}
            {previewUrl && (
              <Image
                style={{objectFit:"contain"}}
                src={`data:image/png;base64,${previewUrl}`}
                width="250px"
                height="200px"
              />
            )}
          </div>
          <div
            style={{ marginTop: "30px", textAlign: "center" }}
            wrapperCol={{ offset: 10, span: 14 }}
          >
            <Button
              type="primary"
              onClick={handleImageUpload}
              style={{ marginRight: "15px", marginLeft: "20px" }}
            >
              Save
            </Button>
            <Button type="primary" onClick={HandleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default AvatarImage;
