import React from "react";
import { Button, Timeline } from "antd";
import { useSelector } from "react-redux";
import { CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';

function TimeLine({ delegate, handlePropSearchModel, fieldEmpty }) {
  const { clientsArray } = useSelector((state) => state.documentSection.CorpReducer);

  return (
    <div>
      <Timeline
        style={{
          marginBottom: 10,
        }}
      >
        <div style={{ display: "block" }}>

        <Timeline.Item
            color="blue"
            dot={fieldEmpty?.propertyType && <CheckCircleOutlined style={{fontSize:"17px",marginLeft:"6px"}}/>}
            style={{cursor:"pointer"}}
            onClick={handlePropSearchModel}
          >
            {/* <span style={{color:"#3D59AB",textDecoration:"underline"}}>Property Search</span> */}
            <Button type="primary">Property Search <SearchOutlined style={{fontSize:"16px",marginLeft:"3px"}}/></Button>
          </Timeline.Item>

       {delegate && <Timeline.Item
            color="blue"
            dot={fieldEmpty?.delegateBuyer?.id && <CheckCircleOutlined style={{fontSize:"17px",marginLeft:"6px"}}/>}
            style={{ fontSize: "14px" }}
          >
            Buyer Agent
          </Timeline.Item>}

          <Timeline.Item
            color="blue"
            style={{ fontSize: "14px" }}
            dot={fieldEmpty?.documentType?.length > 0 && <CheckCircleOutlined style={{fontSize:"17px",marginLeft:"6px"}}/>}
          >
            Select Contract Type
          </Timeline.Item>
          {/* <Timeline.Item color={onFocusData["template"]}>
          Template Selection
        </Timeline.Item> */}

        <Timeline.Item
          color='blue'
          style={{ fontSize: "14px" }}
          dot={fieldEmpty?.closingDateType && <CheckCircleOutlined style={{fontSize:"17px",marginLeft:"6px"}}/>}
        >
          Closing Date
        </Timeline.Item>
          <Timeline.Item
            color="blue"
            dot={fieldEmpty?.priceType?.length > 0 && <CheckCircleOutlined style={{fontSize:"17px",marginLeft:"6px"}}/>}
          >
            Price
          </Timeline.Item>
        </div>
        <Timeline.Item
          color="blue"
          dot={clientsArray?.length > 0 && <CheckCircleOutlined style={{fontSize:"17px",marginLeft:"6px"}}/>}
        >
          Add Buyers
        </Timeline.Item>
        
        {/* <Timeline.Item
        //  color={onFocusData["template"]}
        >
          Build Document
        </Timeline.Item> */}
      </Timeline>
    </div>
  );
}

export { TimeLine };
