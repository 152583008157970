/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import firebase from "../../firebase";
import "firebase/messaging";
import "../../Template/styles/Template.css";
import { userSignUpAction } from "../../User/UserAuthenticate/state/actions";
import { appStatusChange } from "../../App/state/actions";
import CustomModal from "./custom-modal";

const CommonModal = () => {
  const dispatch = useDispatch();
  const { showNotificationPopUp, userAuth } = useSelector((state) => state.userSignup);

  useEffect(() => {
    // Not showing the modal if notifications are already blocked
    if (Notification.permission === "denied") {
      console.log("Notifications are blocked by the user.");
      dispatch(userSignUpAction.handleShowNotification(false));
    }
  }, []);

  const handleClose = () => {
    dispatch(userSignUpAction.handleShowNotification(false));
  };

  const pushNotify = async () => {
    handleClose();
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const messaging = firebase.messaging();
        const deviceToken = await messaging.getToken();
        const sessions = { deviceToken };
        localStorage.setItem("userResponse", JSON.stringify(sessions));
        dispatch(appStatusChange.updatedeviceToken(deviceToken, userAuth));
      } else {
        console.log("Notification permission denied or blocked.");
      }
    } catch (error) {
      console.error("Error in getting permission/token:", error);
    }
  };

  if (!showNotificationPopUp) return null;

  return (
    <CustomModal isModalOpen={showNotificationPopUp} handleModalClose={handleClose}>
      <h2 style={{ fontSize: "30px", color: "#646464", fontWeight: "bold" }}>RE-Sure Notifications</h2>
      <p style={{ fontSize: "20px", color: "#646464" }}>Allow the RE-Sure application to send notifications about your business transactions and reminders.</p>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
          onClick={handleClose}
          style={{
            marginRight: "15px",
            background: "#dbdbdb",
            color: "white",
            borderRadius: "10px",
            width: "50%",
            height: "45px",
            fontSize: "20px",
            textTransform: "uppercase",
          }}
        >
          No
        </Button>
        <Button
          onClick={pushNotify}
          style={{
            background: "#085191",
            borderRadius: "10px",
            marginRight: "15px",
            color: "white",
            width: "50%",
            height: "45px",
            fontSize: "20px",
            textTransform: "uppercase",
          }}
        >
          Yes
        </Button>
      </div>
    </CustomModal>
  );
};

export default CommonModal;
