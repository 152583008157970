import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Popconfirm, Typography, Tooltip, Modal } from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import DetailedDocsClauseModal from './DetailedDocsClauseModal';
const {Text} = Typography

const HighlightText = ({ el, searchText, handleDelete, handleEdit }) => {
  const [modalState, setModalState] = useState({
    showDetailModal: false,
    data: {},
  })
  const highlightedText = (
    <Highlighter
      highlightClassName="highlightClass"
      searchWords={[searchText]}
      autoEscape={true}
      textToHighlight={el?.name?.replace(/\n/g, '<br/>')}
    />
  );

  return (
    <>
    
    <Text copyable={{text: el?.name}} style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'flex-start', cursor: 'pointer'}}
      onClick={()=>setModalState({showDetailModal: true, data: el})}
    >
      <span style={{width: "90%"}}>
        <div
        style={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
        }}
        
        dangerouslySetInnerHTML={{__html: highlightedText.props.textToHighlight}} />
      </span>
      <div style={{display: "flex", alignItems: 'flex-start', flexDirection: "row", marginTop: "5px"}}>
        {el.creationType === 'FJGG' ? null :
        <Tooltip trigger={'hover'} title="Edit">
          <EditTwoTone onClick={(e) => {e.stopPropagation(); handleEdit(el);}} style={{marginLeft: "15px"}} />
        </Tooltip>}
        <Popconfirm
          title="Are you sure you want to delete this Clause?"
          onConfirm={(e) =>{e.stopPropagation(); handleDelete(el._id)}}
          okText="Yes"
          cancelText="No"
        >
          {el.creationType ==='FJGG' ? null :
          <Tooltip trigger={'hover'} title="Delete">
            <DeleteTwoTone style={{margin: "0px 10px 0px 15px"}} onClick={(e)=> e.stopPropagation()} />
          </Tooltip>}
        </Popconfirm>
      </div>
    </Text>
    
    {modalState?.showDetailModal && <DetailedDocsClauseModal setModalState={setModalState} modalState={modalState} />}
    </>
  );
};

export default HighlightText;
