/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Grid } from "antd";
import "./style/index.css";
import RtdDesign from "./component/RtdDesign";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { transactionLaneDataHandle } from "./state/actions";
import { CgPrinter } from "react-icons/cg";
import { ArrowLeftOutlined, MailOutlined, UsergroupAddOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { decryptUrlParams, getOfferIdFromUrl } from "../Common/utils/extraFunctions";
import { Loading } from "../Common/components/Loading";
import DocsAndVendors from "./component/DocsAndVenders";
import styles from "./style/rtd.module.css";
import axios from "axios";
import { SignableAndReadOnly } from "../PropertyComp/components/SignableAndReadOnly";
import RtdLegend from "../Common/components/RtdLegend";
import DoraDocument from "./component/DoraDocument";
import { PropertyHandlers } from "../PropertyComp/state/actions";
import { getCombinedAgentsData } from "./utils/helper";
const { useBreakpoint } = Grid;

const TransactionLane = ({ section }) => {
  const delegate = (section === "DELEGATE");
  const { pathname } = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [showlegend, setShowLegend] = useState(false);
  const [page, setPage] = useState([1]);

  let { propertyDocsWithoutSection } = useSelector((state) => state.PropertyReducer, shallowEqual);
  const [nestedModalOpen, setNestedModalOpen] = useState({
    isEmailModalVisible: false,
    sendMultipleDoc: false,
  });
  const { getIndividualTransactionData, getIndividualTransactionError, addEmailSuccess, getTransactionLaneLoading, getTransactionLaneError,getTransactionLaneData, triggerIndividualTransactionFetch } = useSelector((state) => state.rtdTransactionLane, shallowEqual);
  let { uploadedPdfDocSuccess } = useSelector((state) => state.PropertyReducer,shallowEqual);
  const rtdClient = (pathname?.split("/")[(pathname?.split("/")?.length) - 2] === "sDashboard") ? "SELLER" : "BUYER";

 
  const currentAgentDetails = getIndividualTransactionData?.currentAgentId;
 
  const handleEmailIconClick = (event) => {
    // event.stopPropagation();
    if (!nestedModalOpen.isEmailModalVisible) {
      setNestedModalOpen({
        ...nestedModalOpen,
        isEmailModalVisible: true,
        sendMultipleDoc: true,
      });
    }
  }
  const screens = useBreakpoint();

  const allBuyerAgentData = useMemo(() => {
    const data = [];
    getCombinedAgentsData({ getIndividualTransactionData, agentData: getIndividualTransactionData?.buyerAgentIds, agentList: data, agentType: "BuyerAgents" });
    return data;
  }, [getIndividualTransactionData]);

  const allSellerAgentData = useMemo(() => {
    const data = [];
    getCombinedAgentsData({ getIndividualTransactionData, agentData: getIndividualTransactionData?.sellerAgentIds, agentList: data, agentType: "SellerAgents" });
    return data;
  }, [getIndividualTransactionData]);

  useEffect(() => {
    let unmounted = { value: false };
    let source = axios.CancelToken.source();
    const offerId = getOfferIdFromUrl()
    const delegateSide = delegate && rtdClient;
    let delegateOwnerId = null;
    if (delegateSide) {
      const encryptedOwnerId = queryParams.get("ref");
      delegateOwnerId = encryptedOwnerId ? decryptUrlParams(encryptedOwnerId) : null;
    }
    if(offerId){
      dispatch(transactionLaneDataHandle.getIndividualTransaction({ offerId, setLoading, unmounted, source, delegateSide, delegateOwnerId }));
    }
    return () => {
      // dispatch(transactionLaneDataHandle.resetRTDData());
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [addEmailSuccess, uploadedPdfDocSuccess, triggerIndividualTransactionFetch]);

  useEffect(() => {
    let unmounted = { value: false };
    let source = axios.CancelToken.source();
    const type = "SendEmail";
    let params = {
      ...(getIndividualTransactionData?.offerId ? { offerId: getIndividualTransactionData?.offerId } : {}),
      ...(rtdClient === "SELLER" && (getIndividualTransactionData?.propertyId ? { propertyId: getIndividualTransactionData?.propertyId } : {})),
      openFor: rtdClient === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
      personId: currentAgentDetails?._id,
      ...(delegate ? { delegateSide: rtdClient === "SELLER" ? "SELLER" : "BUYER", delegateOwnerId: currentAgentDetails?._id } : {}),
    };
    if (getIndividualTransactionData?.offerId && !loading) {
      dispatch(PropertyHandlers.getPropertyDocsFun({ params, unmounted, source, type }));
    }
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [getIndividualTransactionData, loading]);

  const handlePrintTransRep = (item) => {
    let buildData = {
      builtForId: currentAgentDetails?._id,
      key: currentAgentDetails?._id,
      builtForSection: "DOCUMENT",
      signable: true,
      offerId: getIndividualTransactionData?.offerId,
      token: localStorage.getItem("userAuth"),
      contractId: "63b6c7f1379c01d3efe69b12",
      contractType: "TR",
      ...(delegate ? { delegateOwnerId: currentAgentDetails?._id } : {}),
    };
    dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: pathname, source: "Print", delegateOwnerId: delegate && currentAgentDetails?._id }));
  };

  const openContactSheet = () => {
    let buildData = {
      builtForId: currentAgentDetails?._id,
      key: currentAgentDetails?._id,
      builtForSection: "CONTACT_SHEET",
      openFor: rtdClient === "SELLER" ? "SELLER_AGENT" : "BUYER_AGENT",
      signable: false,
      offerId: getIndividualTransactionData.offerId,
      token: localStorage.getItem("userAuth"),
      ...(delegate ? { delegateOwnerId: currentAgentDetails?._id } : {}),
    };
    dispatch(transactionLaneDataHandle.generateUrlIdAndRedirect({ buildData, currentPath: pathname, delegateOwnerId: delegate && currentAgentDetails?._id }));
  };

  return (
    <>
    {
      loading ? <Loading /> : (
        getIndividualTransactionError ? "" : <div
          className={(rtdClient === "SELLER") ? styles.laneMainDiv :  styles.laneMainBDiv}
        >
          {
            (rtdClient === "SELLER") && !(getTransactionLaneLoading || getTransactionLaneError) && <DocsAndVendors rtdClient={rtdClient} section={section}/>
          }

          <RtdLegend setShowLegend={setShowLegend} showlegend={showlegend} seller={rtdClient} />

          {
            (screens.xl || screens.xxl) && rtdClient === "SELLER" &&
            <div style={{width: "25%"}}>
              <DoraDocument seller={rtdClient} showDrawer={true} section={section} propertyDocs={propertyDocsWithoutSection}/>
            </div>
          }
          
          <div className={delegate ? styles.rtdDelegateWidthDiv : styles.rtdWidthDiv}>
            <div className={styles.rtdNavContainer}>
                 <span
                 className={styles.documentName}
                  onClick={() => {
                    history.push({ pathname: pathname?.split("/transactions")?.[0] + "/transactions", state: { category: rtdClient, categoryId: (rtdClient === "BUYER") ? getIndividualTransactionData.offerId : getIndividualTransactionData.propertyId }});
                  }}
                  style={{
                    marginLeft:"15px",
                    marginRight: "10px",
                    fontSize: "18px",
                  }}
                >
                  <ArrowLeftOutlined style={{ marginRight: "7px" }} />
                  Back
                </span>
                <span className={styles.documentName} onClick={() => setShowLegend(true)} >
                    <InfoCircleOutlined  style={{marginRight: "5px",marginBottom: "-3px",}} />
                    <span className={styles.rtdNavbar}>Information</span>
                </span>
                {(getIndividualTransactionData?.status === "ACCEPTED") && (
                    <span className={styles.documentName} onClick={handlePrintTransRep} style={{marginLeft: "10px",}}>
                      <CgPrinter style={{marginRight: "5px",marginBottom: "-3px",}}/>
                        <span className={styles.rtdNavbar}>Transaction Report</span>
                    </span>
                )}
               {(getIndividualTransactionData?.status === "ACCEPTED") && (<span onClick={openContactSheet} className={styles.documentName}>
                  <UsergroupAddOutlined style={{ marginRight: "5px", marginBottom: "-3px", }} />
                  <span className={styles.rtdNavbar}>Contact Sheet</span>
                </span>)}
                <span onClick={handleEmailIconClick} className={styles.documentName}>
                       <MailOutlined />
                       <span className={styles.rtdNavbar}>{" "}Send Email</span>
                </span>
              {/* </div> */}

            </div>
            <>
            <SignableAndReadOnly
                componentUsedSection="TRANSACTION_DASHBOARD"
                nestedModalOpen={nestedModalOpen}
                setNestedModalOpen={setNestedModalOpen}
                RTDInitiator={rtdClient}
                role={rtdClient}
                docSelected={false}
                source={ rtdClient === "SELLER" ? "SELLING_RTD" : "BUYING_RTD"}
                selectedData={getTransactionLaneData[0]}
                section={section}
                reloadSection="RTD"
                delegateOwnerId={delegate && currentAgentDetails?._id}
                page={page}
                setPage={setPage}
              />

              <RtdDesign
                currentAgentDetails={currentAgentDetails}
                client={rtdClient}
                section={section}
                allBuyerAgentData={allBuyerAgentData} 
                allSellerAgentData={allSellerAgentData}
               />
            </>
          </div>

          {
            (rtdClient === "BUYER") && !(getTransactionLaneLoading || getTransactionLaneError) && <DocsAndVendors rtdClient={rtdClient} section={section}/>
          }

          {
            (screens.xl || screens.xxl) && rtdClient === "BUYER" &&
            <div style={{width: "25%"}}>
              <DoraDocument seller={rtdClient} showDrawer={true} section={section} propertyDocs={propertyDocsWithoutSection} />
            </div>
          }
        
        </div>
      )
    }
  </>
  );
};

export { TransactionLane };
