import { Button, Collapse, Form, Input, List, Modal, Tooltip } from 'antd';
import React, { useState } from 'react'
import { CaretRightOutlined ,PhoneOutlined,MailOutlined,UserOutlined, PlusOutlined, CloseCircleOutlined, UserAddOutlined} from "@ant-design/icons"
import PhoneInput from 'react-phone-input-2';
import { formatPhoneNumber } from '../../Common/utils/extraFunctions';
import AddCloserModal from './AddCloserModal';

const TitleRepsCloser = ({ companyModalStep, setCompanyModalStep }) => {
  const { Panel } = Collapse
  const [add, setAdd] = useState({
    open: false,
    section: ""
  });
  const [activePanel, setActivePanel] = useState(null);
  
  const handleAdd = (value) => {
    setAdd({
      open: !add?.open,
      section: value
    });
  }

  const onFinish = (values) => {
      setCompanyModalStep({
        ...companyModalStep,
        ...((add?.section === "SALES_REP") ? {
          salesRep: {
            name: values?.name,
            email: values?.email,
            phoneNumber: formatPhoneNumber(values?.phoneNumber)
          }
        } : (add?.section === "CLOSER") ? {
          titleCloser: {
            name: values?.name,
            email: values?.email,
            phoneNumber: formatPhoneNumber(values?.phoneNumber)
          }
        } : {})
        
      })
    handleAdd("");
  }

  const reset = (event)=>{
    event.stopPropagation();
    setCompanyModalStep({
      ...companyModalStep,
      ...((add?.section === "SALES_REP") ? {
        salesRep: ""
      } : (add?.section === "CLOSER") ? {
        titleCloser: ""
      } : {})
    })
  }

  return (
    <>
      <div style={{display:"flex"}}>
        { 
          companyModalStep?.salesRep && <p style={{fontSize: "16px", fontWeight: "bold", marginLeft: ".3rem"}}>
            <text style={{color: "#096dd9",marginRight:"5px"}}>Title Rep :</text >
            {companyModalStep?.salesRep?.name}
          </p>
        }
        {
          companyModalStep?.titleCloser && <p style={{fontSize: "16px", fontWeight: "bold", marginLeft: "20px"}}>
            <text style={{color: "#096dd9", marginRight:"5px"}}>Closer :</text>
            {companyModalStep?.titleCloser?.name}
          </p>
        }
      </div>
      <Collapse
        style={{
          fontSize: "22px"
        }}
        expandIcon={({ isActive, panelKey }) => (
          <CaretRightOutlined
            rotate={isActive ? 90 : 0}
            style={{ fontSize: "1.3rem", cursor: 'pointer' }}
            onClick={(e) => {
              setActivePanel(!isActive ? panelKey : null);
            }}
          />
        )}
        bordered={false}
        activeKey={activePanel}
      >
        <Panel
          key="1"
          header={
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", border: "none" }}
              onClick={(e) => {
                setActivePanel(activePanel == 1 ? null : 1);
                if(!companyModalStep?.inBuilt && !companyModalStep?.salesReps){
                  handleAdd("SALES_REP")
                }
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: ".3rem",
                  color: "#096dd9",
                }}
              >
                Title Rep
              </span>
            </div>
          }
        >
          {
            companyModalStep?.inBuilt ? <List
              style={{
                borderRadius: "8px",
                maxHeight:"290px",
                overflowY: "auto"
              }}
              bordered={true}
              itemLayout="horizontal"
              dataSource={companyModalStep?.titleOffice?.salesReps || []}
              renderItem={(item) => {
                return (
                  <List.Item
                    style={{
                    background: (item._id == companyModalStep?.salesRep?._id) ? "rgb(132, 187, 238)" : "",
                      borderRadius: "8px"
                    }}
                    key={item._id}
                    onClick={(e)=>{
                      e.stopPropagation();
                      setCompanyModalStep({
                        ...companyModalStep,
                        salesRep: item
                      })
                    }}

                  >
                    <CommonList item={item}/>
                  </List.Item>
                );
              }}
            /> : 
            companyModalStep?.salesRep?.name ? <ManualUserSection data={companyModalStep?.salesRep} reset={reset}/> : 
              <div
                style={{
                  height: "120px",
                  padding:"20px",
                  borderRadius:"8px"
                }}
              >
                <Tooltip title="Add Title Rep">
                  <PlusOutlined style={{cursor :"pointer", fontSize: "30px", padding: "25px", border: "1px dashed grey"}} onClick={()=>handleAdd("SALES_REP")}/>
                </Tooltip>
              </div>
          }
          
        </Panel>

        <Panel
          key="2"
          header={
            <div style={{ display: "flex", justifyContent: "space-between" }} 
             onClick={(e) => {
              setActivePanel(activePanel == 2 ? null : 2);
              if(!companyModalStep?.inBuilt && !companyModalStep?.titleCloser){
                handleAdd("CLOSER")
              }
            }}>
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#096dd9",
                }}
              >
                Closer
              </span>
            </div>
          }
        >
          {
            companyModalStep?.inBuilt ? <List
              style={{
                borderRadius: "8px",
                maxHeight: "290px",
                overflowY: "auto"
              }}
              bordered={true}
              itemLayout="horizontal"
              dataSource={companyModalStep?.titleOffice?.titleClosers || []}
              renderItem={(item) => {
                return (
                  <List.Item
                    key={item._id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCompanyModalStep({
                        ...companyModalStep,
                        titleCloser: item
                      })
                    }}
                    style={{ background: (item._id == companyModalStep?.titleCloser?._id) ? "rgb(132, 187, 238)" : "",}}
                  >
                    <CommonList item={item}/>
                  </List.Item>
                );
              }}
            /> :
            companyModalStep?.titleCloser?.name ? <ManualUserSection data={companyModalStep?.titleCloser} reset={reset}/> : 
              <div
                style={{
                  height: "120px",
                  padding:"20px",
                  borderRadius:"8px"
                }}
              >
                <Tooltip title="Add Closer">
                  <PlusOutlined style={{cursor :"pointer", fontSize: "30px", padding: "25px", border: "1px dashed grey"}} onClick={()=>handleAdd("CLOSER")}/>
                </Tooltip>
              </div>
          }
          
        </Panel>
      </Collapse>
      {
        add?.open && <AddCloserModal add={add} onFinish={onFinish} handleAdd={handleAdd} companyModalStep={companyModalStep} setCompanyModalStep={setCompanyModalStep} />
      }
    </>
  )
}

const ManualUserSection = ({data, reset}) => {
  return <div
    style={{
      cursor :"pointer", 
      padding: "13px 20px",
      borderRadius:"8px",
      background: "rgb(132, 187, 238)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "3px"
    }}
  >
    <div style={{position: "absolute", marginTop: "-60px", marginLeft: "600px"}}>
      <CloseCircleOutlined
        style={{ fontSize: "20px",  }}
        onClick={reset}
      />
    </div>
    {
      data?.name && <div style={{display:"flex", alignItems:"center"}}>
        <UserAddOutlined style={{color:"black",marginRight:"10px",fontWeight:"bold",fontSize:"17px"}}/>
        <span  style={{color: "#096dd9", fontSize: "16px", fontWeight: "bold"}}>{data?.name}</span>
      </div>
    }
    {
      data?.email && <div style={{display:"flex", alignItems:"center"}}>
        <MailOutlined style={{color:"black", marginRight:"10px",fontWeight:"bold",fontSize:"17px"}}/>
        <span style={{ color: "black", fontSize: "16px" }}>{data?.email}</span>
      </div>
    }
    {
      data?.phoneNumber && <div style={{display:"flex", alignItems:"center"}}>
        <PhoneOutlined style={{color:"black", marginRight:"10px",fontWeight:"bold",fontSize:"17px"}}/>
        <span style={{ color: "black", fontSize: "16px" }}>{data?.phoneNumber}</span>
      </div>
    }
  </div>
}

const CommonList = ({item}) => {
  return <List.Item.Meta
    title={<><UserOutlined style={{color:"black",marginRight:"10px"}}/><text style={{ color: "#096dd9",}}>{item?.name}</text></>}
    description={
      <div style={{display:"flex",flexDirection:"column",marginTop:"-5px"}}>
        <div style={{ color: "black", fontSize: "16px" ,display:"flex",alignItems:"center"}}>
          <MailOutlined style={{marginRight:"10px"}}/> <text style={{marginBottom:"5px"}}>{item?.email}</text>
        </div>
        <div style={{ color: "black", fontSize: "16px",display:"flex",alignItems:"center" }}>
          <PhoneOutlined style={{marginRight:"10px"}} /> {item?.phoneNumber?item?.phoneNumber:"N/A"}
        </div>
      </div>
    }
  />
}

export {TitleRepsCloser};