/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { Account } from "../Account";
import { Error404 } from "../Common/components/Error404";
import { Client } from "../Client";
import { DocumentSection } from "../DocumentSection";
import { PrivateRoute } from "./components/PrivateRoute";
import { LogoutWindow } from "./components/LogoutWindow";
import { UserAuthenticate } from "../User/UserAuthenticate";
import { UserLogin } from "../User/UserAuthenticate";
import { ForgetPassword } from "../User/UserAuthenticate";
import { UserSignUp } from "../User/UserAuthenticate";
import { Template } from "../Template";
import { TestingRoute } from "../BugReport/components/TestingRoute";
import { BugsLogin } from "../BugReport/components/BugsLogin";
import { BugReportHome } from "../BugReport/BugReportHome";
import { Failed } from "../Common/components/Failed";
import { ChangePassword } from "../Common/components/ChangePassword";
import { CalendarHome } from "../Calendar";
import { WebSharing } from "./components/WebSharing";
import Landing from "../Landing/Landing";
import CommonModal from "../Common/components/CommonModal";
import Tools from "../Tools/Tools";
import { useDispatch, useSelector } from "react-redux";
import Pricing from "../Pricing/Pricing";
import { PaymentSuccess } from "../Pricing/components/PaymentSuccess";
import PaymentFailure from "../Pricing/components/PaymentFailure";
import firebase from "../firebase";
import { LicenseId } from "./components/LicenseId";
import { Setting } from "../Setting/Setting";
import { Suggestions } from "../BugReport/components/Suggestions";
import { appStatusChange } from "./state/actions";
import ClientAuth from "../ClientAuth/ClientAuth";
import { ClientPrivateRoute } from "./components/ClientPrivateRoute";
import ClientScreen from "../ClientScreen/ClientScreen";
import { logoutAndInAllTabs } from "./components/Broadcast";
import { Support } from "../BugReport/components/Support";
import {DeleteAccount} from "../DeleteAccount/DeleteAccount"
import HandleRedirections from "./components/HandleRedirections";
import NewPropertyDesign from "../PropertyComp/NewPropertyDesign";
import dotEnv from "dotenv";
import ReadOnlyClientScreen from "../ClientScreen/ReadOnlyClientScreen";
dotEnv.config();


function App() {
  const [networkConnection, setNetworkConnection] = useState();
  // state for notification popup not display at any time
  const {showNotificationPopUp,  } = useSelector((state) => state.userSignup);
  const dispatch = useDispatch();

  useEffect(() => {
    logoutAndInAllTabs();
  }, []);

  
  const isFCMSupported = firebase?.messaging?.isSupported();
  if (isFCMSupported) {
  const messaging = firebase?.messaging();
  const onMessageListener = () =>
  new Promise((resolve, reject) => {
    try {
      messaging?.onMessage((payload) => {
        dispatch(appStatusChange.getNotificationtData(1, "delete"));
        dispatch(appStatusChange.getNotificationtCountData());
        resolve(payload);
      });
    } catch (error) {
      reject(error);
    }
  });
  onMessageListener()
  } else {
  console.log("Firebase Messaging is not supported in this browser.");
  }

 
  useEffect(() => {
    window.addEventListener("online", () => {
      setNetworkConnection(false);
    });
    window.addEventListener("offline", () => {
      setNetworkConnection(true);
    });
  }, []);




  return (
    <div>
      {showNotificationPopUp && isFCMSupported && <CommonModal />}
      {/* routing for the app */}
      <Router>
        <Switch>
          {/* <--------------testing-------------> */}
          <PrivateRoute networkConnection={networkConnection} exact path="/license" component={LicenseId} />
          <PrivateRoute networkConnection={networkConnection} exact path="/setting" component={Setting} />
          <Route exact path="/clientAuth" component={ClientAuth} />
          <PrivateRoute networkConnection={networkConnection} exact path="/pricing" component={Pricing} />
          <PrivateRoute networkConnection={networkConnection} exact path="/offer" component={DocumentSection} />
          <PrivateRoute networkConnection={networkConnection} exact path="/offer/:id" component={DocumentSection} />
          <PrivateRoute networkConnection={networkConnection} exact path="/pricing" component={Pricing} />
          <PrivateRoute networkConnection={networkConnection} exact path="/success" component={PaymentSuccess} />
          <PrivateRoute networkConnection={networkConnection} exact path="/failed" component={PaymentFailure} />
          <PrivateRoute networkConnection={networkConnection} exact path="/template" component={Template} />
          <PrivateRoute
            networkConnection={networkConnection}
            // exact
            path="/tools"
            component={Tools}
          />
          <PrivateRoute networkConnection={networkConnection} exact path="/contacts" component={Client} />
          <PrivateRoute networkConnection={networkConnection} exact path="/deleteAccount" component={DeleteAccount} />
          {/* <PrivateRoute
            networkConnection={networkConnection}
            exact
            path="/client/:id"
            component={Client}
          /> */}
          <PrivateRoute networkConnection={networkConnection} path="/account" component={Account} />
          <PrivateRoute networkConnection={networkConnection} path="/calendar/:date" component={() => <CalendarHome />} />
          <PrivateRoute exact path="/changePassword" component={ChangePassword} />
          <PrivateRoute networkConnection={networkConnection} path="/transactions" component={() => <NewPropertyDesign />} />
          <ClientPrivateRoute exact path="/clientScreen" component={ClientScreen} />
          <TestingRoute exact path="/bugsReport" component={BugReportHome} />
          <TestingRoute exact path="/suggestions" component={Suggestions} />
          <TestingRoute exact path="/mobilesupport" component={Support} />
          <TestingRoute exact path="/yourBugs" component={BugReportHome} />
          <TestingRoute exact path="/yourSuggestions" component={BugReportHome} />
          <TestingRoute exact path="/yourTickets" component={BugReportHome} />
          <UserAuthenticate exact path="/login" component={UserLogin} />
          <UserAuthenticate exact path="/forgetpassword" component={ForgetPassword} />
          <UserAuthenticate exact path="/signup" component={UserSignUp} />
          {/* <TestingRoute
            exact
            path="/bugsReport/:key"
            component={BugReportHome}
          /> */}
          <Route exact path="/bugsLogin" component={BugsLogin} />
          <Route exact path="/webSharing" component={WebSharing} />
          <Route exact path="/error" component={Error404} />
          <Route exact path="/logout" component={LogoutWindow} />
          <Route exact path="/failed" component={Failed} />
          <Route exact path="/redirections" component={HandleRedirections} />
          <Route exact path="/clientCalendar" component={ReadOnlyClientScreen} />

          <PrivateRoute
            networkConnection={networkConnection}
            // exact
            path="/"
            component={Landing}
          />
          <Route path="*">
            <Redirect to="/error" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export { App };
