import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Typography, Avatar, Button, Image,  Tooltip, Form, Input, DatePicker, Select, Row, Col } from "antd";
import { ImageUploadDetails } from "../../Client/components/ImageUploadDetails";
import { accountHandle } from "../state/actions";
import { AiFillCreditCard } from "react-icons/ai";
import { EditTwoTone, PlusCircleFilled, MailOutlined, UserOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { NameDetails } from "./NameDetails";
import imagePlaceholder from "../../Assets/imagePlaceholder.webp";
import styles from "../styles/Accounts.module.css";
import { PhoneDetails } from "./PhoneDetails";
import { FaMedal } from "react-icons/fa";
import LicenceDetails from "./LicenceDetails";
import { FaPhoneAlt } from "react-icons/fa";
import { RxPencil1 } from "react-icons/rx";
import { formatPhoneNumber } from "../../Common/utils/extraFunctions";
import { PositionDetails } from "./PositionDetails";
import { Loading } from "../../Common/components/Loading";
import "../styles/BlinkingEffect.css";
import { PropertyHandlers } from "../../PropertyComp/state/actions";
import axios from "axios";
import { EditBrokerageForm } from "./EditBrokerageForm";
import { FiRefreshCw } from "react-icons/fi";
const { Text } = Typography;


function Genral({ comingFrom }) {
  const dispatch = useDispatch();
  const [modelDrawer, setModelDrawer] = useState({
    status: false,
    sourceLocation: "",
    component: "",
    title: "",
  });

  const [ confirmModal, setConfirmModal ] = useState({
    open: false,
    values: {}
  });
  const { getUserDetailsData, userId, getUserProfileDataLoading, editBrokerageLoading, createLicenseData, getLicenseData, getLicenseLoading, getLicenseError, createLicenseLoading, createLicenseError } = useSelector((state) => state.account);

  const { getPropertyLicenseData, getPropertyLicenseLoading } = useSelector((state) => state.PropertyReducer,);


  let unmounted = {
    value: false
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    dispatch(accountHandle.getAllLicense({ unmounted, source }));
    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    }
  }, [createLicenseData]);

  const transformedOptions = getPropertyLicenseData?.map(item => ({
    value: item.value,
    disabled: getLicenseData?.some(el => el.idType === item.value),
    label: item.label
  }));

  const [formOpen, setFormOpen] = useState(false);
  const [form] = Form.useForm();
  const [joinBrokerageForm] = Form.useForm();
  const [brokerageForm] = Form.useForm();
  const [selectedIdType, setSelectedIdType] = useState(null);
  const [openModal, setOpenModal] = useState({
    licenseModal: false,
    joinBrokerageModal: false,
    changeBrokerage:false,
    disableJoinBtn:true
  })
  const [showLicense, setShowLicense] = useState(false)

  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 9,
      },
      lg: {
        span: 8,
      }
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 24,
      },
      md: {
        span: 15,
      },
      lg: {
        span: 16,
      }
    },
  };

  const handelopenlicense = () => {
    setOpenModal({ ...openModal, licenseModal: true })
    dispatch(PropertyHandlers.getMlsConstants({ isMlsId: false, source, unmounted }));
    dispatch(accountHandle.createLicenceError(false));
  }
  const handleIdTypeChange = (value) => {
    setSelectedIdType(value);
  };
  const onFinish = (values) => {
    // Convert the moment object to a JavaScript Date object
    const dateValue = values.expiryDate ? values.expiryDate.toDate() : null;

    // Include the expiration date in the values object conditionally
    const updatedValues = {
      ...values,
      ...(selectedIdType === "COLORADO STATE LICENSE" ? {} : { expiryDate: dateValue }),
    };

    // Dispatch the action with the updated values
    dispatch(accountHandle.createLicense({ payload: updatedValues, getUserDetailsData }));

    form.resetFields();
    setOpenModal({ ...openModal, licenseModal: false });
    setSelectedIdType(null)
  };

  const handleJoinBrokerageModal = (origin) => {
    origin?.length ? setOpenModal({ ...openModal, joinBrokerageModal: true,changeBrokerage:true }) : setOpenModal({ ...openModal, joinBrokerageModal: true, changeBrokerage:false })
  }

  const handleJoinBrokerage = (values) => {
    const brokerageData = getUserDetailsData?.brokerageData;
    const anyBrokerageDataFilled = (brokerageData?.brokerageName || brokerageData?.brokerageName || brokerageData?.brokerageAddress || brokerageData?.brokeragePhoneNumber || brokerageData?.brokerageFaxNumber || brokerageData?.brokerageEmail || brokerageData?.brokerageLogo);
    if(anyBrokerageDataFilled){
      setConfirmModal({
        open: true,
        values
      })
    }
    else{
      handleJoinBrokerageSubmit({updateBrokerageData: true, values})
    }
  }

  const handleOk = () => {
    handleJoinBrokerageSubmit({updateBrokerageData: true, values: confirmModal.values})
    handleConfirmCancel();
  }

  const handleNo = () => {
    handleJoinBrokerageSubmit({values: confirmModal.values})
    handleConfirmCancel();
  }

  const handleJoinBrokerageSubmit = ({updateBrokerageData, values}) => {
    let urlData = {
      ...(values?.brokerageCode?.length) ? { code: values?.brokerageCode } : {},
      ...(updateBrokerageData ? {updateBrokerageData} : {})
    }
    dispatch(accountHandle.editBrokerage(urlData))
    handleJoinBrokerageModalCancel()
  }

  const handleJoinBrokerageModalCancel = () => {
    setOpenModal({ ...openModal, joinBrokerageModal: false, changeBrokerage:false, disableJoinBtn:true })
    joinBrokerageForm.resetFields()
  }

  const handleLeaveBrokerage = () => {
    let urlData = {
      leaveBrokerage:true
    }

    Modal.confirm({
      title:"Are you sure you want to leave this brokerage?",
      okText:"Yes",
      cancelText:"No",
      onOk () {
        dispatch(accountHandle.editBrokerage(urlData))
      },
      onCancel () {}
    })
  }

  const handleValueChange = (changedValues) => {
    const brokerageCode = changedValues?.brokerageCode || joinBrokerageForm.getFieldValue('brokerageCode');
    setOpenModal({...openModal,disableJoinBtn:!brokerageCode})
  }

  useEffect(() => {
    if (comingFrom) {
      document.getElementById('Addlisenserow').classList.add('blinkingEffect');
      const timeoutId = setTimeout(() => {
        document.getElementById('Addlisenserow').classList.remove('blinkingEffect');
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [comingFrom]);

  const handelCancel = () => {
    form.resetFields();
    setOpenModal({ ...openModal, licenseModal: false });
    setSelectedIdType("")
  }

  const handleConfirmCancel = () => {
    setConfirmModal({
      open: false,
      values: {}
    })
  }

  const handleRefresh = () => {
    let selectedRow = getLicenseData?.length > 0 && getLicenseData.find((ele) => ele?.idType === "COLORADO STATE LICENSE")
    if (selectedRow && selectedRow?.id) {
      let payload = {
        idType: selectedRow.idType,
        id: selectedRow.id,
      };
      dispatch(accountHandle.updateLicense({ licenseMongoId: selectedRow?._id, payload, getUserDetailsData }));
    }
  }
  
  return (
    <>
    <Modal
        title={<div style={{padding: "20px 20px 0px 20px"}}>
          <ExclamationCircleOutlined /> Do you also want to update your existing brokerage details with the joining brokerage details?
        </div>}
        open={confirmModal.open}
        onCancel={handleConfirmCancel}
        footer={[
          <Button key="secondary" type="default" onClick={handleNo}>
            No
          </Button>,
          <Button key="ok" type="primary" onClick={handleOk}>
            Yes
          </Button>,
        ]}
      >
        <p style={{padding: "0px 20px"}}>Note: Click 'Yes' to update the brokerage details below with the joining brokerage, or click 'No' if you only want to join the brokerage without updating any brokerage details.</p>
      </Modal>
      <Modal visible={openModal.licenseModal}
        width={500}
        style={{ top: modelDrawer.sourceLocation ? 20 : "" }}
        title={
          <div style={{ position: "absolute", top: "0", left: "0", right: "0", height: "50px", display: "flex", alignItems: "center", background: "#2171B9", justifyContent: "center" }}>
            <Text style={{ color: "white", fontSize: "20px", }}>
              ADD LICENSE DETAILS
            </Text></div>
        }
        closeIcon={
          <div style={{ position: "absolute", top: "0", left: "0", right: "0" }}>
            <CloseOutlined style={{ backgroundColor: 'white', borderRadius: "50%", fontSize: "15px", fontWeight: "bold", padding: '5px', color: "#2171B9", marginTop: "10px", marginRight: "5px" }} />
          </div>
        }
        okText="Save"
        cancelText="Cancel"
        footer={false}
        onCancel={handelCancel}
        onOk={() => { }}
      >
        {(createLicenseLoading || getLicenseLoading) ? <><Loading /></> : (getLicenseError || createLicenseError) ? "" : <> {(
          <>
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="ID Type"
                name="idType"
                rules={[{ required: true, message: "Please Select the ID Type" }]}
                style={{
                  marginTop: "10px"
                }}
              >
                <Select
                  allowClear
                  placeholder="Enter ID type"
                  options={transformedOptions}
                  onChange={handleIdTypeChange}
                  loading={getPropertyLicenseLoading}
                />
              </Form.Item>

              <Form.Item
                label="ID No."
                name="id"
                rules={[{ required: true, message: "Enter License Id" }]}
              >
                <Input placeholder="Enter License ID" allowClear />
              </Form.Item>
              {(selectedIdType !== "COLORADO STATE LICENSE" &&
                selectedIdType !== "RECOLORADO" && selectedIdType) && (
                  <Form.Item
                    label="Expire Date"
                    name="expiryDate"
                  // rules={[{ message: "Please Enter Expiration Date" }]}
                  >
                    <DatePicker
                      inputReadOnly={true}
                      style={{ width: '100%' }}
                      format="MM/DD/YYYY"
                      placeholder="Enter Expiry Date"
                    />
                  </Form.Item>
                )}
              <Form.Item wrapperCol={{ offset: 8, span: 12 }}>
                <Button type="primary" style={{ background: "gray", borderRadius: "5px", border: "none" }} onClick={handelCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" style={{ background: "#2171B9", marginLeft: "15px", borderRadius: "5px" }}>
                  Save
                </Button>
              </Form.Item>
            </Form>
          </>
        )}</>}
      </Modal>
      {/* Modal for updating all of the details */}
      {modelDrawer.status && (
        <Modal
          visible={modelDrawer.status}
          width={600}
          style={{ top: modelDrawer.sourceLocation ? 20 : "" }}
          title={
            <Text style={{ color: "#1890ff", textDecorationLine: "underline" }}>
              {modelDrawer.title}
            </Text>
          }
          okText="Save"
          cancelText="Cancel"
          footer={false}
          onCancel={() =>
            setModelDrawer({ status: false, component: "", title: "" })
          }
          onOk={() => { }}
        >
          {modelDrawer.component}
        </Modal>
      )}

      {/* Modal for updating the brokerage details */}

      {/* Modal to Join Brokerage */}
      <Modal open={openModal.joinBrokerageModal} title={<div style={{ textAlign: "center", color: "#178DFA", fontWeight: "bold", fontSize: "20px" }}>{openModal.changeBrokerage ? "Change" : "Join"} Brokerage</div>} onCancel={handleJoinBrokerageModalCancel} footer={false}>
        <Form form={joinBrokerageForm} onFinish={handleJoinBrokerage} {...formItemLayout} onValuesChange={handleValueChange}>
          <Form.Item label="Brokerage Code" name="brokerageCode" required>
            <Input placeholder="Enter Brokerage Joining Code..."></Input>
          </Form.Item>
          <Row justify={"end"} gutter={0} style={{ width: "100%" }}>
            <Col>
              <Button onClick={handleJoinBrokerageModalCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit" style={{ marginLeft: "10px" }} disabled={openModal.disableJoinBtn}>{openModal.changeBrokerage ? "Change" : "Join"}</Button>
            </Col>
          </Row>
        </Form>
      </Modal>


      {(getUserProfileDataLoading || editBrokerageLoading) ? (
        <Loading />
      ) : (
        Object.keys(getUserDetailsData).length > 0 && (
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <section
              style={{
                width: "100%",
                background: "white",
                height: "250px",
                position: "sticky",
                top: "0",
                zIndex: "1100"
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <img
                  loading="lazy"
                  src={
                    getUserDetailsData?.coverImg &&
                      getUserDetailsData?.coverImg?.original !== ""
                      ? getUserDetailsData?.coverImg?.original
                      : "https://resureprojectbucket.s3.us-east-2.amazonaws.com/dev/63fdfa38858258c667ce6777/PROFILE_PICTURES/3defaultCover.jpg"
                  }
                  style={{
                    height: "175px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    minWidth: "100%",
                  }}
                  alt="Cover img"
                />
              </div>
              <Avatar
                onClick={(event) => {
                  event.stopPropagation();
                  setModelDrawer({
                    status: true,
                    component: (
                      <ImageUploadDetails
                        setModelDrawer={setModelDrawer}
                        data={getUserDetailsData}
                        selectedClient={userId}
                        section={"ACCOUNT"}
                        sourcePlace="COVER_IMAGE"
                        supportedFormats={["image"]}
                      />
                    ),
                    title: "Upload Image",
                  });
                }}
                shape="circle"
                size="small"
                style={{
                  position: "absolute",
                  top: 15,
                  right: 40,
                  zIndex: 30,
                  background: "white",
                  cursor: "pointer",
                }}
                icon={<RxPencil1 style={{ color: "blue" }} />}
              ></Avatar>
              <div
                style={{
                  position: "absolute",
                  top: 60,
                  left: 65,
                  zIndex: 30,
                }}
              >
                {getUserDetailsData?.profileImg &&
                  getUserDetailsData?.profileImg?.original !== ""
                  ?
                  <Image
                    style={{
                      borderRadius: "50%",
                      width: "150px",
                      height: "152px",
                      border: "1px solid grey",
                      objectFit: "cover"
                    }}
                    preview={
                      (getUserDetailsData?.profileImg &&
                        getUserDetailsData?.profileImg?.original === ""
                        ? false
                        : true,
                      {
                        mask: (
                          <div
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              zIndex: 11,
                              opacity: 11,
                              bottom: "0px",
                              right: "0px",
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setModelDrawer({
                                status: true,
                                component: (
                                  <ImageUploadDetails
                                    setModelDrawer={setModelDrawer}
                                    data={getUserDetailsData}
                                    selectedClient={userId}
                                    section={"ACCOUNT"}
                                    supportedFormats={["image"]}
                                  />
                                ),
                                title: "Upload Image",
                              });
                            }}
                          >
                            <Avatar
                              style={{ color: "#1890ff", zIndex: 1100 }}
                              size="small"
                              icon={<EditTwoTone style={{ color: "#1890ff" }} />}
                            />
                          </div>
                        ),
                      })
                    }
                    src={
                      getUserDetailsData?.profileImg &&
                        getUserDetailsData?.profileImg?.original !== ""
                        ? getUserDetailsData?.profileImg?.original
                        : imagePlaceholder
                    }
                    alt=""
                  /> :
                  <div>
                    <div
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        zIndex: 11,
                        opacity: 11,
                        bottom: "0px",
                        right: "0px",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        setModelDrawer({
                          status: true,
                          component: (
                            <ImageUploadDetails
                              setModelDrawer={setModelDrawer}
                              data={getUserDetailsData}
                              selectedClient={userId}
                              section={"ACCOUNT"}
                              supportedFormats={["image"]}
                            />
                          ),
                          title: "Upload Image",
                        });
                      }}
                    >
                      <Avatar
                        style={{ color: "#1890ff", zIndex: 1100 }}
                        size="small"
                        icon={<EditTwoTone style={{ color: "#1890ff" }} />}
                      />
                    </div>
                    <div style={{
                      borderRadius: "50%",
                      width: "150px",
                      height: "152px",
                      border: "1px solid grey",
                      background: "whitesmoke"
                    }}>
                      <UserOutlined style={{ color: "grey", fontSize: "70px", position: "relative", left: "40px", top: "40px" }} />
                    </div>
                  </div>
                }
              </div>
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    position: "absolute",
                    top: 210,
                    left: 80,

                    textTransform: "capitalize",

                  }}
                  className={styles.genralText}
                  onClick={() =>
                    setModelDrawer({
                      status: true,
                      component: (
                        <NameDetails
                          setModelDrawer={setModelDrawer}
                          data={getUserDetailsData}
                          selectedClient={userId}
                        />
                      ),
                      title: "NAME DETAILS",
                    })
                  }
                >
                  <h1 style={{
                    fontSize: "25px",
                    fontWeight: "bold", zIndex: 30,
                  }}>
                    {`${getUserDetailsData?.firstName}
                                ${getUserDetailsData?.middleName !== null
                        ? getUserDetailsData?.middleName
                        : ""
                      }
                                  ${getUserDetailsData?.lastName}
                              `}
                  </h1>
                  {getUserDetailsData && getUserDetailsData?.roles && getUserDetailsData?.roles?.includes("TRIAL_USER") ?
                    <text
                      style={{
                        fontSize: "15px",
                        marginLeft: "5px",
                        marginTop: "10px",
                        fontWeight: "bold"
                      }}
                    >(Trial Account)
                    </text> :
                    <text></text>}
                </div>
              </div>
            </section>
            <div style={{}}>
              <section
                style={{
                  display: "grid",
                  zIndex: "1500",
                  alignItems: "flex-start",
                  gridTemplateColumns: "50% 3% auto",
                  // marginTop: "80px",
                  paddingLeft: "20px",
                  padding: "0rem 4rem",
                  height: "calc(100vh - 370px)",
                  overflowX: "hidden",
                  overflowY: "auto",
                }}
              >
                {/* all personal detais */}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    marginTop: "7%",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "25px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: -5,
                          color: "red",
                          fontSize: 20,
                        }}
                      >
                        *
                      </span>

                      <Tooltip
                        title={"Designation"}
                        placement="left"
                      >
                        <FaMedal
                          style={{
                            color: "black",
                            fontSize: "18px",
                            marginTop: "5px",
                            marginLeft: "13px",
                          }}
                        />
                      </Tooltip>
                    </div>

                    <div
                      className={styles.genralText}
                      style={{
                        fontSize: "18px",
                      }}
                      onClick={() =>
                        setModelDrawer({
                          status: true,
                          component: (
                            <PositionDetails
                              setModelDrawer={setModelDrawer}
                              data={getUserDetailsData}
                              selectedClient={userId}
                              section={"ACCOUNT"}
                            />
                          ),
                          title: "DESIGNATION DETAILS",
                        })
                      }
                    >
                      {" "}
                      {getUserDetailsData.designation
                        ? getUserDetailsData.designation
                        : "Enter Designation"}{" "}
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", gap: "25px", alignItems: "center" }}
                  >
                    <div>
                      <Tooltip
                        title={"Email ID"}
                        placement="left"
                      >
                        <MailOutlined
                          style={{
                            color: "black",
                            fontSize: "20px",
                            marginTop: "15px",
                            marginLeft: "13px",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <Typography.Text
                      ellipsis
                      style={{
                        fontSize: "18px",
                      }}
                    >
                      {getUserDetailsData?.email}
                    </Typography.Text>
                  </div>
                  <div
                    style={{ display: "flex", gap: "25px", alignItems: "center" }}
                  >
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: 5,
                          color: "red",
                          fontSize: 20,
                        }}
                      >
                        *
                      </span>

                      <Tooltip
                        title={"Phone No."}
                        placement="left"
                      >
                        <FaPhoneAlt
                          style={{
                            color: "black",
                            fontSize: "20px",
                            marginTop: "18px",
                            marginLeft: "13px",
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div style={{ display: "flex", justifyContent: 'space-between', width: "430px" }}>
                      <div
                        className={styles.genralText}
                        onClick={() =>
                          setModelDrawer({
                            status: true,
                            component: (
                              <PhoneDetails
                                setModelDrawer={setModelDrawer}
                                data={getUserDetailsData}
                                selectedClient={userId}
                                section={"ACCOUNT"}
                              />
                            ),
                            title: "PHONE DETAILS",
                          })
                        }
                      >
                        {getUserDetailsData?.mobilePhoneNumber?.phoneNumber &&
                          Object.keys(getUserDetailsData?.mobilePhoneNumber?.phoneNumber).length > 0
                       
                          ? formatPhoneNumber(
                            getUserDetailsData.mobilePhoneNumber?.phoneNumber
                          )
                          : "Update Phone No."}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", gap: "25px", alignItems: "center", }}
                  >

                    <div
                      style={{
                        position: "relative",

                      }}
                    >


                      <Tooltip
                        title={"License"}
                        placement="left"
                      >
                        <AiFillCreditCard
                          style={{
                            color: "black",
                            fontSize: "20px",
                            marginTop: "8px",
                            marginLeft: "13px",
                          }}

                        />
                      </Tooltip>
                    </div>

                    <div id="Addlisenserow" style={{ display: "flex", justifyContent: 'space-between', width: window.screen.width > 1024 ? "32vw" : "430px", }}>
                      <div
                        style={{ fontSize: "18px" }}
                      >
                        License Details
                      </div>
                        {getLicenseData?.length > 0 && getLicenseData.find((ele) => ele?.idType === "COLORADO STATE LICENSE") && (
                          <div style={{ marginLeft: "10px", marginRight: "auto" }}>
                            <Tooltip title="Update License" placement="topLeft">
                              <FiRefreshCw
                                style={{
                                  fontSize: "22px",
                                  color: "blue",
                                  cursor: "pointer",
                                  marginTop: "5px"
                                }}
                              onClick={handleRefresh}
                              />
                            </Tooltip>
                          </div>)}
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {showLicense && <Button

                            style={{
                              borderRadius: '50px 0px 0px 50px',
                              height: "25px",
                              width: "100px",
                              color: "#2171B9",
                              borderLeft: '2px dashed #2171B9',
                              borderTop: '2px dashed #2171B9',
                              borderBottom: '2px dashed #2171B9', // Dashed left border
                              borderRight: 'none', // Solid right border
                              display: 'flex',
                              alignItems: 'center', // Adjust this value as needed
                            }}
                          >
                            Add License

                          </Button>}
                          <PlusCircleFilled
                            style={{
                              fontSize: "24px",
                              fill: "#085191",
                              color: "#085191",
                              marginRight: "20px",
                              marginTop: "2px",
                              position: "relative",
                              cursor: "pointer"

                            }}
                            onClick={() => handelopenlicense()}
                            onMouseEnter={() => setShowLicense(true)}
                            onMouseLeave={() => {
                              setShowLicense(false)
                            }}
                          />
                        </div>
                      </div>

                    </div>

                  </div>
                  <div style={{ width: window.screen.width > 1024 ? "32vw" : "430px" }}>
                    <LicenceDetails
                      setModelDrawer={setModelDrawer}
                      selectedClient={userId}
                      formOpen={formOpen}
                      setFormOpen={setFormOpen}
                    />
                  </div>
                </div>

                {/* Middle line border*/}
                <div
                  style={{
                    height: "calc(100vh - 50px)",
                    marginTop: "40px",
                    borderLeft: "2px solid #cfcdcd",
                  }}
                ></div>

                {/*company  details */}
                <div style={{ paddingRight: "20px", position: "relative", marginTop: "7%", width: "113%" }}>
                  <div style={{ display: "flex", justifyContent: "center", gap: "12px", fontSize: "22px", fontWeight: "bold", marginBottom: "10px" }}><span>Brokerage</span></div>
                  {(
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>

                      {(!getUserDetailsData?.brokerageData?.brokerageId) && <div style={{ marginLeft: "75%" }}>
                        <Button type="primary" onClick={handleJoinBrokerageModal}>
                          Join Brokerage
                        </Button>
                      </div>}
                          <>
                            <EditBrokerageForm getUserDetailsData={getUserDetailsData} brokerageForm={brokerageForm}/>
                          
                          {getUserDetailsData?.brokerageData?.brokerageId && <>
                           <div style={{ display: "flex", justifyContent: "center", gap: "12px", fontSize: "20px", fontWeight: "bold", marginBottom: "10px",marginTop:"30px" }}><span>You are linked to the following brokerage</span></div>
                           {(Object.keys(getUserDetailsData?.brokerageData)?.length >= 0 && getUserDetailsData?.brokerageData?.brokerageId) && <div style={{ marginLeft: "75%" }}>
                             <Button type="primary"
                               onClick={handleLeaveBrokerage}
                               >
                                Leave Brokerage
                              </Button>
                            </div>}
                              {(getUserDetailsData?.brokerageData?.brokerageId?.logo) && <div style={{ display: "flex", gap: "30px" }}>
                                <div style={{ marginTop: "25px" }}>Logo : </div>
                                <div style={{ marginLeft: "67px", marginBottom: "5px" }}>
                                  <Image
                                    preview
                                    loading="lazy"
                                    src={
                                      getUserDetailsData?.brokerageData?.brokerageId?.logo
                                    }
                                    style={{
                                      height: "60px",
                                      width: "80px",
                                      objectFit: "contain",
                                    }}
                                    alt="logo"
                                  />
                                </div>
                              </div>}

                             {getUserDetailsData?.brokerageData?.brokerageId?.name && <div style={{ display: "flex", gap: "30px", marginBottom:"20px" }}>
                                <div>Name : </div>
                               <div style={{ fontWeight: "bold", marginLeft: "70px" }}>
                                 {getUserDetailsData?.brokerageData?.brokerageId?.name}
                               </div>
                             </div>}

                           </>}
                          </>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        )
      )}
    </>
  );
}
export { Genral };
