import React, { useState } from "react";
import { EmptyData } from "../../Common/components/EmptyData";
import styles from "../styles/Tool.module.css"
// import Highlighter from "react-highlight-words";
import "../styles/highlighter.css"
import HighlightText from "./HighlightText";
import { Spin, Tag } from "antd";

const ClausedataList = ({
  getClauseData,
  getClausesLoading,
  handleEdit,
  handleDelete,
  handleChange,
  searchText,
  page,
  setPage,
}) => {
  const [wait, setWait] = useState(true);
  const pageSize = 50;
  

  return (
    <div style={{width: "100%"}}>
      {getClauseData?.find((el) => el.creationType === 'FJGG' && el.version) && (
        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
          <span>FJGG Current Version:</span> &emsp;
          <Tag color="green" style={{ textAlign: "center" }}>
            {getClauseData.find((el) => el.creationType === 'FJGG' && el.version).version || ''}
          </Tag>
        </div>
      )}
    <div
      style={{
        width: "100%",
        // height:"100%",
        overflowY: "auto",
        overflowX: "hidden",
         position: "relative",
        zIndex: "30",
        marginTop: "20px",
      }}
      className={styles.clauselist}
        onScroll={(e) => {
          let currentHeight = e.target.scrollHeight - e.target.clientHeight
          if (e.target.scrollTop >= (currentHeight - 100)) {
            if (!getClausesLoading && wait && (getClauseData?.length >= pageSize)) {
              setPage([Number(page) + 1]);
              setWait(false);
            }
          }
          setTimeout(() => {
            setWait(true);
          }, 3000)
        }}
    >
      {getClauseData?.length > 0 ? (
        <>
        <table
          className="licenseTable"
          style={{
            width: "90%",
            marginTop: "-5px",
          }}
        >
          <thead
            style={{
              position: "sticky",
              top: -2,
              left: "0px",
              zIndex: 100,
              height: "50px",
            }}
          >
            <tr>
              <th>S.No</th>
              <th>Title</th>
              <th>Clauses</th>
              <th>Type</th>
            </tr>
          </thead>

          <tbody>
            {getClauseData?.map((el, i) => (
              <tr key={el._id} style={{ marginTop: "20px" }}>
                <td
                  style={{
                    fontWeight: "bolder",
                    width: "8%",
                    textAlign: "center",
                  }}
                >
                  {i + 1}
                </td>
                <td
                  style={{
                    fontWeight: "bolder",
                    width: "20%",
                  }}
                >
                {/* {el && el?.title &&
                <Highlighter
                    highlightClassName="highlightClass"
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={el && el?.title}
                />} */}
                {el && el?.title}
                </td>
                <td
                  style={{
                    fontWeight: "bolder",
                    width: "60%",
                  }}
                >
                 <div style={{display:"flex",justifyContent:"space-between"}}>
                  {/* <Text copyable={{text:el?.name}} style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                  <span style={{width:"90%", whiteSpace: "pre-wrap"}}>
                  {el && el?.name &&
                  <Highlighter
                    highlightClassName="highlightClass"
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={el && el?.name?.replace(/\n/g, '<br />')}
                    />}
                 </span>
                  <div style={{display:"flex",flexDirection:"row",marginTop:"5px"}}>
                  {el.creationType === 'FJGG' ? null :
                  <Tooltip trigger={'hover'} title="Edit">
                      <EditTwoTone  onClick={() => handleEdit(el)} style={{marginLeft:"15px"}} />
                 </Tooltip>}
                  <Popconfirm
                    title="Are you sure you want to delete this Clause?"
                    onConfirm={() => handleDelete(el._id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    {el.creationType ==='FJGG' ? null :
                    <Tooltip trigger={'hover'} title="Delete">
                        <DeleteTwoTone style={{margin:"0px 10px 0px 15px"}} />
                    </Tooltip>}
                  </Popconfirm>
                  </div>
                  </Text> */}
                  <HighlightText el={el} searchText={searchText} handleDelete={handleDelete} handleEdit={handleEdit}/>
                </div>
                </td>
                <td style={{textAlign:"center"}}>
                    <p style={{fontWeight:"bold"}}>{el.creationType}</p>
                </td>
              </tr>
            ))}
          </tbody>
          </table>
            {/* Loader for infinite scroll */}
            {getClausesLoading && (page > 1) && (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh" }}>
                <Spin size="medium" />
              </div>
            )}
          </>
        ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
            textAlign: "center",
          }}
        >
          <EmptyData />
        </div>
      )}
    </div>
    </div>

  );
};

export { ClausedataList };
