/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Modal, Radio, Input, Button, Tooltip, message } from "antd";
import styles from "../../style/offerImport.module.css";
import { BsInfoLg } from "react-icons/bs";

const ModifyDatesModal = ({ modalState, setModalState, handleInputSave, CurrentPdfImportData }) => {
  const [formState, setFormState] = useState({
    radioType: "date",
    inputValue: "",
  });
  const hanInputChanged = useRef(false);

  const handleCancel = () => {
    setModalState((prevState) => ({ ...prevState, showDateFieldModal: false, event: {} }));
  };

  useEffect(() => {
    const localData = CurrentPdfImportData || {};
    const currentData = localData[modalState.event?.target?.name];
    const isRelativeTime = modalState?.event?.target?.classList?.contains("relativeTimeInput");
    if (currentData) {
      if (!isNaN(Date.parse(currentData))) {
        setFormState({
          radioType: "date",
          inputValue: currentData,
        });
      } else if (currentData?.type) {
        setFormState({
          radioType: currentData.type,
          inputValue: currentData.value,
        });
      } else if (isRelativeTime) {
        setFormState({
          radioType: "time",
          inputValue: currentData,
        });
      }
    } else {
      setFormState({
        radioType: "date",
        inputValue: "",
      });
    }
  }, [CurrentPdfImportData, modalState.event?.target?.name]);

  const isClosingDateField = modalState?.event?.target?.classList?.contains("closing-date-input");
  const requiredDateField = modalState?.event?.target?.classList?.contains("requiredDateInput");

  const handleDataChange = (formattedData) => {
    const inputName = modalState.event?.target?.name;
    handleInputSave({ [inputName]: formattedData });
    handleCancel();
  };

  const handleSave = () => {
    const { radioType, inputValue } = formState;
    const inputName = modalState.event?.target?.name;

    if (!inputName) {
      console.error("No input name found");
      return;
    }

    let formattedData = {
      type: inputValue ? radioType : "text",
      value: inputValue || "N/A",
      ...(radioType === "mec" && { calculatedDate: "" }),
    };
    if (requiredDateField && !inputValue) {
      message.error("Please select a valid date.");
      return;
    }
    if (isClosingDateField) formattedData = inputValue || "";
    handleDataChange(formattedData);
  };

  const onChangeRadio = (e) => {
    setFormState({ radioType: e.target.value, inputValue: "" });
  };

  const onChangeInput = (e) => {
    const { value } = e.target;
    //  Preventing negative and non-numeric input for MEC
    if (formState.radioType === "mec" && (!/^\d*$/.test(value) || value < 0 || value > 500)) {
      return;
    }
    setFormState((prevState) => ({ ...prevState, inputValue: value }));
    hanInputChanged.current = true;
  };
  const inputProps = (type) => ({
    name: modalState.event?.target?.name,
    value: formState.radioType === type ? formState.inputValue : "",
    onChange: onChangeInput,
    onClick: () => {
      if (formState.radioType !== type) {
        setFormState({ radioType: type, inputValue: "" });
      }
    },
  });
  const isRelativeTime = modalState?.event?.target?.classList?.contains("relativeTimeInput");

  const label = modalState?.event?.target?.parentElement?.querySelector(`.${styles.Customlbl}`);
  const labelText = label?.textContent?.trim().replace(/^\*\s*/, "") || (isRelativeTime ? "Select Time" : "Select Date");
  return (
    <Modal
      visible={true}
      width={"600px"}
      title={
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "22px",
            color: "grey",
            width: "90%",
            overflow: "elipsis",
          }}
        >
          {labelText}
        </span>
      }
      onCancel={handleCancel}
      footer={null}
    >
      <div style={{ height: "fit-content", overflowY: "auto", overflowX: "hidden" }}>
        <div style={{ display: "flex", maxWidth: "400px", flexDirection: "column", gap: "20px" }}>
          <Radio.Group onChange={onChangeRadio} value={formState.radioType}>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", width: "400px" }}>
              {isRelativeTime && (
                <div style={{ display: "flex", alignItems: "center", gap: "0px" }}>
                  <Radio value="time">Select Time</Radio>
                  <Input type="time" {...inputProps("time")} style={{ width: "148px", marginLeft: "-5px" }} />
                </div>
              )}
              {!isRelativeTime && (
                <div style={{ display: "flex", alignItems: "center", gap: "0px" }}>
                  <Radio value="date">Select Date</Radio>
                  <Input type="date" {...inputProps("date")} style={{ width: "150px", marginLeft: "-5px" }} />
                </div>
              )}
              {!isRelativeTime && !isClosingDateField && (
                <Radio value="mec">
                  <Input type="text" {...inputProps("mec")}  style={{ margin: "5px", width: "70px" }} /> Days After MEC
                </Radio>
              )}
              {!isClosingDateField && (
                <Radio value="text" style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <Input placeholder="Enter text" maxLength={30} {...inputProps("text")} style={{ margin: "5px", width: "230px" }} />
                    <Tooltip title={"RE-Sure is not able to build Calendar reminders if you choose this option."} placement="right">
                      <BsInfoLg style={{ fontSize: "35px" }} />
                    </Tooltip>
                  </div>
                </Radio>
              )}
            </div>
          </Radio.Group>
        </div>
        <div style={{ textAlign: "center", margin: "40px 30px 30px 30px" }}>
          <Button type="primary" onClick={handleSave} disabled={!hanInputChanged.current}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModifyDatesModal;
